import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { cmsGetPromise } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ExpandingTable from '../ExpandingReactTable';

var _ = require('lodash');

class AllocationLogList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            searchText: []
        };

        this.fetchData = this.fetchData.bind(this);
        this.getColumns = this.getColumns.bind(this);
    }

    componentDidMount() { }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;
        var params = self.props.match.params;
        if (!params.accountId || !params.serviceName) {
            return;
        }

        var apiParams = {
            accountId: params.accountId,
            accountServiceId: params.serviceName,
            objectIds: [
                {
                    type: 'services',
                    id: params.serviceName
                },
                {
                    type: 'allocationlogs',
                }
            ],
            currentPage: 1,
            pageSize: 100
        };

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            apiParams['SortBy'] = sortSettings.Column;
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        return cmsGetPromise(apiParams).catch(err => {
            toast.error(err)
        });
    }

    getColumns() {
        var columns = [];

        columns.push(
            {
                id: "createdon",
                Header: "Created",
                width: 200,
                accessor: log => log.CreatedOn,
                sortable: false,
                filterable: false
            }
        );

        columns.push({
            id: "message",
            Header: "Message",
            accessor: log => (<span class="large-debug-text">{log.Message.replace("\n", "<br />")}</span>),
            sortable: false,
            filterable: false,
            className: "large-debug-text"
        });

        return columns;
    }

    render() {
        var self = this;

        return (
            <>
                <h2>SBC Allocation Logs</h2>
                <hr />
                <ExpandingTable
                    loadingText={"Loading..."}
                    noDataText={"No logs to show."}
                    showPagination={true}
                    fetchData={self.fetchData}
                    sortBy={
                        {
                            Column: "createdat",
                            SortDirection: 'Descending'
                        }
                    }
                    columns={self.getColumns()}
                />
            </>
        );
    }
}

export default connect(state => ({
    baseAccount: state.account.baseAccount,
    account: state.account.account,
    services: state.services.rawList
}))(withRouter(AllocationLogList));