import { cmsStartAllServiceSyncPromise, toastErrHandler, cmsGetPrivateAccountAppSettings } from './CallMSAPI.js';
import { getSyncIdToken, getSyncTokens, getScopedSyncTokens } from './MSALAuthProvider';
import { getExternalSyncFormattedStartData, getExternalSyncToken, openPopupWindow } from './ExternalAuthProvider';
import { showConsoleLogs } from './CallMSUIHelpers.js';
var _ = require('lodash');

export async function cmsStartSyncAllServices(accId, services, popup, shouldCaptureDebug, promptUser, isAutoSync) {
    var teamsSFB = services.filter(x => (x.ServiceCode === 'teams' || x.ServiceCode === 'sfb') && x.SyncRequiresPerJobToken);
    var externalSync = services.filter(x => x.ServiceCode != 'teams' && x.ServiceCode != 'sfb' && x.ServiceSyncModuleCode);

    if(!popup && (teamsSFB.length > 0 && !isAutoSync || (externalSync.length > 0))) {
        popup = openPopupWindow('about:blank', "Portal Auth");
    }

    var AccountServiceIds = [];
    var authSection = {};

    for (var i = 0; i < services.length; i++) {
        AccountServiceIds.push(services[i].Id);
    }

    if (teamsSFB.length > 0 && (!externalSync || externalSync.length == 0)) {
        var temp = 0;
        return doTeamsSync(teamsSFB, popup, promptUser, authSection, isAutoSync, accId).then(function (res) {

            if (popup) {
                popup.close();
            }
            var postData = {
                AccountServiceIds: AccountServiceIds,
                auth: authSection,
                CaptureDebug: shouldCaptureDebug,
                IsFirstSync: false,
                promptUser: promptUser,

            }

            return cmsStartAllServiceSyncPromise(
                accId,
                postData
            );

        }).catch(err => toastErrHandler(err));

    } else if ((teamsSFB.length == 0 || !teamsSFB) && externalSync.length > 0) {
        var temp = 0;
        return doExternalSync(externalSync, popup, authSection).then(function (res) {

            if (popup) {
                popup.close();
            }

            var postData = {
                AccountServiceIds: AccountServiceIds,
                auth: authSection,
                CaptureDebug: shouldCaptureDebug,
                IsFirstSync: false,
                promptUser: promptUser,

            }
            return cmsStartAllServiceSyncPromise(
                accId,
                postData
            );

        }).catch(err => toastErrHandler(err));

    } else if (externalSync.length > 0 && teamsSFB.length > 0) {
        var temp = 0;
        return doAllSync(teamsSFB, externalSync, popup, promptUser, authSection, isAutoSync, accId).then(function (res) {

            if (popup) {
                popup.close();
            }

            var postData = {
                AccountServiceIds: AccountServiceIds,
                auth: authSection,
                CaptureDebug: shouldCaptureDebug,
                IsFirstSync: false,
                promptUser: promptUser,

            }
            return cmsStartAllServiceSyncPromise(
                accId,
                postData
            );

        }).catch(err => toastErrHandler(err));

    } else {
        if (popup) {
            popup.close();
        }

        var postData = {
            AccountServiceIds: AccountServiceIds,
            auth: authSection,
            CaptureDebug: shouldCaptureDebug,
            IsFirstSync: false,
            promptUser: promptUser,

        }
        return cmsStartAllServiceSyncPromise(
            accId,
            postData
        );
    }
}

async function doTeamsSync(teamsSFB, popup, promptUser, authSection, isAutoSync, accId){
    var serviceSyncSettings = teamsSFB.find(x => x.SyncSettings);

    var domainHint = null;
    var syncTenantId = null;
    var appSyncId = null;

    if (serviceSyncSettings) {
        var syncTenant = _.find(serviceSyncSettings.SyncSettings, { Key: 'TenantDomain' });
        if (syncTenant) {
            domainHint = syncTenant.Value;
            if(showConsoleLogs()) {
                console.log("Using domain hint of " + domainHint);
            }
        }
        var tenantId = _.find(serviceSyncSettings.SyncSettings, { Key: 'TenantId' });
        if (tenantId && tenantId.Value) {
            syncTenantId = tenantId.Value;
        }
        var syncId = _.find(serviceSyncSettings.SyncSettings, { Key: 'SyncClientId' });
        if (syncId && syncId.Value) {
            appSyncId = syncId.Value;
        }
    }

    if(isAutoSync){
        return cmsGetPrivateAccountAppSettings(accId).then(res => {
            if(res && res.data) {
                var auth = {
                    teams: {
                        type: "TenantId" ,
                        value: syncTenantId,
                        clientId: res.data.AutoSyncApplicationId,
                    }
                };
                _.merge(authSection, auth);
                return auth;
            }
        })
    }

    //If phoneapp is enabled and at least one toggle is switched on then use v2 version of auth and use extra scopes
    var useV2Auth = false;
    if (teamsSFB && teamsSFB[0] && teamsSFB[0].TeamsSettings) {
        if (teamsSFB[0].TeamsSettings.IsPhoneAppEnabled && teamsSFB[0].TeamsSettings.IsPhoneAppEnabled != 'Disabled') {
            useV2Auth = true;
        }
    }
    var extraScopes = [
        'AppCatalog.ReadWrite.All',
        'TeamsAppInstallation.ReadWriteForUser'
    ];

    return getSyncIdToken(domainHint, promptUser, popup, syncTenantId)
        .then(function (ok) {
            var tid = ok.tid;
            var username = ok.preferred_username;

            if (serviceSyncSettings.SyncSettings) {
                var syncTenant = _.find(serviceSyncSettings.SyncSettings, { Key: 'TenantId' });
                if (syncTenant && syncTenant.Value && syncTenant.Value !== tid) {
                    // tenants don't match!
                    // let upstream provider deal with closing popup
                    throw new Error("The tenant for the selected user does not match the previously tenant selected.");
                } else {
                    if(showConsoleLogs()) {
                        console.log("Tenants match so we are all good!");
                    }
                }
            } else {
                if(showConsoleLogs()) {
                    console.log("No tenant values to check with");
                }
            }

            if (useV2Auth) {
                return getScopedSyncTokens(1, tid, username, popup, appSyncId, extraScopes);
            } else {
                return getSyncTokens(1, tid, username, popup, appSyncId);
            }
        }).then(function (results) {
            if (results.length < 1) {
                throw new Error("Not enough codes returned");
            }
            if (!results[0].code) {
                throw new Error("Sync response but missing code(s)");
            }

            var auth = {
                teams: {
                    type: 'AuthorizationCode',
                    value: results[0].code,
                    clientId: results[0].client_id,
                    redirectUri: results[0].redirect_uri
                }
            };
            _.merge(authSection, auth);
            return auth;
        });
}
async function doExternalSync(externalSync, popup, authSection){
    for (var i = 0; i < externalSync.length; i++) {
        var serviceObj = externalSync[i];
        var serviceSyncSettings = serviceObj.SyncSettings;
        var syncSettings = serviceSyncSettings ? _.cloneDeep(serviceSyncSettings) : null;

        // inject the account service name for display in custom popups (e.g. Kazoo)
        if (syncSettings && serviceObj) {
            syncSettings.push({
                Value: serviceObj.Name,
                Key: 'AccountServiceName'
            })
        }

        return getExternalSyncToken(serviceObj.ServiceSyncModuleCode, popup, syncSettings)
            .then(function (results) {
                var code = '';
                if (results && results.length === 1 && results[0].code) {
                    code = results[0].code;
                }

                var outCodeData = getExternalSyncFormattedStartData(serviceObj.ServiceSyncModuleCode, code, syncSettings);
                _.merge(authSection, outCodeData);
                return outCodeData;
            });
    }
}
async function doAllSync(teamsSFB, externalSync, popup, promptUser, authSection, isAutoSync, accId){
    return doTeamsSync(teamsSFB, popup, promptUser, authSection, isAutoSync, accId).then(function () {
        return doExternalSync(externalSync, popup, authSection)
    }).catch(function (err) {
        throw new Error(err.message)
    })
}