import React, { Component } from 'react';
import { connect } from 'react-redux';
var _ = require('lodash');

export const DOMAIN_CODE = 'SD';
export const PBX_USER = 'PU';
export const PBX_USER_TRIAL = 'PUT';
export const TRUNK_USER = 'TU';
export const TRUNK_USER_TRIAL = 'TUT';
export const PHONE_APP_TRIAL = 'PAT';

export const PBX_USER_LICENCE_CODE = 'USER-PBX';
export const TRUNK_USER_LICENCE_CODE = 'USER-TRUNK';
export const SFB_DOMAIN_LICENCE_CODE = 'DOMAIN-SFB';
export const PHONE_APP_LICENCE_CODE = 'USER-PHONEAPP';

export function isTrialCode(code) {
    if (!code) {
        return false;
    }

    switch (code) {
        case PBX_USER_TRIAL:
        case TRUNK_USER_TRIAL:
        case PHONE_APP_TRIAL:
            return true;
        default:
            return false;
    }
}


class UserLicenceLine extends Component {
    render() {
        var licLines = [];
        var userLic = extractSpecificLicence(this.props.account.Licences, PBX_USER_LICENCE_CODE);
        if (userLic) {
            licLines.push(userLic.Remaining + " of " + userLic.NumPurchased + " PBX")
        }

        var trunkLic = extractSpecificLicence(this.props.account.Licences, TRUNK_USER_LICENCE_CODE);
        if (trunkLic) {
            licLines.push(trunkLic.Remaining + " of " + trunkLic.NumPurchased + " Trunk")
        }

        var phoneAppLic = extractSpecificLicence(this.props.account.Licences, PHONE_APP_LICENCE_CODE);
        if (phoneAppLic) {
            licLines.push(phoneAppLic.Remaining + " of " + phoneAppLic.NumPurchased + " Phone App")
        }

        var msg = '';
        if (licLines.length === 0) {
            msg = "No user licences available.";
        } else {
            msg = licLines.join(" and ");
            msg += " user licences available.";
        }

        return (
            <p className="text-muted">{msg}</p>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserLicenceLine);

export function extractLicence(account, code) {
    if (!account || !account.Licences) {
        return null;
    }

    return extractSpecificLicence(account.Licences, code);
}

export function extractSpecificLicence(licences, code) {
    let o = _.find(licences, { ProductCode: code });

    if (!o) {
        return null;
    } else {
        o = _.cloneDeep(o);
        o.Remaining = o.NumPurchased - o.NumUsed;
        return o;
    }
}

export const hasUsedLicences = (licences = []) => {
    if (!licences) {
        return false;
    }
    const used = licences.filter(l => l.NumUsed > 0);
    return used != null && used.length > 0;
}
