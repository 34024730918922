export const ValidateRealm = (value, label) => {
    var response = null;

    if (!/\w/i.test(value)) {
        response = `You must enter a valid ${label}.`; 
    } else if (HasSlashes(value)) {
        response = `The ${label} cannot contain any slashes.`;
    } else if (HasWhiteSpace(value)) {
        response = `The ${label} cannot contain any white space.`;
    } else if (HasPortNumber(value)) {
        response = `The ${label} cannot contain a port.`;
    }

    return response  
}

export const ValidateProxy = (value, label) => {
    var response = null;

    if (!/\w/i.test(value)) {
        response = `You must enter a valid ${label}.`;
    } else if (HasSlashes(value)) {
        response = `The ${label} cannot contain any slashes.`;
    } else if (HasWhiteSpace(value)) {
        response = `The ${label} cannot contain any white space.`;
    }

    return response
}

const HasSlashes = (value) => {
    return /[/\\]/i.test(value)
}

const HasWhiteSpace = (value) => {
    return /\s/i.test(value)
}

const HasPortNumber = (value) => {
    return /:\d/i.test(value)
}