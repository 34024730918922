import { CallLogType } from "./Enums";

export class CallLog {
    constructor(resp = CallLog.prototype)
    {
        if (resp) {
            this.time = resp.time || 0;
            this.type = resp.type || CallLogType.EdgeToApp;
            this.content = resp.content || '';
        }
    }
    time = 0;
    type = CallLogType.EdgeToApp;
    content = '';
}

export class ContentCall extends CallLog {
    constructor(resp = ContentCall.prototype)
    {
        super(resp);
        if (resp) {
            this.answeredOn = resp.answeredOn || 0;
            this.callIdInternal = resp.callIdInternal || '';
            this.callIdPart1 = resp.callIdPart1 || '';
            this.callIdPart2 = resp.callIdPart2 || '';
            this.callIdPart2CorrelationId = resp.callIdPart2CorrelationId || '';
            this.callLogId = resp.callLogId || '';
            this.calleeId = resp.calleeId || '';
            this.callerId = resp.callerId || '';
            this.completedOn = resp.completedOn || 0;
            this.correlationId = resp.correlationId || '';
            this.failureAdvice = resp.failureAdvice || '';
            this.fromPart1 = resp.fromPart1 || 0;
            this.serviceUserId = resp.serviceUserId || 0;
            this.startedOn = resp.startedOn || 0;
            this.disposition = resp.disposition || '';
        }
    }
    answeredOn = 0;
    callIdInternal = '';
    callIdPart1 = ''
    callIdPart2 = '';
    callIdPart2CorrelationId = '';
    callLogId = '';
    calleeId = '';
    callerId = '';
    completedOn = 0;
    correlationId = '';
    failureAdvice = '';
    fromPart1 = 0;
    serviceUserId = 0;
    startedOn = 0;
    disposition = '';
}

export class ContentLog extends CallLog {
    constructor(resp = ContentLog.prototype)
    {
        super(resp);
        if (resp) {
            this.accepted_change = resp.accepted_change || 0;
            this.message = resp.message || '';
            this.new_state = resp.new_state || '';
            this.new_state_name = resp.new_state_name || '';
        }
    }
    accepted_change = 0;
    message = '';
    new_state = '';
    new_state_name = '';
}
