import React, { Component } from 'react';
import {
    getErrMsg,
    cmsAddService,
    cmsServiceEnableSyncWrapper,
    cmsStartOAuthServiceSync,
    cmsGetSyncModuleDefaultSettings,
} from '../CallMSAPI.js';
import { Form, Field, FieldArray, Formik } from 'formik';
import { toast } from 'react-toastify';
import MicrosoftAdvancedSyncOptions from './MicrosoftAdvancedSyncOptions';
import { extractLicence, extractSpecificLicence } from '../LicenceHelpers';
import { DocumentList } from '../CallMSUIHelpers';
import { SubmitButton } from '../FormHelpers';
import { LargeTenantModeRow } from './ServiceFormHelpers';
import CountryIdAsyncSelect from '../CountryIdAsyncSelect.js';
import CallingEssentialsRow from '../components/service/CallingEssentialsRow.js';

import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

var _ = require('lodash');

class MicrosoftServiceNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentService: null
        };
        this.onToggleChange = this.onToggleChange.bind(this);
        this.onComplete = this.onComplete.bind(this);
    }

    refetchServices = () => {
        this.props.refetchServices();
    }

    getFormDefaults() {
        var currentVarId = this.props.availableService.Variants ? this.props.availableService.Variants[0].Id : '';
        var currentVar = _.find(this.props.availableService.Variants, { 'Id' : currentVarId });
        var baseDefaults = {
            Domains: [''],
            Name: this.props.availableService.Name,
            CountryId: this.props.account.CountryId ? this.props.account.CountryId : '',
            StateId: this.props.account.StateId ? this.props.account.StateId : '',
            ServiceId: this.props.availableService.Id,
            ServiceVariantId: currentVarId,
            accountId: this.props.account.Id,
            enableSync: currentVar && currentVar.SyncSupported ? true : false,
            ExternalServiceAccountId: '',
            // lives under SyncSettings
            GroupIdListNative: [],
            GroupIdListCE: [],
            SyncSettings: {},
            TeamsSettings: {},
            PhoneAppToggle: false,
            PhoneAppOnlyToggle: false,
            LargeTenantMode: false,
            PSNamePrefix: null,
            PSUsageLocations: null
        };

        return baseDefaults;
    }

    onComplete(popup, errMessage, successMessage, warningMessage) {
        var self = this;
        if (popup) {
            popup.close();
        }
        if (errMessage) {
            toast.error(errMessage);
        }
        if (successMessage) {
            toast.success(successMessage);
        }
        if (warningMessage) {
            toast.warning(warningMessage);
        }
        self.refetchServices();
    }

    onToggleChange = (e, checked, containerId, setFieldValue, fieldName) => {
        var elements = document.getElementsByClassName(containerId);
        for (var i = 0; i < elements.length; i++) {
            var element = elements.item(i)
            element.style.display = checked
                ? "block"
                : "none";
        }
        setFieldValue(fieldName, checked);
    }

    getSfbLicenceInfo(variant) {
        /**
         */
        var output = {
            hasLimit: false,
            count: 0,
            details: null
        }

        if (variant.Code === 'SFBSTD') {
            output['hasLimit'] = true;
            var licenceInfo = extractLicence(this.props.account, 'DOMAIN-SFB');
            if (licenceInfo) {
                output['details'] = licenceInfo;
                output['count'] = licenceInfo.Remaining;
            }
        }

        return output;
    }

    render() {
        var self = this;

        return (
            <div>
                <Formik
                    // Required so updates from getFormDefaults get propagated down to form
                    enableReinitialize={true}
                    initialValues={this.getFormDefaults()}
                    validate={values => {
                        let errors = {};
                        var variant = _.find(self.props.availableService.Variants, { 'Id': parseInt(values.ServiceVariantId) });

                        // Ensure Domain Name exists
                        if (values.Domains.length < variant.MinDomains) {
                            errors.domainsOverall = 'You must enter at least ' + variant.MinDomains + ' domains.';
                        } else if (values.Domains.length > variant.MaxDomains) {
                            // If the last domain is empty string, skip that
                            if (values.Domains[values.Domains.length - 1] !== '') {
                                errors.domainsOverall = 'You cannot enter more than ' + variant.MaxDomains + ' domains.';
                            }
                        }

                        if (variant.MinDomains > 0) {
                            values.Domains.forEach(function (d, ind) {
                                if (!/[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(d)) {
                                    if (!errors.hasOwnProperty('Domains')) {
                                        errors.Domains = [];
                                    }
                                    errors.Domains[ind] = 'You must enter a valid domain';
                                }
                            });
                        }

                        if (!values.Name) {
                            errors.Name = 'You must enter a name for this service.';
                        }

                        if (variant.RequireCountryOrState) {
                            if (!values.CountryId && !values.StateId) {
                                errors.CountryId = 'You must select a country/state value.';
                            }
                        }

                        if (!values.ServiceVariantId) {
                            errors.ServiceVariantId = 'You must select a variant.';
                        }

                        if (values.PSNamePrefix && !/^[a-zA-Z]+$/.test(values.PSNamePrefix)) {
                            errors.PSNamePrefix = 'Tenant Configuration Prefix should not contain any numbers or special characters';
                        }

                        if (values.PSUsageLocations && !/^[A-Za-z]+(?:\s*,\s*[A-Za-z]+)*$/i.test(values.PSUsageLocations)) {
                            errors.PSUsageLocations = 'This value should only contain Alphabetic characters, and commas.';
                        }

                        return errors;
                    }}
                    onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                        var formik = this;

                        var values = _.cloneDeep(originalValues);

                        if (values['StateId']) {
                            values['CountryId'] = '';
                        }

                        var phoneAppEnabled = 'Disabled';
                        if (!values['PhoneAppToggle']) {
                            phoneAppEnabled = 'Disabled';
                        } else if (values['PhoneAppToggle'] && !values['PhoneAppOnlyToggle']) {
                            phoneAppEnabled = 'Enabled';
                        } else if (values['PhoneAppToggle'] && values['PhoneAppOnlyToggle']) {
                            phoneAppEnabled = 'PhoneAppOnly';
                        }

                        values['TeamsSettings'] = {
                            IsPhoneAppEnabled: phoneAppEnabled
                        };

                        delete values.PhoneAppToggle;
                        delete values.PhoneAppOnlyToggle;

                        // Collapse down country object to single ID value
                        var variant = _.find(self.props.availableService.Variants, { 'Id': parseInt(values.ServiceVariantId) });
                        if (!variant.RequireCountryOrState) {
                            delete values.CountryId;
                            delete values.StateId;
                        }

                        if (variant.MinDomains === 0 && variant.MaxDomains === 0) {
                            delete values.Domains;
                        }

                        values.SyncSettings = {};
                        if (values.GroupIdListNative && values.GroupIdListNative.length > 0) {
                            values.SyncSettings['GroupIdListNative'] = values.GroupIdListNative;
                        }
                        if ((values.PhoneAppToggle || values.PhoneAppOnlyToggle) && values.LargeTenantMode) {
                            values.SyncSettings['GroupIdListCE'] = values.GroupIdListCE;
                        } else {
                            values.SyncSettings['GroupIdListCE'] = null;
                        }
                        delete values.GroupIdListNative;
                        delete values.GroupIdListCE;

                        if (values.PSNamePrefix) {
                            values.SyncSettings['PSNamePrefix'] = values.PSNamePrefix;
                        }
                        delete values.PSNamePrefix;

                        if (values.PSUsageLocations) {
                            values.SyncSettings['PSUsageLocations'] = values.PSUsageLocations;
                        }
                        delete values.PSUsageLocations;

                        let accId = values['accountId'];
                        delete values['accountId'];

                        if (!values.enableSync) {
                            delete values.SyncSettings;
                        }

                        // self.props.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                        cmsAddService(accId, values, function (data) {
                            // Do we need to prompt for service enable?
                            var popup = self.props.buildPopupCb && self.props.buildPopupCb != null ? self.props.buildPopupCb() : false;
                            if (values.enableSync) {
                                cmsServiceEnableSyncWrapper(
                                    self.props.account.Id,
                                    data,
                                    {
                                        'OverwriteData': true,
                                        'SyncSettings': values.SyncSettings
                                    },
                                    popup,
                                    function (ok) {
                                        // service now enabled... kick off a sync
                                        // 2 tokens requested, we need to send for each scope
                                        cmsGetSyncModuleDefaultSettings(self.props.account.Id).then(function (res) {
                                            var syncIdObj = _.find(res.data, { Key: 'SyncClientId' });
                                            var syncId = null;
                                            if (syncIdObj) {
                                                syncId = syncIdObj.Value;
                                            }

                                            cmsStartOAuthServiceSync(
                                                self.props.account.Id,
                                                data,
                                                {
                                                    firstSync: true
                                                },
                                                popup,
                                                syncId
                                            ).then(
                                                function (ok) {
                                                    toast.success('Service added successfully');
                                                    if (self.props.newServiceSyncStartedCb && self.props.newServiceSyncStartedCb != null) {
                                                        self.props.newServiceSyncStartedCb(popup);
                                                        self.refetchServices();
                                                    } else {
                                                        popup.close();
                                                        self.refetchServices();
                                                    }
                                                },
                                                function (err) {
                                                    var warningMessage = "Unable to start first sync, please click the sync button to try again";
                                                    self.onComplete(popup, null, null, warningMessage);
                                                }
                                            ).finally(function () {
                                                setSubmitting(false);
                                            })
                                        })
                                    },
                                    function (err) {
                                        setSubmitting(false);
                                        var warningMessage = "Unable to start first sync, please click the sync button to try again";
                                        self.onComplete(popup, null, null, warningMessage);
                                    }
                                );
                            } else {
                                setSubmitting(false);
                                self.onComplete(popup, null, "Service successfully enabled");
                            }

                            // reset form
                            resetForm && resetForm(formik.getFormDefaults());

                        }, function (error) {
                            setSubmitting(false);
                            toast.error(getErrMsg(error));
                        })
                    }}
                >

                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => {
                        var variantOptions = [];
                        self.props.availableService.Variants.forEach(function (r) {
                            variantOptions.push(<option key={r.Id} value={r.Id}>{r.Name}</option>);
                        });

                        var variantOption = null;
                        if (variantOptions.length === 1) {
                            variantOption = (<input type="hidden" name="ServiceVariantId" value={values.ServiceVariantId} />);
                        } else {
                            variantOptions.unshift(<option key="blankId" value="" disabled={true}>Select Service Variant</option>);
                            variantOption = (
                                <div className="form-group form-padding">
                                    <label>Service Variant</label>
                                    <div>
                                        <Field component="select" name="ServiceVariantId" className="form-control">
                                            {variantOptions}
                                        </Field>
                                        <span className="error-message">{errors.Variant && touched.Variant && errors.Variant}</span>
                                    </div>
                                </div>
                            );
                        }

                        /**
                         * We have some clever form logic here to handle the min/max nunmber of domains a service can support.
                         */
                        var domainsPart = null;
                        var domainsPartArray = [];

                        if (!self.props.availableService.Variants || self.props.availableService.Variants.length === 0) {
                            return <p>No service variants are available.</p>;
                        }

                        var currentVariant = _.find(self.props.availableService.Variants, { 'Id': parseInt(values.ServiceVariantId) });

                        var oneClickNote = null;
                        if (currentVariant.Code === 'TEAMSNATIVESPDOM' || currentVariant.Code === 'TEAMSAUDIOCODES') {
                            oneClickNote = (
                                <div className="alert alert-default">
                                    <p>Setup of Office 365 works best if you have two
                                    unassigned licenses such as Business Essentials/Premium
                                    or E1/E3/E5. These unassigned licences are only required
                                    for a few hours during the setup process and can be
                                    removed or re-assigned afterwards. If you only have
                                    one unassigned license then the set-up process also
                                    works but may take longer. If you don't have any
                                    unassigned licenses then additional manual activation
                                    steps will be required.
                                    </p>
                                </div>
                            );
                        }

                        var formClassNames = "form-new-teams-service user-form-parts-wrapper";

                        if (isSubmitting) {
                            formClassNames += " form-is-submitting";
                        }

                        if (!currentVariant) {
                            return (
                                <>
                                    <div>
                                        <Form className={formClassNames}>
                                            {variantOption}
                                        </Form>
                                    </div>
                                    <hr />
                                    <div className="col-sm-12">
                                        <p>A valid service variant has not been selected.</p>
                                    </div>
                                </>
                            );
                        }

                        var licInfo = self.getSfbLicenceInfo(currentVariant);
                        if (licInfo.hasLimit && licInfo.count === 0) {
                            return (<p>You do not have any available Skype for Business domain licences to activate this service.</p>);
                        }

                        if (currentVariant.MaxDomains > 0) {
                            var addDomainClass = "btn btn-primary";
                            if (values.Domains.length >= currentVariant.MaxDomains) {
                                addDomainClass = "hidden";
                            }

                            if (licInfo.hasLimit && (values.Domains.length >= licInfo.count)) {
                                addDomainClass = "hidden";
                            }

                            domainsPartArray = (
                                <FieldArray name="Domains" render={({ insert, remove, push }) => (

                                    <div>
                                        {values.Domains.length > 0 &&
                                            values.Domains.map(
                                                function (domain, index) {
                                                    var removeButton = null;
                                                    if (values.Domains.length > currentVariant.MinDomains) {
                                                        removeButton = (
                                                            <div>
                                                                <button type="button" className="btn btn-default btn--remove-domain-inline" onClick={() => remove(index)}>
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        );
                                                    }

                                                    return (
                                                        <div key={index}>
                                                            <div className="service-domain-form-listing">
                                                                <Field className="form-control" name={`Domains.${index}`} placeholder="yourdomain.com" type="text" />
                                                                {errors.Domains && errors.Domains[index] && touched.Domains && touched.Domains[index] &&
                                                                    (
                                                                        <div className="error-message">
                                                                            {errors.Domains[index]}
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            {removeButton}
                                                        </div>
                                                    );
                                                }
                                            )
                                        }

                                        <span className="error-message">{errors.domainsOverall}</span>
                                        <button type="button" className={addDomainClass} onClick={() => push("")}>
                                            <i className="fa-solid fa-plus"></i> Add Additional Domain
                                            </button>

                                    </div>
                                )} />
                            );

                            domainsPart = (
                                <div className="form-group form-padding">
                                    <label>Domains</label>
                                    {domainsPartArray}
                                </div>
                            );
                        }

                        var serviceName = null;
                        var firstService = true;
                        if (firstService) {
                            serviceName = (
                                <input type="hidden" name="Name" value={values.Name} onChange={handleChange} onBlur={handleBlur} />
                            );
                        } else {
                            serviceName = (
                                <div className="form-group form-padding">
                                    <label>Service Name</label>
                                    <input type="text" name="Name" className="form-control" value={values.Name} onChange={handleChange} onBlur={handleBlur} />
                                    <span className="error-message">{errors.Name && touched.Name && errors.Name}</span>
                                </div>
                            );
                        }

                        var countryPart = null;

                        if (currentVariant.RequireCountryOrState) {
                            countryPart = (
                                <div className="form-group form-padding">
                                    <label>Country</label>
                                    <CountryIdAsyncSelect
                                        countryName="CountryId"
                                        countryValue={values.CountryId}
                                        stateName="StateId"
                                        stateValue={values.StateId}
                                        onChange={setFieldValue}
                                    />
                                    <span className="error-message">{errors.CountryId && touched.CountryId && errors.CountryId}</span>
                                    <span className="error-message">{errors.StateId && touched.StateId && errors.StateId}</span>
                                </div>
                            );
                        }

                        var syncPart = null;
                        var advancedTeams = null;
                        if (currentVariant.SyncSupported) {
                            if (!self.props.showSyncEnableToggle) {
                                syncPart = (
                                    <>
                                        <div className="form-group form-padding">
                                            <div><label>Office 365 Sync</label></div>
                                            <div>
                                                <label> <Field type="checkbox" name="enableSync" /> Enable Sync</label>
                                                <p className="help-block">After the service has been enabled we will start
                                                    syncing data between this portal and Microsoft Office 365.</p>
                                            </div>
                                        </div>
                                    </>
                                );
                            }

                            var isCallingEssentialsEnabled = false;
                            var phoneAppLic = extractSpecificLicence(self.props.account.Licences, 'USER-PHONEAPP');
                            if (phoneAppLic && currentVariant.Code === 'TEAMSAUDIOCODES') {
                                isCallingEssentialsEnabled = true;
                            }

                            var advancedEnabled = true;

                            var useCEGroups = (values.PhoneAppToggle || values.PhoneAppOnlyToggle) && isCallingEssentialsEnabled ? true : false;

                            if (advancedEnabled && self.props.availableService.Code === 'teams' && values.enableSync) {
                                var pSNamePrefixPlaceholder = self.props.account && self.props.account.BrandShortName ? self.props.account.BrandShortName : '';
                                advancedTeams = (
                                    <>
                                        <hr />
                                        <div className="form-group-full form-padding">
                                            <LargeTenantModeRow values={values} setFieldValue={setFieldValue} isCallingEssentialsEnabled={useCEGroups} />
                                        </div>
                                        <hr />
                                        <div className="form-group-full form-padding">
                                            <MicrosoftAdvancedSyncOptions
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                touched={touched}
                                                errors={errors}
                                                readOnly={false}
                                                PSNamePrefixPlaceholder={pSNamePrefixPlaceholder} />
                                        </div>
                                    </>
                                );
                            }
                        }

                        var phoneAppRow = null;
                        if (isCallingEssentialsEnabled && values.enableSync) {
                            phoneAppRow = (
                                <div className="form-group-full form-padding">
                                    <CallingEssentialsRow
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        account={self.props.account}
                                    />
                                </div>
                                );
                        }

                        return (
                            <>
                                <div className="alert alert-info">
                                    {oneClickNote}
                                    <Form className={formClassNames}>
                                        {variantOption}

                                        {serviceName}

                                        {countryPart}

                                        {domainsPart}

                                        {syncPart}

                                        {phoneAppRow}

                                        {advancedTeams}

                                        <hr />

                                        <div className="form-group form-padding">
                                            <div>
                                                <SubmitButton isSubmitting={isSubmitting}>Enable Service</SubmitButton>
                                            </div>
                                        </div>
                                    </Form>

                                </div>
                                {self.props.showDocuments ?
                                    <DocumentList
                                        heading="Documents"
                                        documents={currentVariant.Documents}
                                    />
                                    :
                                    null}
                            </>
                        )
                    }
                    }

                </Formik>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refetchServices: () => dispatch(actions.refetchServices())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MicrosoftServiceNew);