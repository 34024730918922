import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { ErrorSplash } from './CallMSUIHelpers';

class NoMatch extends Component {
  render() {
    return (
        <ErrorSplash>
        <h4>Oops, we couldn't find that page.</h4>
            <p> <Link to="/">Return to Home</Link> </p>
        </ErrorSplash>
    );
  }
}


export default NoMatch;
