import React, { Component } from 'react';


class WizardBreadcrumbs extends Component {
    constructor(props) {
        super(props);
    }

    getBreadcrumbStyle = (status) => {
        return status == "active"
            ? "wizard-breadcrumb--active"
            : status == "completed"
                ? "wizard-breadcrumb--completed"
                : "wizard-breadcrumb--uncompleted"
    }

    getBreadcrumbs = (props) => {
        const crumbs = [];
        var numCols = Math.round(12 / props.totalSteps)
        for (let i = 1; i <= props.totalSteps; i += 1) {
            const status = props.currentStep === i
                ? "active"
                : props.currentStep > i
                    ? "completed"
                    : "uncompleted"

            crumbs.push(
                <div className={`col-md-${numCols}`} key={`step-${i}`}>
                    <div className={`col-md-12 wizard-breadcrumb ${this.getBreadcrumbStyle(status)}`}
                        key={`step-${i}`}
                    >{props.names[i - 1]}
                    </div>
                </div>
            );
        }

        return crumbs;
    }

    render() {
        var crumbs = this.getBreadcrumbs(this.props);
        return (
            <div className="row wizard-breadcrumbs-container">
                {crumbs}
            </div >
        );

    }
};

export default WizardBreadcrumbs;