import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { isSyncOnlyContributor, LoadingSplash } from './CallMSUIHelpers';
import { hasUsedLicences } from './LicenceHelpers';
var _ = require('lodash');

const Home = (props) => {

    const renderRedirect = () => {
        if (!props.baseAccountsLoaded) {
            return <LoadingSplash text="Loading Additional Profile Details..." />
        } else if (isSyncOnlyContributor(props.baseAccountInfo.Roles)) {
            return <Redirect to={`/portal/${props.account.Id}/sync`} />
        } else if (props.baseAccountInfo.NumChildren > 0 || (props.baseAccountInfo && props.baseAccountInfo.IsPurchaseEnabled)) {
            return <Redirect to='/accounts' />

        } else if (props.loaded) {

            if (!hasUsedLicences(props.account.Licences)) {
                return <Redirect to={`/portal/${props.account.Id}/gettingstarted`} />
            }

            return <Redirect to={`/portal/${props.account.Id}/users`} />
        }
    }

    return (
        <div className='Home'>
            {renderRedirect()}
        </div>
    );
}
const mapStateToProps = state => {
    const account = state.account;
    
    return {
        account: account.account,
        loaded: account.loaded,
        baseAccount: account.baseAccount,
        baseAccountsLoaded: account.baseAccountsLoaded,
        baseAccountInfo: account.baseAccountInfo
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
