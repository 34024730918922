import React from 'react';
import './AllApp.css';
import { NavLink, withRouter } from 'react-router-dom'
import Header from './Header';
import Footer from './Footer';
import { Nav } from '@fluentui/react';
import { string } from 'prop-types';
import { connect } from 'react-redux';

import * as actions from './store/actions/index';

const AppSwitchScreen = (props) => {

    const switchAccount = (accId = '') => {
        props.switchBaseAccount(accId, accId);
    }

    return (
        <div className='page-wrapper'>
            <Header
                links={[
                    <NavLink
                        key='accounts_home'
                        to={`/accounts/${props.baseAccount.Id}`}
                    >
                        List <b>{props.baseAccount.Name}</b> Accounts
                    </NavLink>
                ]}
            />

            <br />
            <div className='container container--main-content' role='main'>
                <div className='row'>
                    <div className='col-xs-12'>
                        <Nav
                            onRenderGroupHeader={(g) => (
                                <>
                                    <h3>{g.name}</h3>
                                    <h4>{props.text}</h4>
                                </>
                            )}
                            ariaLabel={props.text}
                            onLinkClick={(e, i) => {
                                e.preventDefault();
                                switchAccount(i.key);
                                props.history.push(`/accounts/${i.key}`);
                            }}
                            onRenderLink={l => <h5>{l.name}</h5>}
                            groups={
                                [
                                    {
                                        name: 'Available Base Accounts:',
                                        links: props.baseAccounts.map((a, i) => {
                                            if (a.Id === props.baseAccount.Id) {
                                                a.Name += ' (active)';
                                            }

                                            return {
                                                name: a.Name,
                                                url: `/accounts/${a.Id}`,
                                                key: a.Id,
                                                target: '_blank'
                                            };
                                        })
                                    }
                                ]
                            }
                        />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
AppSwitchScreen.defaulProps = {
    text: ''
};
AppSwitchScreen.propTypes = {
    text: string
};

const mapStateToProps = state => {
    const account = state.account;
    return {
        baseAccount: state.account.baseAccount,
        baseAccounts: state.account.baseAccounts
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        switchBaseAccount: (baseId = '', accountId = '') => dispatch(actions.switchBaseAccount(baseId, accountId))
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppSwitchScreen));
