// required for JSX
import React from 'react';

/**
 * This file is used in a few places:
 * - Call Log display.
 *   Gives enhanced error messages and explainations
 *
 * - Registration status tooltip
 *   Gives summary messages
 *
 * - Registration status
 *   Gives summary messages
 **/

export function getExampleLogLists(account) {
    return (
        <ul>
            <li><a href={`/portal/${account.Id}/calls/demo_200_in_public_sip`}>Inbound success</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_200_in_short`}>Inbound short duration</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_one_public_sip`}>Inbound - One SIP message</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_200_out`}>Outbound success</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_cancel_pbx`}>Caller Cancelled</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_404_ms`}>404 RNL from Microsoft</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_403_pbx`}>403 from PBX</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_404_pbx`}>404 from PBX</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_488_dr`}>488 from Teams</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_504_dr`}>504 from Edge, out to Teams</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_200_rtp_broken`}>Broken RTP Message</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_c2tgo_outbound`}>C2TGo - Outbound</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_c2tgo_outbound_inbound`}>C2TGo - Outbound(Inbound)</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_c2tgo_inbound`}>C2TGo - Inbound</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_c2tgo_blind_transfer`}>C2TGo - Blind Transfer to PSTN</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_c2tgo_blind_transfer_ttt_AtoB`}>C2TGo - Blind Transfer-TTT A to B</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_c2tgo_blind_transfer_ttt_AtoC`}>C2TGo - Blind Transfer-TTT A to C</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_c2tgo_consult_transfer_ttt_AtoB`}>C2TGo - Consult Transfer-TTT A to B</a></li>
            <li><a href={`/portal/${account.Id}/calls/demo_c2tgo_consult_transfer_ttt_AtoC`}>C2TGo - Consult Transfer-TTT A to C</a></li>
        </ul>
    );
}

export function getErrorHelpSingleMessage(msg, method_name) {
    if (!method_name) {
        method_name = 'REGISTER';
    }
    var out = '';
    if (   msg.includes('Authentication Failure')
        || msg.includes('Forbidden')
        || msg.includes('Unauthorized')
        || msg.includes('User unknown')
        || msg.includes('could not register device')
        || msg.includes('Invalid password')
        || msg.includes('Method Not Allowed')
        || msg.includes('Not Found')
    ) {
        out = 'Please check the username and password entered.';
    }
    else if (  msg.includes('No response received after')
            || msg.includes('Connection refused')
    ) {
        out = `We did not receive any response to our SIP ${method_name} packet. Please check your service realm and proxy settings. Ensure your firewall is set to allow SIP traffic from our platform.`;
    }
    else if (   msg.includes('Not Acceptable')
             || msg.includes('Server Internal Error')
             || msg.includes('Bad Request')
    ) {
        out = 'Please check the service realm, proxy, username and password.';
    }
    else if (msg.includes('SSL connect failed')) {
        out = 'We could not establish an encrypted connection. Please check your certificate is valid, publicly trusted and includes the domain configured in the proxy or realm field.';
    }
    else if (msg.includes('Unable to resolve URI')) {
        out = 'We could not find a suitable DNS record for SIP. We try both "SRV" and "A" records. Please check the realm (or proxy) value entered and confirm the appropriate DNS records are in place.';
    } else if (msg.includes('Contact too long')) {
        out = `Our ${method_name} contact header was rejected as too long. Try enabling Contact Data Param suppression on the Services screen.`;
    }

    return out;
}

// Call SIP packet => Error
export function getErrorHelp(status_code, status_text, reason, method, frompart1, hasChallengeHeader) {
    // Bail out if it's an expected challenge response
    switch(status_code) {
        case 401:
        case 407:
            if (hasChallengeHeader) {
                return null; // fine, nothing to show here
            }
            break;
        default:
    }

    switch(status_code) {
        case 404:
            if (reason && reason.includes(';RNL')) {
                return {
                    'summary': <>Microsoft do not recognise the number set for this user.</>,
                    'detail': err_404_rnl_detail(reason)
                };
            } else {
                return {
                    'summary': <>This response indicates that the user does not exist at the domain specified in the Request-URI.</>,
                    'detail': <p>The destination that is trying to be reached is unreachable because the number is not allocated or defined.</p>
                };
            }
        case 486:
            return {
                'summary': <>The callee's end system was contacted successfully, but the callee is currently not willing or able to take additional calls.</>
            };
        case 488:
            return {
                'summary': <>A 488 error is typically caused by unsupported Codecs and/or incompatible encryption.</>,
                'detail' : err_488_detail()
            };
        case 504:
            if (status_text && status_text.toLowerCase().includes('int-bridge')) {
                if (frompart1) {
                    return {
                        'summary': 'The call failed to reach the upstream Teams direct routing endpoint.'
                    }
                } else {
                    return {
                        'summary': 'The call failed to reach the upstream PBX/Trunk endpoint.'
                    }
                }
            }
        default:
            if (status_code) {
                var prefix = null;
                if (status_code >= 400 && status_code < 500) {
                    prefix = <>Client error <strong>{status_code} {status_text}</strong>.</>
                } else if (status_code >= 500 && status_code < 600) {
                    prefix = <>Server error <strong>{status_code} {status_text}</strong>.</>
                }
                // No 'call' specific information, try general errors.
                var out = getErrorHelpSingleMessage(status_text, method);
                if (prefix || out) {
                    return {
                        'summary': <>{prefix} {out}</>
                    }
                }
            }
            if (reason && reason.toLowerCase().includes('rtp broken')) {
                return {
                    'summary': 'The platform ended the call due to a lack of RTP packets. Ensure your PBX/Trunk does not restrict media IP addresses.'
                }
            }
    }

    return null;
}

// Single 'message' from Registration mapping
// TBC
export function getCallErrorHelp(failure_text, frompart1) {
    if (!failure_text) {
        return null;
    }

    if (failure_text.toLowerCase().includes('error reaching upstream')) {
        if (!frompart1) {
            return {
                'summary': 'The upstream PBX/Trunk did not respond to our INVITE. Ensure your PBX/Trunk does not restrict SIP IP addresses.'
            };
        }
    }

    return null;
}

// Additional detail message sections
const err_404_rnl_detail = (reason_content) => {
    return <>
		<h4>Setup Delay</h4>

		<p>This is most common when an account is new. The reason is that the
user data configured into the Microsoft platform can take some time to
propagate through their system. The best solution here is just to wait. This
can take an hour or so in some cases but is often much quicker. </p>

<p>Occasionally the Microsoft platform is out of sync or an admin has changed
the user's number in the Office 365 portal, this will cause the Microsoft Teams
number associated with the user to be different to that set in the
portal and produce the mismatch.  </p>

		<h4>
		Reporting the issue to Microsoft
		</h4>

		<p>This has to be done by the customer with the
		problem as Microsoft will only deal with the account owner. There is no
		additional action than can be made by the portal or the administrator to
		resolve this. Microsoft however can re-sync the account.
		</p>

		<ul>
<li>Log in to the Office 365 admin portal at https://www.office.com/ </li>
<li>Go to the Admin icon</li>
<li>On the left hand side menu bar select "Show All" and the Support option will show,</li>
<li>Create a support request entitled something like "Unable to receive calls in Teams (404 error)"</li>
</ul>
<p>
		Within the detail of the support request, provide the following details:
		</p>

<ul><li>
		A User email address for one of the users with dial-pad that cannot receive calls.
</li></ul>
		<p>
		State that:</p>

		<ul>
		<li>Direct Routing is being used in Carrier Mode (SBCs will not show in the Teams Admin centre)</li>
		<li>Enterprise Voice is enabled for the user</li>
		<li>The Voice Routing Policy is applied to the user</li>
		<li>The user has a valid Phone System licence</li>
		<li>The user can place outbound calls</li>
		<li>The correct number is showing on the Teams admin centre and the user’s dial pad</li>
		<li>Microsoft Approved Direct Routing SBCs are being used.</li>
		<li>The calling service/trunk is getting a 404-rejection error from Microsoft
		Direct Routing when sending the call to Teams with message:<br/>
		<code>Reason
		header: {reason_content}</code>
		</li>
		<li>If Microsoft request SBC logs then refer to support to have these provided.</li>
		</ul>
    </>
};

const err_488_detail = () => {
    return (
        <>
        <h4>Unsupported Codecs</h4>

        <p>Start by checking the codecs being sent to the platform. Try limit
        the codecs to the most common used to see if that resolves the issue.
        PCMU and PCMA are recommended choices.</p>

        <p> The portal provides an option in the portal to filter on the
        Codecs. Note that this is a restrictive filter, so codecs that are
        missing will not be negotiated. The codecs selected here are in order
        of preference, which will then be reflected in the SDP.  </p>


        <h4>Incompatible Encryption</h4>
        <p>Confirm if the platform expects to be using media encryption. If so, enable this in the PBX/Trunk settings page.</p>
        </>
    );
};
