import React, { Component } from 'react';
import { Field } from 'formik';

import { isTrialCode } from '../LicenceHelpers';

class OrderFormTable extends Component {

    render() {
        var self = this;

        // No offers to place order against
        if (self.props.okOffers.length === 0) {
            return null;
        }

        // Figure out product exclusions...
        var hasTrialParts = false;
        var hasNonTrialParts = false;
        var usedPlanCodes = [];
        self.props.okOffers.forEach(function (offer) {
            var currentQuantity = self.props.values["quantity-" + offer.Id];
            if (currentQuantity > 0) {
                if (isTrialCode(offer.PlanCode)) {
                    hasTrialParts = true;
                } else {
                    hasNonTrialParts = true;
                }

                usedPlanCodes.push(offer.PlanCode);
            }
        });

        var knownIds = [];
        var plans = [];
        self.props.okOffers.forEach(function (offer) {
            if (!offer.IsAvailable) { return; }
            if (self.props.limitSubscriptions) {
                var shouldShow = false;
                self.props.limitSubscriptions.forEach(function(tag) {
                    if (offer.PlanCode.startsWith(tag)) {
                        shouldShow = true;
                    }
                });

                if (!shouldShow) {
                    return;
                }
            }

            // Ensure we don't render form components with undef values
            if (!self.props.values.hasOwnProperty("quantity-" + offer.Id)) {
                return;
            }

            // Already an ID we've covered
            // If an offer is enabled twice for an account (e.g. with different external IDs) then this can happen
            if (knownIds[offer.Id]) {
                return;
            } else {
                knownIds[offer.Id] = true;
            }

            var currentQuantity = self.props.values["quantity-" + offer.Id];

            var allowed = true;
            if (isTrialCode(offer.PlanCode) && hasNonTrialParts) {
                allowed = false;
            }
            if (!isTrialCode(offer.PlanCode) && hasTrialParts) {
                allowed = false;
            }

            if (allowed && currentQuantity === 0) {
                // Don't allow duplicate products
                if (usedPlanCodes.indexOf(offer.PlanCode) > -1) {
                    allowed = false;
                }
            }

            var addButton = null;
            if (allowed) {
                if (currentQuantity === 0) {
                    addButton = (
                        <button className="btn btn-success btn-sm" disabled={!allowed} onClick={(e) => {
                            e.preventDefault();
                            // Bump to a single value
                            var defaultValue = offer.MinQuantity;
                            if (isTrialCode(offer.PlanCode)) {
                                defaultValue = Math.min(25, offer.MaxQuantity);
                            }
                            self.props.setFieldValue('quantity-' + offer.Id, defaultValue);
                        }}><i className="fa-solid fa-plus"></i></button>
                    );
                } else {
                    addButton = (
                        <button className="btn btn-default btn-sm" disabled={!allowed} onClick={(e) => {
                            e.preventDefault();
                            // Bump to a single value
                            self.props.setFieldValue('quantity-' + offer.Id, 0);
                        }}><i className="fa-solid fa-xmark"></i></button>
                    );
                }
            }

            var basePrice = null;
            var newPrice = null;
            if (offer && offer.UnitPrice) {
                basePrice = (
                    <>
                        <span>{offer.CurrencyCode}</span>&nbsp;<span>{(offer.UnitPrice).toFixed(2)}</span>
                    </>
                );
                newPrice = (
                    <>
                        <span>{offer.CurrencyCode}</span>&nbsp;<span>{(self.props.values["quantity-" + offer.Id] * offer.UnitPrice).toFixed(2)}</span>
                    </>
                );
            }

            plans.push(
                <tr key={offer.Id + offer.Code}>
                    <td title={offer.Code}>{offer.Description ? offer.Description : offer.PlanName} ({offer.Code})</td>
                    <td className="text-center">
                        {addButton}
                    </td>
                    <td className="text-right">
                        <Field
                            className="form-control quantity-field"
                            min={offer.MinQuantity}
                            max={offer.MaxQuantity}
                            type="number"
                            name={"quantity-" + offer.Id}
                            disabled={!allowed || currentQuantity === 0}
                        />
                    </td>
                    <td className="text-right">
                        {basePrice}
                    </td>
                    <td className={"text-right " + (!allowed ? "text-muted" : "")}>
                        {newPrice}
                    </td>
                </tr>
            );
        })

        if (plans.length === 0) {
            // <p>There are currently no offers available for your account</p>;
            return null;
        }

        return (
            <table className="table order-table">
                <thead>
                    <tr>
                        <th>Plan</th>
                        <th></th>
                        <th className="text-left col-quantity">Quantity</th>
                        <th className="text-left col-unit">Unit Price</th>
                        <th className="text-left col-total">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {plans}
                </tbody>
            </table>
        );
    }
}

export default OrderFormTable;
