import React, { Component } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import AccountBreadcrumbs from './AccountBreadcrumbs';
import {
    getUserName,
    triggerSwitchAccounts,
    triggerLogout,
    getUserDisplayName
} from './MSALAuthProvider';

import { connect } from 'react-redux';
import * as actions from './store/actions/index';

var _ = require('lodash');

class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu(e) {
        let self = this;
        e.preventDefault();
        self.setState(({ expanded: !self.state.expanded }))
    }

    switchAccount = (accId = '') => {
        this.props.switchBaseAccount(accId, accId);
    }

    render() {
        let self = this;
        function doLogOut(e) {
            e.preventDefault();
            triggerLogout();
        }

        function switchAccount(e) {
            e.preventDefault();
            triggerSwitchAccounts();
        }

        var baseAccountList = null;
        // If there is only one base account don't show it. Even if user is in
        // child account, they'll then get the 'up' arrow so don't need the list.
        if (this.props.baseAccounts.length > 1) {
            var baseAccounts = [
                <li key='noAccs' className='NoAccPlaceholder'>No accounts available.</li>
            ];
            if (this.props.baseAccounts && this.props.baseAccounts.length) {
                baseAccounts = this.props.baseAccounts.map(acc => {
                    var styles = 'baseAccountSelectWrapper';
                    if (acc.Id === this.props.baseAccount.Id) {
                        styles += ' currentId';
                    }
                    return (
                        <li
                            key={acc.Id}
                            className={styles}
                            title={`Brand: ${acc.Brand}`}>
                                <button onClick={() => this.props.switchBaseAccount(acc.Id, acc.Id, () => {
                                    self.props.forceRemount();
                                    self.props.history.push("/accounts/" + acc.Id);
                                })}>
                                    {acc.Name}
                                </button>
                        </li>
                    );
                });
            }

            baseAccountList = (
                <ul className='profile-menu profile-menu--list'>
                    <li className='title'>Base Accounts</li>
                    {baseAccounts}
                </ul>
            );
        }

        var isOpenClass = 'dropdown';
        if (this.state.expanded) {
            isOpenClass += ' open';
        }

        var shoppingLinkButton = null;
        var supportLinkButton = null;

        if (this.props.baseAccount) {
            var shoppingLink = this.props.getLinkUrl('store');
            if (shoppingLink) {
                shoppingLinkButton = (
                    <div className='shopping-link'><a href={shoppingLink.URL} className='btn btn-default btn-default-extra-space'><i className='fa-solid fa-cart-shopping'></i></a></div>
                );
            }

            var supportLink = this.props.getLinkUrl('support');
            if (supportLink) {
                supportLinkButton = (
                    <div className='support-link'>
                        <a target="_blank" href={supportLink.URL} data-testid='supportIconNavBarId' className="btn btn-default btn-default-tight"><i className="fa-regular fa-circle-question"></i></a>
                        {supportLink.URL.toLowerCase().includes("destinygroup.atlassian.net") &&
                            <div className='support-popup'>
                                <div><b>UK/EU Support:</b> +44 330 822 5055</div>
                                <div><b>US Support:</b> +1 917 473 9492</div>
                            </div>
                        }
                    </div >
                );
            }
        }

        var userName = getUserName();
        var displayName = getUserDisplayName();

        return (
            <div className='profile-menu-wrapper'>

                {shoppingLinkButton}
                {supportLinkButton}

                <div className={isOpenClass}>
                    <div className="w-75" style={{float: 'left', whiteSpace: 'nowrap'}}>
                        <div className="displayName">{displayName}</div>
                        <div className="account-Name" title={this.props.account.Name}>{this.props.account.Name}</div>
                    </div>
                    <div className="w-25" style={{float: 'right', paddingLeft: '5px', whiteSpace: 'nowrap'}}>
                        <button className='profile-menu-btn btn btn-default btn-default-tight dropdown-toggle' data-testid='profileIconId' type='button' onClick={(e) => this.toggleMenu(e)}>
                            <i className={`fa-solid fa-chevron-${this.state.expanded ? 'up' : 'down'}`}></i>
                        </button>
                    </div>
                    <div className='dropdown-menu'>
                        {baseAccountList}
                        {this.props.hasRole('SystemUserSummary') ? (
                            <ul className='profile-menu system-owner-bg'>
                                <li className='username'><i className='fa-solid fa-user-secret'></i> <small>System Owner Only</small></li>
                                <li className='username'><strong>Brand:</strong> {this.props.baseAccount.Brand}</li>
                                <li className='username'><strong>Contract Acc:</strong> {this.props.contractAccount.Name} ({this.props.contractAccount.Id})</li>
                                <li className='username'>
                                    <NavLink to={`/portal/${this.props.account.Id}/tools`}>Call Log Tools</NavLink>
                                </li>
                                <li className='username'>
                                    <NavLink to={`/portal/${this.props.account.Id}/call-logs`}>Call Log List</NavLink>
                                </li>
                            </ul>
                        ) : <hr className='small' />}
                        <ul className='profile-menu profile-menu--sign-out'>
                            <li className='username'>{userName}</li>
                            <li className='profile-menu-action'>
                                <button className='btn btn-default btn--full-length' onClick={switchAccount}>
                                    <i className='fa-solid fa-arrow-right-arrow-left'></i>
                                    &nbsp;
                                    Switch Account
                                </button>
                            </li>
                            <li className='profile-menu-action'>
                                <button className='btn btn-default btn--full-length' onClick={doLogOut}>
                                    <i className='fa-solid fa-right-from-bracket'></i>
                                    &nbsp;
                                    Log out
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        contractAccount: account.contractAccount,
        accountHierarchy: account.accountHierarchy,
        account: account.account,
        baseAccount: account.baseAccount,
        baseAccounts: account.baseAccounts
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart)),
        getLinkUrl: (exLinkTypeName = '') => dispatch(actions.getLinkUrl(exLinkTypeName)),
        switchBaseAccount: (baseId = '', accountId = '', callBack = null) => dispatch(actions.switchBaseAccount(baseId, accountId, callBack))
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileMenu));