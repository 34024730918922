import React, { Component } from 'react';
import SyncJobTable from './SyncJob/SyncJobTable';
import ActionHeader from '../ActionHeader.js';
import ServiceSync from '../Services/ServiceSync';
import RefreshButton from '../RefreshButton';
import { cmsGetServices } from '../CallMSAPI.js';
import { isSystemOwnerSystemAcc } from '../CallMSUIHelpers';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class SyncJobs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            services: null,
            tableRevision: 1
        };

        this.triggerTableUpdate = this.triggerTableUpdate.bind(this);
    }

    triggerTableUpdate(force) {
        this.setState(prevState => ({
            tableRevision: prevState.tableRevision + 1
        }));
    }

    render() {
        var self = this;
        const services = self.props.services.rawList;

        if (!self.props.services.loaded) {
            return (<p>Loading services...</p>);
        }

        var serviceSync = null;
        if (services.length) {
            serviceSync = <ServiceSync />;
        }

        return (
            <div className="Portal">
                <ActionHeader headerText="Sync Jobs">{ serviceSync }</ActionHeader>
                        <RefreshButton
                            refreshPaused={[]}
                            refreshInProgress={false}
                            triggerRefresh={self.triggerTableUpdate}
                        />
                <hr />

                <SyncJobTable
                    services={services}
                    lastUpdate={this.state.tableRevision}
                />
            </div>
        );
    }
}
export default connect(state => ({
    services: state.services
}))(SyncJobs);