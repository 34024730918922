import React, { PureComponent } from 'react';
import { Field } from 'formik';
import { EditPasswordField } from '../CallMSUIHelpers';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { SortableList } from './ServiceFormHelpers';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { CountryHOC, PhoneNumberField } from './ServiceFormHelpers';
import CountryIdAsyncSelect from '../CountryIdAsyncSelect';

var _ = require('lodash');

class FieldWithDisposition extends PureComponent {

    constructor(props) {
        super(props);
        this.state = this.processProps(props);

        this.toggleLock = this.toggleLock.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
        this.dismissHelp = this.dismissHelp.bind(this);
        this.isLocked = this.isLocked.bind(this);
        this.toggleWarning = this.toggleWarning.bind(this);
        this.dismissWarning = this.dismissWarning.bind(this);
        this.hasTemplateWarning = this.hasTemplateWarning.bind(this);
        this.getTemplateWarning = this.getTemplateWarning.bind(this);
        this.getTemplateWarningBadValue = this.getTemplateWarningBadValue.bind(this);
    }

    processProps(props) {
        return {
            tempUnlocked: false,
            showHelp: false,
            showWarning: false,
        };
    }

    isLocked() {
        if (this.props.visibility === 'View') {
            return true;
        } else if ((this.props.visibility.indexOf('Locked') > -1) && !this.state.tempUnlocked) {
            return true;
        }
        return false;
    }

    toggleLock(e) {
        e.preventDefault();

        this.setState(prevState => ({ tempUnlocked: !prevState.tempUnlocked }));
    }

    toggleHelp() {
        this.setState(prevState => ({ showHelp: !prevState.showHelp }));
    }

    dismissHelp() {
        this.setState({ showHelp: false });
    }

    toggleWarning() {
        this.setState(prevState => ({ showWarning: !prevState.showWarning }));
    }

    dismissWarning() {
        this.setState({ showWarning: false });
    }

    getOptionLabel = (value, options) => {
        var option = _.find(options, { value: value });

        return option !== null
            ? option.label
            : '';
    }

    hasTemplateWarning = (objId) => {
        var self = this;    
        return !/custom/i.test(self.props.template.Name)
            &&  [
                    "LockedEditMandatory",
                    "LockedEditOptional ",
                    "View"
                ].includes(self.props.visibility)
            && self.props.template.hasOwnProperty(objId)
            && self.props.template[objId]
            && self.props.formValues.hasOwnProperty(objId)
            && self.props.formValues[objId]
            && self.props.formValues[objId] !== self.props.template[objId]
    }

    getTemplateWarning = (obj) => {
        var self = this;
        return (
            <>
                <button className="btn btn-link btn--faux-link" style={{ color: '#ffc107' }} onClick={(e) => { e.preventDefault(); self.toggleWarning() }}>
                    <i className="fa-solid fa-triangle-exclamation" id={"warningFor-" + obj.Id}></i>
                </button>
                {self.state.showWarning ?
                    <TeachingBubble
                        target={'#warningFor-' + obj.Id}
                        hasCondensedHeadline={true}
                        onDismiss={() => self.dismissWarning()}
                        hasCloseIcon={true}
                        closeButtonAriaLabel="Close"
                        headline="Non-Standard Template Value"
                        primaryButtonProps={{
                            children: "Restore Template Value",
                            onClick: () => {
                                self.props.setFieldValue(obj.Id, self.props.template[obj.Id]);
                                self.dismissWarning()
                            },
                        }}>
                        <div className="row">
                            <div className="col-md-12">
                                {`The ${obj.Label} value in the ${self.props.template.Name} template is ${obj.Type === 'select'
                                    ? self.getOptionLabel(self.props.template[obj.Id], obj.Values)
                                    : self.props.template[obj.Id]}`}
                            </div>
                        </div>
                    </TeachingBubble>
                : null}
            </>
        )
    }

    getTemplateWarningBadValue = (obj, value) => {
        var self = this;
        var warningText = obj.ValueSetWarning(value);

        if (warningText) {
            return (
                <>
                    <button className="btn btn-link btn--faux-link" style={{ color: '#ffc107' }} onClick={(e) => { e.preventDefault(); self.toggleWarning() }}>
                        <i className="fa-solid fa-triangle-exclamation" id={"warningFor-" + obj.Id}></i>
                    </button>
                    {self.state.showWarning ?
                        <TeachingBubble
                            target={'#warningFor-' + obj.Id}
                            hasCondensedHeadline={true}
                            onDismiss={() => self.dismissWarning()}
                            hasCloseIcon={true}
                            closeButtonAriaLabel="Close"
                            headline="Non-Recommended Value"
                        >
                            <div className="row">
                                <div className="col-md-12">{warningText}</div>
                            </div>
                        </TeachingBubble>
                    : null}
                </>
            )
        } else {
            return null;
        }
    }

    render() {
        var self = this;
        var obj = self.props.formObject;

        // Pass in an optional onChangeHook.
        // If that's set, set
        var customProps = {};
        var onChangeHook = obj.onChangeHook;
        if (onChangeHook) {
            customProps['onChange'] = function (e) {
                var newVal = e.target.value;
                self.props.setFieldValue(obj.Id, newVal).then((res) => {
                    onChangeHook && onChangeHook(obj.Id, newVal, self.props.formValues, self.props.setFieldValue);
                });
            };
        }

        var lockedEdit = null;
        if (this.props.visibility.indexOf('Locked') > -1) {
            var lockIcon = (this.isLocked() ? <i className="fa-solid fa-lock"></i> : <i className="fa-solid fa-unlock"></i>);
            lockedEdit = <button className="btn btn-link" onClick={(e) => this.toggleLock(e)}>{lockIcon}</button>;
        }

        let isUIDisabled = false;
        if (obj.hasOwnProperty('IsUIDisabled') && obj.IsUIDisabled != null) {
            isUIDisabled = obj.IsUIDisabled;
        }

        var required = null;
        if (this.props.visibility.indexOf('Mandatory') > -1) {
            required = <span className="required">*</span>;
        }

        var help = null;

        if (obj.hasOwnProperty('Help')) {
            help = (
                <>
                <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleHelp() }}>
                    <i className="fa-solid fa-question-circle" id={"helpFor-" + obj.Id}></i>
                </button>
                {self.state.showHelp ?
                    <TeachingBubble
                        target={'#helpFor-' + obj.Id}
                        hasCondensedHeadline={true}
                        onDismiss={() => self.dismissHelp()}
                        hasCloseIcon={true}
                        closeButtonAriaLabel="Close"
                        headline={obj.HelpTitle}
                    >
                        {obj.Help}
                    </TeachingBubble>
                    : null}
                </>
            );
        }
        
        var warning = null;

        // Warning 1: Value doesn't match the template
        if (   self.props.hasOwnProperty('template')
            && self.props.template
            && (obj.Type === 'text' || obj.Type === 'select')
            && self.hasTemplateWarning(
                obj.Id)
        ) {
            warning = self.getTemplateWarning(obj);
        }

        // Warning 2: Value is deprecated/discouraged
        if (warning === null && obj.hasOwnProperty('ValueSetWarning')) {
            warning = self.getTemplateWarningBadValue( obj, self.props.formValues[obj.Id] );
        }

        var label = (<label>{obj.Label} {required} {lockedEdit} {help} {warning}</label>)

        if (obj.hasOwnProperty('Tooltip')) {
            var calloutProps = { gapSpace: 0 };

            label = (
                <TooltipHost content={obj.Tooltip} calloutProps={calloutProps}>
                    {label}
                </TooltipHost>
            );
        }

        if (obj.Type === 'text' || obj.Type === 'number') {
            return (
                <>
                    {label}
                    <Field type={obj.Type} name={obj.Id} className="form-control" disabled={this.isLocked() || isUIDisabled} readOnly={obj.readOnly} placeholder={obj.placeholder} />
                    <span className="error-message">{self.props.errors[obj.Id] && self.props.touched[obj.Id] && self.props.errors[obj.Id]}</span>
                </>
            );
        } else if (obj.Type === 'password') {
            return (
                <>
                {label}
                    <EditPasswordField name={obj.Id} disabled={this.isLocked() || isUIDisabled} hasPassword={obj.HasPassword} />
                <span className="error-message">{self.props.errors[obj.Id] && self.props.touched[obj.Id] && self.props.errors[obj.Id]}</span>
                </>
            );
        } else if (obj.Type === 'checkbox') {
            return (
                <>
                {label}
                <div className="field-disposition-checkbox">
                <Field name={obj.Id}>
                    {({ field, form }) => (
                        <label className="no-bold">
                            <input
                                type="checkbox"
                                checked={field.value && field.value.includes(obj.CheckedValue)}
                                disabled={this.isLocked() || isUIDisabled}
                                onChange={() => {
                                    var nextValue;
                                    if (field.value && field.value.includes(obj.CheckedValue)) {
                                        nextValue = field.value.filter(
                                            value => value !== obj.CheckedValue
                                        );
                                        form.setFieldValue(obj.Id, nextValue);
                                    } else {
                                        if (field.value) {
                                            nextValue = field.value.concat(obj.CheckedValue);
                                            form.setFieldValue(obj.Id, nextValue);
                                        } else {
                                            form.setFieldValue(obj.Id, ["", obj.CheckedValue]);
                                        }
                                    }

                                    // Add callback capability to set other values from this one...
                                    if (obj.NewValueCB) {
                                        obj.NewValueCB(form.setFieldValue, nextValue);
                                    }
                                }}
                            />
                            &nbsp; {obj.CheckedLabel}
                        </label>
                    )}
                </Field>
                </div>
                <span className="error-message">{self.props.errors[obj.Id] && self.props.touched[obj.Id] && self.props.errors[obj.Id]}</span>
                </>
            );
        } else if (obj.Type === 'sortablelist') {
            return (
                <>
                {label}
                <SortableList
                    disabled={this.isLocked() || isUIDisabled}
                    name={obj.Id}
                    formValues={self.props.formValues}
                    placeholder={obj.Placeholder}
                    values={self.props.formValues}
                    options={obj.Values}
                    setFieldValue={self.props.setFieldValue}
                />
                <span className="error-message">{self.props.errors[obj.Id] && self.props.touched[obj.Id] && self.props.errors[obj.Id]}</span>
                </>
            );
        } else if (obj.Type === 'select') {
            if (obj.Values) {
                let options = [];
                obj.Values.forEach(function (v) {
                    options.push(<option key={v.value} value={v.value} disabled={isUIDisabled || v.hasOwnProperty('disabled') ? v.disabled : false}>{v.label}</option>);
                });
                return (
                    <>
                        {label}
                        <Field {...customProps} value={self.props.formValues[obj.Id]} component="select" name={obj.Id} className="form-control" disabled={isUIDisabled || this.isLocked()}>
                            {options}
                        </Field>
                        <span className="error-message">{self.props.errors[obj.Id] && self.props.touched[obj.Id] && self.props.errors[obj.Id]}</span>
                    </>
                );
            } else {
                return null;
            }
        } else if (obj.Type === 'country') {
            return (
                <>
                {label}
                <div className="form-control-height">
                    <CountryIdAsyncSelect
                        countryName="CountryId"
                        countryValue={self.props.formValues.CountryId}
                        stateName="StateId"
                        stateValue={self.props.formValues.StateId}
                        onChange={self.props.setFieldValue}
                        disabled={this.isLocked() ||isUIDisabled}
                    />
                </div>
                <span className="error-message">{self.props.errors[obj.Id] && self.props.touched[obj.Id] && self.props.errors[obj.Id]}</span>
                </>
            );
        } else if (obj.Type === 'phonenumber') {
            return (
                <>
                    {label}
                    <CountryHOC
                        CountryId={self.props.obProps.CountryId}
                        StateId={self.props.obProps.StateId}
                        accountId={self.props.obProps.accountId}
                    ><PhoneNumberField
                            name={obj.Id}
                            setFieldValue={self.props.obProps.setFieldValue}
                            service={self.props.obProps.service}
                            hideLabel={self.props.obProps.hideLabel}
                            alternateLabel={self.props.obProps.alternateLabel}
                            disabled={this.isLocked() || isUIDisabled}
                            value={self.props.formValues.EssentialsPhoneNumber}
                        />
                    </CountryHOC>
                    <span className="error-message">{self.props.errors[obj.Id] && self.props.errors[obj.Id]}</span>
                </>
            );
        } else {
            return <p>Error: No match for type: {obj.hasOwnProperty('Type') ? obj.Type : '[no-type-given]'}</p>;
        }
    }

}
export default FieldWithDisposition;
