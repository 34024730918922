export default class ServiceUserService
{
    static hashServiceUserParts(values)
    {
        if (values.Part1.SyncUserPart && values.Part1.SyncUserPart.hasOwnProperty('Id')) {
            values.Part1Id = values.Part1.SyncUserPart.Id;
            delete values.Part1;
        } else if (values.Part1.Id) {
            values.Part1Id = values.Part1.Id;
            delete values.Part1;
        }
        if (values.Part2.SyncUserPart && values.Part2.SyncUserPart.hasOwnProperty('Id')) {
            values.Part2Id = values.Part2.SyncUserPart.Id;
            delete values.Part2;
        } else if (values.Part2.Id) {
            values.Part2Id = values.Part2.Id;
            delete values.Part2;
        }

        return values;
    }
}