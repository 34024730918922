import React, { Component } from 'react';
import { iconWarning, iconify } from '../CallMSUIHelpers.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

class DNSRecords extends Component {
    justCopiedAction(text) {
        toast.success(text + " copied to clipboard");
    }

    render() {
        var self = this;
        var DNSRecords = [];
        this.props.domain.DNSRecords.forEach(function (r) {
            var testLink = null;
            if (self.props.showTestLink && !r.Validated) {
                testLink = (
                    <a href={"https://mxtoolbox.com/SuperTool.aspx?action=cname%3a" + r.FQDN} target="_blank" rel="noopener noreferrer">
                        Check Now <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                );
            }
            DNSRecords.push(
                <tr key={r.FQDN + "-" + r.Type + "-" + r.Target}>
                    <td>{r.FQDN}</td>
                    <td>{r.Type}</td>
                    <td>
                        <CopyToClipboard text={r.Target}
                            onCopy={() => self.justCopiedAction( r.Target )}>
                            <button className="btn btn-link">
                                <span>{r.Target}</span> <i className="fa-solid fa-copy"></i>
                            </button>
                        </CopyToClipboard>
                    </td>
                    {self.props.showPorts ? <td>{r.Port}</td> : null}
                    <td>
                        {iconify(r.Validated)} {testLink}
                    </td>
                    <td>{!r.Validated && r.Published ? iconWarning() : iconify(r.Published)}</td>
                </tr>
            );
        })

        if (DNSRecords.length === 0) {
            return null;
        }

        return (
            <>
            <h4>{self.props.domain.DomainName} DNS Records</h4>
            <table className="table table-condensed table-dns-records">
                <thead>
                    <tr>
                        <th>FQDN</th>
                        <th>DNS Type</th>
                        <th>Target</th>
                        {this.props.showPorts ? <th>Port</th> : null}
                        <th>DNS Validated</th>
                        <th>Ready</th>
                    </tr>
                </thead>
                <tbody>
                    {DNSRecords}
                </tbody>
            </table>
            </>
        );

    }
}
export default DNSRecords;
