import React, { Component } from 'react';

class CallLight extends Component {

    render() {
        const Qtooltip = props => {
            var inboundState = 'circle-na no-call-data-light';
            var inMessage = props.inbound;
            if (props.inbound === 'Success') {
                inboundState = 'circle-ok';
            } else if (props.inbound === 'InProgressSuccess') {
                inboundState = 'circle-ok call-in-progress';
                inMessage = "Call In Progress";
            } else if (props.inbound === 'Failed') {
                inboundState = 'circle-problem';
            }

            var outboundState = 'circle-na no-call-data-light';
            var outMessage = props.outbound;
            if (props.outbound === 'Success') {
                outboundState = 'circle-ok';
            } else if (props.outbound === 'InProgressSuccess') {
                outboundState = 'circle-ok call-in-progress';
                outMessage = "Call In Progress";
            } else if (props.outbound === 'Failed') {
                outboundState = 'circle-problem';
            }

            return (
                <div className="q-popup-status-wrapper">
                    <i className={inboundState + " fa-solid fa-arrow-down"} data-original-title="Calls"></i>
                    &nbsp;
                    <i className={outboundState + " fa-solid fa-arrow-up"} data-original-title="Calls"></i>
                    <div className="q-tooltip text-left q-tooltip--call-summary">
                        <>
                        <h5>Recent Calls</h5>
                        <p>
                            <strong>Latest to {props.Part2ServiceName}:</strong> {inMessage}
                        </p>
                        <p>
                            <strong>Latest from {props.Part2ServiceName}:</strong> {outMessage}
                        </p>
                        <button onClick={props.triggerCallLog} className="btn btn-link">View Recent Call Log</button>
                        </>
                    </div>
                </div>
            );
        };

        var user = this.props.User;
        var serviceName = this.props.service && this.props.service.Name ? this.props.service.Name : null;
        return (
            <Qtooltip
                triggerCallLog={this.props.triggerCallLog}
                Part2ServiceName={serviceName}
                inbound={user.CallStatusFromPart1}
                outbound={user.CallStatusFromPart2}
            />
        );

    }
}

export default CallLight;
