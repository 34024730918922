import React, { Component } from 'react';

class AvailableServicesFormPart extends Component {

    constructor(props) {
        super(props);

        this.state = { showFullService: false };
        this.toggleFullService = this.toggleFullService.bind(this);
    }

    toggleFullService() {
        this.setState(prevState => ({
            showFullService: !prevState.showFullService
        }))
    }

    render() {
        var self = this;

        var serviceRows = [];
        var variants = [];
        self.props.values.AvailableServicesBase.forEach(function (s) {
            s.Variants.forEach(function (v) {
                v.FullServiceName = s.Name;
                variants[v.Id] = v;
            });
        });

        if (self.props.values.AvailableServicesTarget) {
            self.props.values.AvailableServicesTarget.forEach(function (s) {
                s.Variants.forEach(function (v) {
                    v.FullServiceName = s.Name;
                    variants[v.Id] = v;
                });
            });
        }

        variants.forEach(function (v) {
            serviceRows.push(
                <div key={v.Id} className="checkbox">
                    <label><input type="checkbox"
                        name={v.Id} defaultValue={true}
                        checked={self.props.values[v.Id]}
                        onChange={self.props.handleChange}
                        onBlur={self.props.handleBlur} /> {v.FullServiceName}: {v.Name} </label>
                </div>
            );
        });

        var serviceDisplay = null;
        if (self.state.showFullService) {
            serviceDisplay = (
                <>
                {serviceRows}
                <button className="btn btn-default btn-sm" onClick={self.toggleFullService}>Done</button>
                </>
            );
        } else {
            serviceDisplay = !self.props.disabled
                ? <button className="btn btn-link btn-link--faux btn-sm" onClick={self.toggleFullService}><em>Edit Available Services</em></button>
                : null;
        }

        return serviceDisplay;
    }

}

export default AvailableServicesFormPart;
