/**
 * Client idle timeout for event listeners of mousemove, focus and blur
 * @param {number} expSecs The amount of seconds before calling the resetCallback
 * @param {Function} initCallBack The callback when an event is trigger e.g. mousemove
 * @param {Function} resetCallBack The callback when to client has become idle from expSecs
 */
export const idleTimeout = (expSecs = 15, initCallBack = (_ev = new FocusEvent()) => { }, resetCallBack = (_ev = new FocusEvent()) => { }) => {

    let timer = null;
    let mounted = false;

    const resetTimer = (resetCallBack) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            mounted = false;
            resetCallBack();
        }, expSecs * 1000);
    }

    const getEvent = (ev = new FocusEvent()) => {
        if (!mounted) {
            mounted = true;
            initCallBack(ev);
        }
      
        resetTimer(() => resetCallBack(ev));
    }

    const removeEventListeners = () => {
        window.removeEventListener("mousemove", getEvent);
        window.removeEventListener("focus", getEvent);
        window.removeEventListener("blur", getEvent);
    }

    window.addEventListener("mousemove", getEvent);
    window.addEventListener("focus", getEvent);
    window.addEventListener("blur", getEvent);

    return removeEventListeners;
}