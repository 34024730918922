import React, { Component } from 'react';
import { cmsGetPromise } from '../../CallMSAPI.js';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { showConsoleLogs } from '../../CallMSUIHelpers.js';

const validFileTypes = ['.png'];

var _ = require('lodash');

const defaultCropSettings = {
    unit: 'px',
    width: 180,
    height: 54,
    x: 0,
    y: 0,
};

class PhoneAppBrandingIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            size: 0,
            width: 0,
            height: 0
        };

        this.uploadId = _.uniqueId('upload_');

        this.onSelectFile = this.onSelectFile.bind(this);
        this.downloadImage = this.downloadImage.bind(this);
    }

    componentDidMount() {
        if (this.props.isDraft != null && this.props.data.PublishedState != 'None') {
            this.downloadImage();
        } 
    }

    downloadImage = () => {
        var self = this;
        let isPublished = false;
        if (self.props.data.PublishedState === 'Published') {
            isPublished = true;
        }

        cmsGetPromise({
            accountId: self.props.account.Id,
            objectType: self.props.objectType,
            imageExternalLinkType: self.props.type,
            isPublished: isPublished
        }).then(response => {

            if (response && response.data) {
                var image = `data:${response.data.ContentType};base64,${response.data.Image}`;

                self.getImageDimensions(image).then(dimensions => {
                    self.setState({
                        type: response.data.ContentType,
                        size: response.data.Image.length,
                        width: dimensions.width,
                        height: dimensions.height
                    })
                    if (self.props.type === "PhoneAppSideBarLogo") {
                        self.props.setFieldValue("PhoneAppSideBarLogo", image);
                    } else if (self.props.type === "PhoneAppHeaderLogo")  {
                        self.props.setFieldValue("PhoneAppHeaderLogo", image);
                    }
                })
            }
        }).catch((err) => {
            if(showConsoleLogs()){
                console.log("err", err)
            }
        })
    }

    getImageDimensions = (file) => {
        return new Promise(function (resolved) {
            var image = new Image()
            image.onload = function () {
                resolved({ width: image.width, height: image.height })
            };
            image.src = file
        })
    }

    isValidFileType = (type) => {
        return type && validFileTypes.includes(type.replace("image/", "."));
    }

    isValidFileSize = (size) => {
        return size && !((size / Math.pow(1024, 2.0)) > 1);
    }

    onSelectFile = (e) => {
        var self = this;
        if (e.target.files
            && e.target.files.length > 0
            && this.isValidFileType(e.target.files[0].type)) {

            var file = e.target.files[0];
            const reader = new FileReader();

            reader.addEventListener('load', () => {
                self.getImageDimensions(reader.result).then(dimensions => {
                    self.setState({
                        type: file.type,
                        size: file.size,
                        width: dimensions.width,
                        height: dimensions.height,
                    })
                    self.props.setFieldValue(self.props.type, reader.result);
                })
            });

            reader.readAsDataURL(file);
        }
    };

    render = () => {
        var self = this;

        const checkIsValid = (values) => {
            let correctSize = self.props.header === "Outline Icon" ? 32 : 192;

            if (values[self.props.type]) {
                if (self.state.width == correctSize && self.state.height == correctSize) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }

        }

        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <h2>{self.props.header}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-info domain-form-wrapper">
                            <div className="row">
                                <div className={self.props.value ? "col-md-6" : "col-md-12"}>
                                    {self.props.value
                                        ?
                                        <>
                                            <h4>Preview</h4>
                                            <div className="logo-container">
                                                {self.props.value
                                                    ? <img className="img-logo" src={self.props.value} alt="no image" />
                                                    : <div className="no-image">no image</div>
                                                }
                                            </div>
                                            {self.props.value && <h5>Height: {self.state.height}px, Width: {self.state.width}px</h5>}
                                            {self.props.recommendations &&
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p><em>{self.props.recommendations}</em></p>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        : <p><em>{`You don't currently have a ${self.props.header.toLowerCase()} configured. Use the button below to get started and upload one.`}</em></p>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input type="file" id={self.uploadId} name={self.uploadId} accept={`${validFileTypes.join(",")}`} onChange={self.onSelectFile} style={{ display: "none" }} />
                                                <label style={{ width: "200px", backgroundColor: "rgb(239, 239, 239)" }} className="btn mb-2" htmlFor={self.uploadId}>Choose Image To Upload</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {self.props.errors[self.props.type] &&
                                                    <span className="error-message">{self.props.errors[self.props.type]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refreshAccount: (callBack = () => { }) => dispatch(actions.refreshAccount(callBack))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PhoneAppBrandingIcon);