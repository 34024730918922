import { Link } from 'react-router-dom'
import ProfileMenu from './ProfileMenu';
import React, { Component } from 'react';
import Favicon from 'react-favicon';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import { isOnlyBrandContributor } from './CallMSUIHelpers';
import AccountBreadcrumbs from './AccountBreadcrumbs';

var _ = require('lodash');

class Header extends Component {

    constructor(props)
    {
        super(props);

        this.state = {
            toggleNavBar: false,
            profileMenuKey: 0
        }

        this.forceHeaderRemount = this.forceHeaderRemount.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.account.Id != this.props.account.Id){
            this.forceUpdate();
        }
    }

    forceHeaderRemount = () => {
        let self = this;
        self.setState({ profileMenuKey: (self.state.profileMenuKey + 1) });
    }

    getUrlExt = (url = '') => {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    renderBrandName = (brandAccount, account) => {
        let content = null;

        if (!brandAccount) {
            return content;
        }

        if (brandAccount.hasOwnProperty('Brand')) {
            let titleStr = account && account.Name && account.Brand ? `${account.Name} - ${account.Brand}` : `${brandAccount.Brand}`;
            titleStr += ' - Admin Portal';
            document.title = titleStr;
            content = <span className="logo-text dynamic-brand-name">{brandAccount.Brand}</span>;
        }

        const brandImage = this.props.getLinkUrl('header-logo');

        if (brandImage) {
            if (brandImage.URL) {
               
                const urlFileType = this.getUrlExt(brandImage.URL)
                const isSvg = urlFileType === 'svg' || urlFileType === 'svg+xml'; 

                content = <img src={brandImage.URL} data-testid='imageLogoId' className={`logo-img ${isSvg ? 'w-100' : ''}`} alt={`${brandAccount.Brand} logo`} />
            }

        }

        return content;
    }

    toggleNavBar = () => this.setState({ toggleNavBar: !this.state.toggleNavBar });

    handleLogoLink = () => {

        if (isOnlyBrandContributor(this.props.account.roles)) {
            return `/accounts/${this.props.account.Id}/branding`;
        }

        if (this.props.accountHierarchy && this.props.accountHierarchy.length >= 2) {
            return `/accounts/${this.props.accountHierarchy[this.props.accountHierarchy.length - 2].Id}/`;
        }

        return '/';
    }

    render() {
        let self = this;
        const { toggleNavBar } = this.state;

        var liLinks = [];

        this.props.links.forEach(function (l) {
            liLinks.push(<li key={l.key}>{l}</li>);
        });

        let favIcon = null;
        const brandFavicon = this.props.getLinkUrl('favicon');

        if (brandFavicon) {
            favIcon = <Favicon url={brandFavicon.URL} />
        }

        return (
            <>
                <nav className='navbar navbar-default' role='navigation'>
                    <div className='container container--header'>
                        <div className='navbar-header'>
                            <button
                                type='button'
                                className='navbar-toggle collapsed'
                                onClick={this.toggleNavBar}
                                aria-expanded={toggleNavBar.toString()}
                                >
                                <span className='sr-only'>Toggle navigation</span>
                                <span className='icon-bar' />
                                <span className='icon-bar' />
                                <span className='icon-bar' />
                            </button>
                            <Link to={this.handleLogoLink()} className='navbar-brand'>
                                {favIcon}
                                {this.renderBrandName(this.props.baseAccount, this.props.account)}
                            </Link>
                        </div>
                        <div className={`navbar-collapse collapse ${toggleNavBar ? 'in' : ''}`} aria-expanded={toggleNavBar.toString()}>
                            <ul className='nav navbar-nav main-nav'>
                                {liLinks}
                            </ul>

                            <div className='navbar-right'>
                                <ProfileMenu forceRemount={() => {self.forceHeaderRemount()}}/>
                            </div>
                        </div>
                    </div>
                    <div className="accountBreadcrumbs">
                        <AccountBreadcrumbs
                            key={this.state.profileMenuKey}
                            account={this.props.account}
                            accountHierarchy={this.props.accountHierarchy}
                        />
                    </div>
                </nav>
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        baseAccount: account.baseAccount,
        account: account.account,
        accountHierarchy: account.accountHierarchy
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getLinkUrl: (exLinkTypeName = '') => dispatch(actions.getLinkUrl(exLinkTypeName))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
