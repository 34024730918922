import React, { Component, useState } from 'react';
import ActionHeader from '../ActionHeader.js';
import Audit from './Audit';
import TabAdminUser from './TabAdminUser';
import TabAPIKey from './TabAPIKey';
import { canAccess } from '../CallMSUIHelpers';
import AccountSubscriptions from '../Accounts/AccountSubscriptions';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { connect } from 'react-redux';
 

const PortalAccount = (props) => {

    const [tabIndex, setTabIndex] = useState(0);

    const canViewAccSubs = () => {
        if (props.contractAccount.Id && props.account.Id) {
            if (props.account.Id === props.contractAccount.Id) {
                return canAccess('PlaceOrders', props.account.roles);
            }
        }
        return false;
    }

    const renderTabPanels = () => {
        let tabPanels = [
            <TabPanel key='adminusers'>
                <TabAdminUser />
            </TabPanel>
        ];

        if (canViewAccSubs()) {

            tabPanels.push(
                <TabPanel key='mysubs'>
                    <AccountSubscriptions
                        targetAccount={props.account}
                        closeButtonText={'Close'}
                        submitButtonText={'Save'}
                        limitSubscriptions={['BR','DET']}
                    />
                </TabPanel>
            );
        }

        tabPanels.push(
            <TabPanel key='audit'>
                <Audit />
            </TabPanel>
        );

        if (canAccess('AccountApiKeys', props.account.roles)) {
            tabPanels.push(
                <TabPanel key='apikeys'>
                    <TabAPIKey />
                </TabPanel>
            );
        }
        return tabPanels;
    }

    const renderTabHeaders = () => {
        let tabHeaders = [
            <Tab key='adminusers'><i className='fa-solid fa-users'></i> Administrative Users</Tab>
        ];
        if (canViewAccSubs()) {
            tabHeaders.push(<Tab key='mysubs'><span className='text-muted'><i className='fa-solid fa-list'></i> My Subscriptions</span></Tab>);
        }
        tabHeaders.push(<Tab key='audit'><span className='text-muted'><i className='fa-solid fa-clock-rotate-left'></i> Audit</span></Tab>);

        if (canAccess('AccountApiKeys', props.account.roles)) {
            tabHeaders.push(<Tab key='apikeys'><span className='text-muted'><i className='fa-solid fa-link'></i> Developers</span></Tab>);
        }

        return tabHeaders;
    }

    return (
        <div className='Portal'>
            <ActionHeader headerText={`Manage ${props.account.Name}`} />
            <hr />
            <Tabs selectedIndex={tabIndex} onSelect={tabIndex => setTabIndex(tabIndex)}>
                <TabList>
                    {renderTabHeaders()}
                </TabList>

                {renderTabPanels()}
            </Tabs>
        </div>
    );
    
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        contractAccount: account.contractAccount,
        account: account.account,
        baseAccount: account.baseAccount,
        baseAccounts: account.baseAccounts
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
      
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PortalAccount);
