import React, { Component } from 'react';
import BillingTable from './BillingTable';
import BillingAuditTable from './BillingAuditTable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getErrMsg, cmsGetChildAccountsPromise } from '../../CallMSAPI.js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0, 
            accountsLoaded: false,
            allAccounts: []
        };
        this.updateTab = this.updateTab.bind(this);
    }

    componentDidMount() {
        var self = this;

        cmsGetChildAccountsPromise(
            self.props.account.Id
        ).then(function (callmsData) {
            self.setState({
                allAccounts: callmsData.data.Results,
                accountsLoaded: true
            });
        }, function (err) {
            toast.error("Unable to load accounts: " + getErrMsg(err));
            self.setState({
                accountsLoaded: true,
            });
        })
    }

    /**
     * Update current tab, also try update URL with tab key (often service or
     * service variant code) for any future page reloads.
     * 
     * @param {any} index
     */
    updateTab(index) {
        var tab = this.getTabFromIndex(index);
        if (tab) {
            this.props.history.push("/accounts/" + this.props.account.Id + "/billing/" + tab.key);
        }

        this.setState({ tabIndex: index });
    }

    getTabFromIndex(index) {
        var all = this.getTabs();
        return all && all.Headers && all.Headers[index] ? all.Headers[index] : null;
    }

    getTabs() {
        var self = this;

        var tabHeaders = [];
        var tabPanels = [];

        tabHeaders.push(
            <Tab viewpriority={0} key={"billdownload"}>
                Billing Statements
            </Tab>);
        tabPanels.push(
            <TabPanel viewpriority={0} key={"billdownload"}>
                <BillingTable />
            </TabPanel>
        );

        tabHeaders.push(
            <Tab viewpriority={1} key={"orderhistory"}>
                Order History
            </Tab>
        );
        tabPanels.push(
            <TabPanel viewpriority={1} key={"orderhistory"}>
                {self.state.accountsLoaded
                    ? <BillingAuditTable allAccounts={self.state.allAccounts} />
                    : <p>Loading account data...</p>}
            </TabPanel>
        );

        return {
            Headers: tabHeaders,
            Panels: tabPanels,
        }
    }

    render() {
        var self = this;

        var data = self.getTabs();
        var tabHeaders = data.Headers;
        var tabPanels = data.Panels;

        return (
            <>
                <div className="Billing">
                    <h2>{this.props.contractAccount ? this.props.contractAccount.Name : ''} Billing</h2>
                    <hr />

                    <Tabs selectedIndex={this.state.tabIndex} onSelect={self.updateTab}>
                        <TabList>
                            {tabHeaders}
                        </TabList>

                        {tabPanels}
                    </Tabs>
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        contractAccount: account.contractAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Billing));