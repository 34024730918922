var _ = require('lodash');

const data = {
    "Id": '',
    "ExternalLinkTypeId": "",
    "ExternalLinkTypeName": "",
    "URL": "",
    "Text": "",
    "VisibleToAccount": [],
    "VisibleToChildren": [],
    "VisibleToGrandDescendents": [],
    "IsSystemOwnerOnly": false,
    "RequiresUrl": true
}

const types = {
    "Support": 1,
    "Store": 2,
    "Icon": 3,
    "Logo": 4,
    "FooterText": 7,
    "FooterTextSubbrand": 8,
    "FooterTextSecondary": 26,
    "FooterTextSubbrandSecondary": 27,
}

export const showImageExternalLinks = (links, systemOwnerRole, canAccessFooterLinks) => {
    var response = [];

    var storeLink = links === null || links.length === 0
        ? undefined
        : _.find(links, { ExternalLinkTypeName: 'store' });

        response.push(
            storeLink === undefined
                ? getNewExternalLinkData(
                    types.Store,
                    "store",
                    false)
                : getExisitingExternalLinkData(
                    storeLink,
                    false)
        );

    var supportLink = links === null || links.length === 0
        ? undefined
        : _.find(links, { ExternalLinkTypeName: 'support' });

        response.push(
            supportLink === undefined
                ? getNewExternalLinkData(
                    types.Support,
                    "support",
                    false)
                : getExisitingExternalLinkData(
                    supportLink,
                    false)
    );

    if (systemOwnerRole) {
        var iconLink = links === null || links.length === 0
            ? undefined
            : _.find(links, { ExternalLinkTypeName: 'favicon' });

        response.push(
            iconLink === undefined
                ? getNewExternalLinkData(
                    types.Icon,
                    "favicon",
                    true)
                : getExisitingExternalLinkData(
                    iconLink,
                    true)
        );

        var logoLink = links === null || links.length === 0
            ? undefined
            : _.find(links, { ExternalLinkTypeName: 'header-logo' });

        response.push(
            logoLink === undefined
                ? getNewExternalLinkData(
                    types.Logo,
                    "header-logo",
                    true)
                : getExisitingExternalLinkData(
                    logoLink,
                    true)
        );
    }

    if (canAccessFooterLinks || systemOwnerRole) {

        var footerTextLink = links === null || links.length === 0
            ? undefined
            : _.find(links, { ExternalLinkTypeName: 'footer-text' });

        response.push(
            footerTextLink === undefined
                ? getNewExternalLinkData(
                    types.FooterText,
                    "footer-text",
                    false,
                    true)
                : getExisitingExternalLinkData(
                    footerTextLink,
                    false,
                    true)
        );

        var footerTextLinkSecondary = links === null || links.length === 0 || links.filter(x => x.ExternalLinkTypeName == 'footer-text-secondary').length == 0
            ? undefined
            : _.find(links, { ExternalLinkTypeName: 'footer-text-secondary' });

        response.push(footerTextLinkSecondary == undefined ? getNewExternalLinkData(
            types.FooterTextSecondary,
            "footer-text-secondary",
            false,
            true)
            : getExisitingExternalLinkData(
                footerTextLinkSecondary,
                false,
                true));

        var footerTextSubbrandLink = links === null || links.length === 0
            ? undefined
            : _.find(links, { ExternalLinkTypeName: 'footer-text-subbrand' });

        response.push(
            footerTextSubbrandLink === undefined
                ? getNewExternalLinkData(
                    types.FooterTextSubbrand,
                    "footer-text-subbrand",
                    false,
                    true)
                : getExisitingExternalLinkData(
                    footerTextSubbrandLink,
                    false,
                    true)
        );

        var footerTextSubbrandSecondaryLink = links === null || links.length === 0
            ? undefined
            : _.find(links, { ExternalLinkTypeName: 'footer-text-subbrand-secondary' });

        response.push(
            footerTextSubbrandSecondaryLink === undefined
                ? getNewExternalLinkData(
                    types.FooterTextSubbrandSecondary,
                    "footer-text-subbrand-secondary",
                    false,
                    true)
                : getExisitingExternalLinkData(
                    footerTextSubbrandSecondaryLink,
                    false,
                    true)
        );
    }

    return response;
}

export const getPostExternalLink = (link) => {
    var response = _.omit(link, ['Id', 'IsSystemOwnerOnly', 'RequiresUrl']);

    response.VisibleToAccount = link.VisibleToAccount.length !== 0
        && response.VisibleToAccount[0].toLowerCase() === 'true'
            ? true
            : false;

    response.VisibleToChildren = link.VisibleToChildren.length !== 0
        && response.VisibleToChildren[0].toLowerCase() === 'true'
            ? true
            : false;

    response.VisibleToGrandDescendents = link.VisibleToGrandDescendents.length !== 0
        && response.VisibleToGrandDescendents[0].toLowerCase() === 'true'
            ? true
            : false;

    return response;
}

export const getNewExternalLinkData = (typeId, typeName, isSystemOwnerOnly, requiresUrl = true) => {
    var response = _.cloneDeep(data);

    response.ExternalLinkTypeId = typeId;
    response.ExternalLinkTypeName = typeName;
    response.IsSystemOwnerOnly = isSystemOwnerOnly;
    response.RequiresUrl = requiresUrl;

    return response;
};

const getExisitingExternalLinkData = (link, isSystemOwnerOnly, requiresUrl = true) => {

    link.IsSystemOwnerOnly = isSystemOwnerOnly;
    link.RequiresUrl = requiresUrl;

    link.VisibleToAccount = link.VisibleToAccount
        ? [link.VisibleToAccount.toString()]
        : [];

    link.VisibleToChildren = link.VisibleToChildren
        ? [link.VisibleToChildren.toString()]
        : [];

    link.VisibleToGrandDescendents = link.VisibleToGrandDescendents
        ? [link.VisibleToGrandDescendents.toString()]
        : [];

    return link;
};
