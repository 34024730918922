import { cmsGetServices, cmsGetServicesPromise, cmsGetAccountServicePromise } from '../../CallMSAPI';
import * as actionTypes from './actionTypes';
import _ from 'lodash';

const start = () => {
    return {
        type: actionTypes.SERVICES_START
    };
};

const success = (resp) => {
    return {
        type: actionTypes.SERVICES_SUCCESS,
        services: resp
    };
};

const fail = (err) => {
    return {
        type: actionTypes.SERVICES_FAIL,
        err
    };
};

const callBack = () => { };

export const refetchServices = (resCallBack = callBack) => {
    return (dispatch, getState) => getServicesLogic(getState, res => {
        dispatch(success(res))
        resCallBack(res);
    });
}

export const getServices = () => {
    return (dispatch, getState) => {
        dispatch(start());

        getServicesLogic(getState, res => dispatch(success(res)), err => dispatch(fail(err)));
    }

};

const getServicesLogic = (getState, res = callBack, error = callBack) => {

    const account = getState().account.account;

    cmsGetServices(account.Id, (resp) => {

        let services = resp.Results;

        let servicesState = {
            list: [],
            rawList: [],
            fullTeams: [],
            totalList: account.AvailableServices
                ? account.AvailableServices.map(s => s.Id)
                : [],
            trueTransferSupported: false,
            canSelectTrueTransfer: false
        };

        services.forEach(function (s) {
            if (!servicesState.list[s.ServiceId]) {
                servicesState.list[s.ServiceId] = [];
            }
            servicesState.list[s.ServiceId].push(s);
            servicesState.rawList.push(s);

            if (servicesState.totalList.indexOf(s.ServiceId) === -1) {
                servicesState.totalList.push(s.ServiceId);
            }

        });


        //Check for Teams True Transfer support.
        //Uses 'typical' case of one Teams service that has a name of 'teams'

        let existingTeamsService = services.filter(x => x.Name === 'Teams');
        if (existingTeamsService.length > 0) {
            if (existingTeamsService[0].ServiceVariantCode === 'TEAMSAUDIOCODES') {
                servicesState.trueTransferSupported = true;
            }
        }

        servicesState.totalList.forEach(function (sId) {
            var availableService = _.find(account.AvailableServices, function (el) { return el.Id === sId });
            if (availableService) {
                if (availableService.Code === 'teams') {
                    var is2ndGenAvail = availableService.Variants.filter(x => x.Code === 'TEAMSAUDIOCODES');
                    if (is2ndGenAvail.length > 0) {
                        servicesState.canSelectTrueTransfer = true;
                    }
                }
            }
        });
        let servicePromises = [];
        servicesState.rawList.filter(x => x.SyncEnabled && x.ServiceCode === 'teams').forEach((service) => {
            servicePromises.push(cmsGetAccountServicePromise(account.Id, service.Id))
        });
        Promise.allSettled(servicePromises).then(res => {
            _.forEach(res, (s) => {
                if(s && s.value && s.value.data){
                    servicesState.fullTeams.push(s.value.data);
                }
            })
        })

        res(servicesState);

    }, (err) => {
        error(err);
    });
}