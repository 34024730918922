import { instanceOf } from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { CallLog } from '../../models/CallLog';

const CallFlowPacket = (props) => {
    const [tabIndex, setTabIndex] = useState(0);

    const renderMessage = (log = new CallLog()) => {
        return (
            <pre className='large-debug-text'>
                {log.content}
            </pre>
        );
    }

    const renderDetails = () => {

    }

    return (
        <Tabs
            selectedIndex={tabIndex}
            onSelect={(i) => setTabIndex(i)}
        >
            <TabList>
                <Tab key='message'>
                    Message
                </Tab>
                <Tab key='details'>
                    Details
                </Tab>
            </TabList>
            <TabPanel key='message'>
                {renderMessage(props.callLog)}
            </TabPanel>
            <TabPanel key='details'>
                {renderDetails}
            </TabPanel>
        </Tabs>
    );
}
CallFlowPacket.defaultProps = {
    callLog: new CallLog()
}
CallFlowPacket.propTypes = {
    callLog: instanceOf(CallLog).isRequired
}
export default CallFlowPacket;