// Based on sample authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { extractIdToken } from './utils/ExtractIdToken.js';
import { clearLastAzureAccountLocalStorage, clearLocalStorage, cmsGetSyncModuleDefaultSettings, cmsGetPrivateAccountAppSettings } from './CallMSAPI';
import { Logger, LogLevel } from "msal";
import { openPopupWindow } from './ExternalAuthProvider.js';
import LocalStorageHelper from './utils/LocalStorageHelper.js';
import queryString from 'query-string';
import { showConsoleLogs } from './CallMSUIHelpers.js';


let _system = {};
/*
 * Show extra MSAL logging when running in a local environment
 
if (window.location.hostname === 'localhost') {
    _system = {
        logger: new Logger(
            (logLevel, message, containsPii) => {
                console.log("[MSAL]", message);
            },
            {
                level: LogLevel.Verbose,
                piiLoggingEnabled: false
            }
        )

    };
}
*/

let _msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: null,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
    app: {
        allowDeleteAccount: false,
        appInsightsInstrumentationKey: null,
        instance: 'https://login.microsoftonline.com/common',
        tenant: 'common'
    },
    system: _system
};

let _globalAuthContext = null;

export function getMsalConfig() {
    return _msalConfig;
}

export function setMsalConfig(data) {
    _msalConfig.auth.clientId = data.clientId;
    _msalConfig.app.instance = data.instance;
    _msalConfig.app.tenant = data.tenant;
    _msalConfig.auth.authority = _msalConfig.app.instance + _msalConfig.app.tenant
    _msalConfig.app.allowDeleteAccount = parseInt(data.allowDeleteAccount);
    _msalConfig.app.appInsightsInstrumentationKey = data.appInsightsInstrumentationKey;

}

export function getMsalAuthContext(loginHint = null) {
    if (_globalAuthContext === null) {
        let tempauthenticationParameters = {
            scopes: [
                _msalConfig.auth.clientId + '/.default'
            ],
        };

        // Sometimes we want to try and log in with a specific user account
        if (loginHint !== null && loginHint) {
            tempauthenticationParameters['loginHint'] = loginHint;
        }

        _globalAuthContext = new MsalAuthProvider(
            _msalConfig,
            tempauthenticationParameters,
            {
                loginType: LoginType.Redirect,
                // A blank html page that MSAL can load in an iframe to refresh the token.
                // Using these avoids multiple loads of the main app
                tokenRefreshUri: window.location.origin + '/authDummy.html',
            }
        );
    }
    return _globalAuthContext;
}

/**
 * Misc helpers
 */
export function getUserName() {
    var authProvider = getMsalAuthContext();
    var info = authProvider.getAccountInfo();
    if (info) {
        return authProvider.getAccountInfo().account.userName;
    }
    return null;
}

export function getUserDisplayName() {
    var authProvider = getMsalAuthContext();
    var info = authProvider.getAccountInfo();
    if (info) {
        return authProvider.getAccountInfo().account.name;
    }
    return null;
}

export function getUserFullName() {
    var authProvider = getMsalAuthContext();
    var info = authProvider.getAccountInfo();
    if (info) {
        return authProvider.getAccountInfo().account.name;
    }
    return null;
}

export function getIdToken() {
    var authProvider = getMsalAuthContext();
    var info = authProvider.getAccountInfo();
    if (info) {
        return authProvider.getAccountInfo().account.idToken;
    }
    return null;
}

export function triggerLogout() {
    // Clear the last account cookie to allow a full log-out experience
    clearLastAzureAccountLocalStorage();
    const arr = ['cms_'];

    clearLocalStorage(arr, x => {
        let item = LocalStorageHelper.decode(x.value);
        //handling cms_ to keep uistate.
        if (x.key.startsWith('cms_')) {
            item.value = {
                ...item.value,
                tid: '',
                iss: '',
                baseAccId: ''
            };
        }

        LocalStorageHelper.setWithExpiry(x.key, item.expiry, item.value);
    });

    const authProvider = getMsalAuthContext();
    authProvider.logout();
}

export function triggerSwitchAccounts() {
    // Clear the last account cookie to ensure switch process kicks in smoothly
    clearLastAzureAccountLocalStorage();

    var authProvider = getMsalAuthContext();

    // loginHint that's blank is important for account selector to show
    var requestObj = {
        scopes: [_msalConfig.auth.clientId + '/.default'],
        prompt: "select_account",
        loginHint: ""
    };

    // Don't do anything with promise as this will reload the app
    authProvider.loginRedirect(requestObj);
}

/**
 * CMS API fetch wrappers
 */
function msalFetch(authProvider, fetch, url, options, data = null) {
    var tt = authProvider.getAccessToken().then(
        function (tokenObj) {
            var token = tokenObj.accessToken;
            var o = options || {};
            if (!o.headers) o.headers = {};
            o.headers.Authorization = "Bearer ".concat(token);
            o.headers["Dstny-Client"] = "Portal";
            if (data === null) {
                return fetch(url, o);
            } else {
                return fetch(url, data, o);
            }
        }
    );

    return tt;
} // eslint-disable-next-line


export function msalApiFetch(fetch, url, options, data = null) {
    return msalFetch(getMsalAuthContext(), fetch, url, options, data);
}

/**
 * Sync specific helpers
 */

// CMS

export function getSyncIdToken(domainHint, forceSelect, popupWindow, tenantId) {
    return new Promise(function (resolve, reject) {
        var redirectUri = window.location.origin + '/authDummy.html';
        var redirectUriComp = encodeURIComponent(redirectUri);
        var conf = getMsalConfig();
        var tenId = tenantId ? tenantId : 'common';

        // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
        var nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        var url = "https://login.microsoftonline.com/" + tenId + "/oauth2/v2.0/authorize?"
            + "client_id=" + conf.auth.clientId
            + "&response_type=id_token"
            + "&redirect_uri=" + redirectUriComp
            + "&nonce=" + nonce
            + "&scope=openid%20profile";

        if (forceSelect || !domainHint) {
            url += "&prompt=select_account";
        } else {
            url += "&domain_hint=" + encodeURIComponent(domainHint);
        }

        // Once returned, check tenant ID matches expected tenant ID.
        // Code: tenant id in URL and login hint is the user.

        if (!popupWindow) {
            popupWindow = openPopupWindow(url, "Portal Auth");
        } else {
            popupWindow.location.href = url;
            //externalPopup = true;
        }

        let pollTimer = window.setInterval(function () {
            if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                window.clearInterval(pollTimer);
                reject("Auth window closed.");
            } else {
                try {
                    var loc = popupWindow.location;
                    if (encodeURI(loc.href).indexOf(encodeURI(redirectUri)) !== -1) {
                        var token = loc.hash;
                        // TODO: Check nonce matches
                        var temp = extractIdToken(token);
                        window.clearInterval(pollTimer);

                        temp['popup'] = popupWindow;
                        resolve(temp);
                        popupWindow.location.href = url;
                    }
                } catch (e) {
                    // whilst not our origin it'll error, just ignore until it's back to our origin
                }
            }
        }, 100);
    })
}

export function getScopedSyncTokens(numberRequired, tenant, prefDomain, popup, SyncClientId, extraScopes) {
    // build URL pass it in
    var conf = getMsalConfig();
    if (!tenant) {
        throw new Error("No tenant ID supplied for sync");
    }

    if (!prefDomain) {
        throw new Error("No user address supplied for sync");
    }

    var scopes = [
        "48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation",
        "https://graph.windows.net/Directory.AccessAsUser.All"
    ];

    if (extraScopes && extraScopes.length) {
        scopes = scopes.concat(extraScopes);
    }

    var redirectUri = window.location.origin + '/authDummy.html';
    var redirectUriComp = encodeURIComponent(redirectUri);

    var appId = SyncClientId ? SyncClientId : conf.auth.clientId;

    // Note, v2, as we want to supply our own scope
    var url = "https://login.microsoftonline.com/" + tenant + "/oauth2/v2.0/authorize?"
        + "client_id=" + appId
        + "&response_type=code"
        + "&redirect_uri=" + redirectUriComp
        + "&login_hint=" + encodeURIComponent(prefDomain)
        + "&scope=" + scopes.join("%20");

    return internalGetSyncTokens(url, appId, redirectUri, numberRequired, popup, SyncClientId);
}

export function getSyncTokens(numberRequired, tenant, prefDomain, popup, SyncClientId) {
    // build URL, pass it in
    var conf = getMsalConfig();
    if (!tenant) {
        throw new Error("No tenant ID supplied for sync");
    }

    if (!prefDomain) {
        throw new Error("No user address supplied for sync");
    }

    var redirectUri = window.location.origin + '/authDummy.html';
    var redirectUriComp = encodeURIComponent(redirectUri);

    var appId = SyncClientId ? SyncClientId : conf.auth.clientId;

    // Note, v1, not v2, as we don't want to appear to request offline access
    // Also scope is set on application, so we can use v1 and not supply here.
    var url = "https://login.microsoftonline.com/" + tenant + "/oauth2/authorize?"
        + "client_id=" + appId
        + "&response_type=code"
        + "&redirect_uri=" + redirectUriComp
        + "&login_hint=" + encodeURIComponent(prefDomain);

    return internalGetSyncTokens(url, appId, redirectUri, numberRequired, popup, SyncClientId);
}

function internalGetSyncTokens(url, appId, redirectUri, numberRequired, popup, SyncClientId) {
    return new Promise(function (resolve, reject) {
        var externalPopup = false;
        let popupWindow = popup;
        if (!popupWindow) {
            popupWindow = openPopupWindow(url, "Portal Auth");
        } else {
            popupWindow.location.href = url;
            externalPopup = true;
        }

        var output = [];
        var pos = 0;
        let pollTimer = window.setInterval(function () {
            if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                window.clearInterval(pollTimer);
                reject("Auth window closed.");

            } else {
                try {
                    var loc = popupWindow.location;
                    if (encodeURI(loc.href).indexOf(encodeURI(redirectUri)) !== -1) {
                        var temp = queryString.parse(loc.search);
                        temp['request_url'] = url;
                        temp['client_id'] = appId;
                        temp['redirect_uri'] = redirectUri;

                        if (!temp.code
                            || (pos > 0 && numberRequired > 1
                                && temp.code && (temp.code === output[pos - 1].code)
                            )
                        ) {
                            // console.log("Still on previous code value")
                            return;
                        } else {
                            output.push(temp);
                            pos++;
                        }

                        if (pos < numberRequired) {
                            // Let's go round again!
                            popupWindow.location.href = url;
                        } else {
                            window.clearInterval(pollTimer);
                            if (!externalPopup) {
                                if(showConsoleLogs()){
                                    console.log("closing non-external MS popup window");
                                }
                                popupWindow.close();
                            }
                            if(showConsoleLogs()){
                                console.log("Final output");
                                console.log(output);
                            }
                            resolve(output);
                            popupWindow.location.href = url;
                        }
                    } else {
                        // console.log("Not found yet... " + loc);
                    }
                } catch (e) {
                    if(showConsoleLogs()){
                        console.log("Not MSAL root: " + e.message);
                    }
                    // whilst not our origin it'll error, just ignore until it's back to our origin
                }
            }
        }, 100);
    })
}
export function getConsentForAutoSync(accId, popup) {
    return new Promise(function (resolve, reject) {
        var redirectUri = window.location.origin + '/authDummy.html';
        var redirectUriComp = encodeURIComponent(redirectUri);

        cmsGetPrivateAccountAppSettings(accId).then(function (res) {

            var appId = res.data.AutoSyncApplicationId;
            var tenantId = res.data.TenantId;
            if (!tenantId) {
                tenantId = 'common';
            }
            var url = "https://login.microsoftonline.com/" + tenantId + "/adminconsent?"
                + "client_id=" + appId
                + "&redirect_uri=" + redirectUriComp;
            var externalPopup = false;
            var popupWindow = null;
            if (popup) {
                externalPopup = true;
                popupWindow = popup;
                popupWindow.location.href = url;
            } else {
                popupWindow = openPopupWindow(url, "Portal Auth");
            }

            let pollTimer = window.setInterval(function () {
                if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                    window.clearInterval(pollTimer);
                    reject("Auth window closed.");
                } else {
                    try {
                        var loc = popupWindow.location;
                        if (encodeURI(loc.href).indexOf(encodeURI(redirectUri)) !== -1) {
                            if (loc.search) {
                                var temp = queryString.parse(loc.search);
                                var tenant = temp.tenant;
                                
                                if (temp && temp.error && temp.error == 'access_denied') {
                                    window.clearInterval(pollTimer);
                                    popupWindow.close();
                                    reject(false);
                                }
                                if (temp && temp.admin_consent && temp.admin_consent == "True") {
                                    if (!externalPopup) {
                                        if(showConsoleLogs()){
                                            console.log("closing non-external MS popup window");
                                        }
                                        popupWindow.close();
                                    }
                                    window.clearInterval(pollTimer);
                                    resolve(tenant);
                                }
                            }
                        }
                    } catch (e) {

                    }
                }
            }, 100);

        }).catch(function (err) {
            reject(err.message);
        });
    })
}
export function getConsentForAdvancedCalling(accId, popup) {
    return new Promise(function (resolve, reject) {
        var redirectUri = window.location.origin + '/authDummy.html';
        var redirectUriComp = encodeURIComponent(redirectUri);

        cmsGetSyncModuleDefaultSettings(accId).then(function (res) {

            var appId = res.data.find(x => x.Key === "CEBotClientId").Value;
            var tenantId = res.data.find(x => x.Key === "TenantId").Value;
            if (tenantId === null) {
                tenantId = 'common';
            }
            var url = "https://login.microsoftonline.com/" + tenantId + "/adminconsent?"
                + "client_id=" + appId
                + "&redirect_uri=" + redirectUriComp;
            var externalPopup = false;
            var popupWindow = null;
            if (popup) {
                externalPopup = true;
                popupWindow = popup;
                popupWindow.location.href = url;
            } else {
                popupWindow = openPopupWindow(url, "Portal Auth");
            }

            let pollTimer = window.setInterval(function () {
                if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                    window.clearInterval(pollTimer);
                    reject("Auth window closed.");
                } else {
                    try {
                        var loc = popupWindow.location;
                        if (encodeURI(loc.href).indexOf(encodeURI(redirectUri)) !== -1) {
                            if (loc.search) {
                                var temp = queryString.parse(loc.search);
                                if (temp && temp.error && temp.error == 'access_denied') {
                                    window.clearInterval(pollTimer);
                                    popupWindow.close();
                                    reject(false);
                                }
                                if (temp && temp.admin_consent && temp.admin_consent == "True") {
                                    if (!externalPopup) {
                                        if(showConsoleLogs()){
                                            console.log("closing non-external MS popup window");
                                        }
                                        popupWindow.close();
                                    }
                                    window.clearInterval(pollTimer);
                                    resolve(true);
                                }
                            }
                        }
                    } catch (e) {

                    }
                }
            }, 100);

        }).catch(function (err) {
            reject(err.message);
        });
    })
}
export function getConsentForPresence(appId, tenantId, popup) {
    return new Promise(function (resolve, reject) {
        var redirectUri = window.location.origin + '/authDummy.html';
        var redirectUriComp = encodeURIComponent(redirectUri);

        if (tenantId === null) {
            tenantId = 'common';
        }
        var ticksAsUniqueId = new Date().getTime();
        var url = "https://login.microsoftonline.com/" + tenantId + "/oauth2/authorize?"
            + "client_id=" + appId
            + "&response_type=code"
            + "&state=" + ticksAsUniqueId
            + "&redirect_uri=" + redirectUriComp
        var externalPopup = false;
        var popupWindow = null;
        if (popup) {
            externalPopup = true;
            popupWindow = popup;
            popupWindow.location.href = url;
        } else {
            popupWindow = openPopupWindow(url, "Portal Auth");
        }

        let pollTimer = window.setInterval(function () {
            if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                window.clearInterval(pollTimer);
                reject("Auth window closed.");
            } else {
                try {
                    var loc = popupWindow.location;
                    if (encodeURI(loc.href).indexOf(encodeURI(redirectUri)) !== -1 &&
                        encodeURI(loc.href).indexOf(ticksAsUniqueId) !== -1) {
                        if (loc.search) {
                            var output = queryString.parse(loc.search);
                            if (output && output.code) {
                                if (!externalPopup) {
                                    if(showConsoleLogs()){
                                        console.log("closing non-external MS popup window");
                                    }
                                    popupWindow.close();
                                }
                                window.clearInterval(pollTimer);
                                resolve(output);
                            }
                        }
                    }
                } catch (e) {

                }
            }
        }, 100);
    })
}
export function getConsentForPhoneApp(appId, popup) {
    return new Promise(function (resolve, reject) {
        var redirectUri = window.location.origin + '/authDummy.html';
        var redirectUriComp = encodeURIComponent(redirectUri);

        var url = "https://login.microsoftonline.com/common/adminconsent?"
            + "client_id=" + appId
            + "&redirect_uri=" + redirectUriComp

        var externalPopup = false;
        var popupWindow = null;
        if (popup) {
            externalPopup = true;
            popupWindow = popup;
            popupWindow.location.href = url;
        } else {
            popupWindow = openPopupWindow(url, "Portal Auth");
        }

        let pollTimer = window.setInterval(function () {
            if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                window.clearInterval(pollTimer);
                reject("Auth window closed.");
            } else {
                try {
                    var loc = popupWindow.location;
                    if (loc.href.indexOf('AADSTS65004') !== -1) {
                        if (!externalPopup)
                            popupWindow.close();
                        window.clearInterval(pollTimer);
                        reject("User declined consent");
                    } else if (encodeURI(loc.href).indexOf(encodeURI(redirectUri)) !== -1) {
                        if (!externalPopup)
                            popupWindow.close();
                        window.clearInterval(pollTimer);
                        resolve(true);
                    }
                } catch (e) {

                }
            }
        }, 100);
    })
}