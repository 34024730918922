import React from 'react';
import { Switch, Route } from 'react-router-dom'

import AppPortal from './AppPortal';
import GettingStarted from './GettingStarted';
import CallLogTools from './components/CallLogTools';
import CallLogList from './components/CallLogList';
import AllocationLogList from './components/AllocationLogList';
import NoMatch from './NoMatch';
import PortalAccount from './AccountAdministration/PortalAccount';
import Services from './Services/Services';
import SyncJobs from './Services/SyncJobs';
import Users from './ServiceUsers/Users';
import ServiceUserRegStatus from './ServiceUsers/ServiceUserRegStatus.js';
import PreReqHistoryPage from './Services/PreReqHistory';
import SyncContributor from './Services/SyncContributor';
import { LoadingSplash } from './CallMSUIHelpers';
import { connect } from 'react-redux';
import SystemOwnerCallSingle from './components/call/SystemOwnerCallSingle';
import CallSingleWrapper from './ServiceUsers/CallSingleWrapper';
import Msgraph from './MSGraphAPI.js';

const Portal = (props) => {
    if (!props.loaded) {
        return <LoadingSplash text='Loading Account...' /> 
    }

    return (
        <AppPortal>
            <Switch>
                <Route path='/portal/:accountId/' exact component={PortalAccount} />
                <Route path="/portal/:accountId/prereqhistory" exact component={PreReqHistoryPage} />
                <Route path="/portal/:accountId/prereqtesting" exact component={Msgraph} />
                <Route path='/portal/:accountId/users/:serviceUserPartId/services/:serviceName/status' component={ServiceUserRegStatus} />
                <Route path='/portal/:accountId/services/:serviceName/status' component={ServiceUserRegStatus} />
                <Route path='/portal/:accountId/services/:serviceName/allocation-logs' component={AllocationLogList} />
                <Route path='/portal/:accountId/users' component={Users} />
                <Route exact path='/portal/:accountId/calls/:callId' component={CallSingleWrapper} />
                <Route exact path='/portal/:accountId/calls/system_owner/:callId' component={SystemOwnerCallSingle} />
                <Route path='/portal/:accountId/services/jobs' component={SyncJobs} />
                <Route path='/portal/:accountId/services/:serviceName?/:accountServiceId?' component={Services} />
                <Route path='/portal/:accountId/gettingstarted' component={GettingStarted} />
                <Route path='/portal/:accountId/tools' component={CallLogTools} />
                <Route path='/portal/:accountId/call-logs' component={CallLogList} />
                <Route path='/portal/:accountId/sync' component={SyncContributor} />
                <Route component={NoMatch} />
            </Switch>
        </AppPortal>
    );
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        loaded: account.loaded
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Portal);
