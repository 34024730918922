export default class LocalStorageHelper
{
    static #predicate = (_value = new LocalStorageItem(), _index = 0, _array = []) => { };

    static decode = (str = '') => {
        try {
            return JSON.parse(decodeURIComponent(str));
        } catch {
            try {
                return JSON.parse(str);
            } catch  {
                return str;
            }
        }
    }

    /**
     * Set local storage with expiration
     * @param {string} key identifier by key to value
     * @param {number} maxAge seconds till local storage expires from now.
     * @param {any} value value set with key
     */
    static setWithExpiry = (key = '', maxAge = (60 * 60), value = {}) => {
        const now = new Date();
        const item = {
            value,
            expiry: (now.getTime() + (maxAge * 1000))
        };
        window.localStorage.setItem(key, encodeURIComponent(JSON.stringify(item)));
    }

    /**
    * Get local storage with expiration
    * @param {string} key identifier by key to value
    */
    static getWithExpiry = (key = '') => {
        const str = window.localStorage.getItem(key); 

        if (!str) {
            return null;
        }
        const now = new Date();
        const item = this.decode(str);

        if (now.getTime() > item.expiry) {
            window.localStorage.removeItem(key);
            return null;
        }
        return item.value;
    }

 
    static removeItem = (key = '') => window.localStorage.removeItem(key);

    /**
    * Clear local storage
    * @param {string} key identifier by key to value
    */
    static clear = () => window.localStorage.clear();

    /**
     * Check if an item exists via key
     * @param {string} key identifier by key to value
     */
    static exists = (key = '') => {
        const item = this.getWithExpiry(key);
        if (!item) {
            return false;
        }
        return true;
    }

    static filter = (x = this.#predicate) => {
        let arr = [new LocalStorageItem()];
        arr.splice(0, 1);

        for (var i = 0, len = window.localStorage.length; i < len; ++i) {
            const key = window.localStorage.key(i);

            arr.push({
                value: window.localStorage.getItem(key),
                key
            });
        }

        return arr.filter(x);
    }
}
class LocalStorageItem {

    expiry = 0;
    value = {};
    key = '';
}