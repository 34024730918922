import React from 'react';
import { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { reallocateServiceSbcs, toastErrHandler } from '../../CallMSAPI';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { toast } from 'react-toastify';


const SbcReallocateDropDown = (props) => {

    const [state, setState] = useState({
        selectedOpt: null,
        overrideSbcs: [],
        selectedOverrideSbcs: [],
        toggle: false
    });

    const options = [
        {
            label: 'Keep All',
            value: '1'
        },
        {
            label: 'Keep Ineligible & Unavailable',
            value: '2'
        },
        {
            label: 'Keep Unavailable',
            value: '3'
        },
        {
            label: 'Allow Deallocate',
            value: '4'
        },
        {
            label: 'Force Deallocate',
            value: '5'
        },
        {
            label: 'No Change',
            value: '6'
        }
    ];

    const reallocateOnChange = (opt) => {
        setState({ ...state, selectedOpt: opt });
    }

    const overrideSbcOnChange = (_val, action) => {
        let overrideSbcs = [...state.overrideSbcs];
        let selectedOverrideSbcs = [...state.selectedOverrideSbcs];
        switch (action.action) {
            case 'create-option':
                overrideSbcs.push(action.option);
                selectedOverrideSbcs.push(action.option);
                break;
            case 'select-option':
                selectedOverrideSbcs.push(action.option);
                break;
            case 'remove-value':
                const index = selectedOverrideSbcs.findIndex(x => x.value === action.removedValue.value);
                selectedOverrideSbcs.splice(index, 1);
                break;
            default:
        }

        setState({ ...state, overrideSbcs, selectedOverrideSbcs });
    }

    const reallocateHandler = () => {
        const overrideSbcs = state.selectedOverrideSbcs.map(x => x.value);
        return reallocateServiceSbcs(
            props.account.Id,
            props.serviceId, state.selectedOpt.value,
            overrideSbcs
        );
    }

    const onDialogYesBtn = () => {
        toast.info(`Starting Reallocation: ${state.selectedOpt.label}`);
        toggleDialog();
        reallocateHandler().then(() => {
            toast.success(`Reallocation Successful: ${state.selectedOpt.label}`);
            props.onSubmit(state.value);
        }).catch(err => toastErrHandler(err));
    }

    const toggleDialog = () => setState({ ...state, toggle: !state.toggle });
    var base = "/portal/" + props.account.Id;
    var pathName = "/services/" + props.serviceId + "/allocation-logs";

    return (
        <div className='alert system-owner-container'>
            <div className='user-form-parts-wrapper mb-1'>
                <div className='form-group mb-1'>
                    <label htmlFor='SbcReallocateDdl'>
                        Reallocate SBCs <i className='fa-solid fa-user-secret' title='System Owner Only' />
                    </label>
                    <Select
                        value={state.selectedOpt}
                        options={options}
                        className='select-dropdown'
                        id='SbcReallocateDdl'
                        name='SbcReallocateDropDown'
                        onChange={reallocateOnChange}
                        isSearchable={false}
                        isDisabled={props.IsUIDisabled}
                    />
                </div>
                <div className='form-group mb-1'>
                    <div className='row'>
                        <div className='col-md-9'>
                            <label htmlFor='SbcReallocateDdl'>
                                Override SBCs
                            </label>
                            <CreatableSelect
                                options={state.overrideSbcs}
                                value={state.selectedOverrideSbcs}
                                isClearable
                                isMulti
                                isDisabled={!state.selectedOpt}
                                className='select-dropdown'
                                id='OverrideSbcDdl'
                                name='OverrideSbcDropDown'
                                onChange={overrideSbcOnChange}
                                disabled={props.IsUIDisabled}
                            />
                        </div>
                        <div className='col-md-3'>
                            <PrimaryButton
                                type='button'
                                className='float-right mt-5'
                                disabled={!state.selectedOpt || props.IsUIDisabled}
                                onClick={toggleDialog}
                            >
                                Reallocate
                            </PrimaryButton>
                        </div>
                    </div>


                </div>
                <div className='user-form-parts-wrapper mb-1'>
                    <Link className="ext_link" to={base + pathName} target="_blank">
                        Click here to view allocation logs
                    </Link>
                </div>
            </div>
            <Dialog
                hidden={!state.toggle}
                onDismiss={toggleDialog}
                isBlocking={true}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Are you sure?',
                    subText: 'If the customer has whitelisted the IPs this will cause an immediate outage. Do not do this unless you are sure there are no IP filters in place. For Teams an immediate sync will be required which will also cause an outage. Do you want to proceed?',
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={onDialogYesBtn} text='Yes' />
                    <DefaultButton onClick={toggleDialog} text='No' />
                </DialogFooter>
            </Dialog>
        </div>
    );
}
export default withRouter(connect(state => ({
    account: state.account.account,
}))(SbcReallocateDropDown));