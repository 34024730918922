import React, { Component } from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { connect } from 'react-redux';

class PlaceOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reference: null,
        }
    }

    render() {
        var self = this;

        var supplierName = self.props.contractAccountInfo && self.props.contractAccountInfo.ContractOwnerBrand ? self.props.contractAccountInfo.ContractOwnerBrand : 'Supplier';
        var supplierLink = self.props.contractAccountInfo && self.props.contractAccountInfo.ContractOwnerTermsURL ? self.props.contractAccountInfo.ContractOwnerTermsURL : null;
        
        var totalStrings = this.props.totalStrings;
        if (!totalStrings) {
            var currencies = [];
            var newTotals = [];

            if (this.props.subs) {
                this.props.subs.forEach(function (s) {
                    if (s) {
                        var q = (s.hasOwnProperty('NewQuantity') ? s.NewQuantity : s.Quantity);
                        var total = (q * s.UnitPrice);

                        if (newTotals.hasOwnProperty(s.CurrencyCode)) {
                            newTotals[s.CurrencyCode] += total;
                        } else {
                            newTotals[s.CurrencyCode] = total;
                            currencies.push(s.CurrencyCode);
                        }
                    }
                });

            } else if (this.props.formValues && this.props.offers) {
                this.props.offers.forEach(function (o) {
                    if (o) {
                        var q = 0;
                        if (self.props.formValues['quantity-' + o.Id]) {
                            q = parseInt(self.props.formValues['quantity-' + o.Id]);
                        }
                        var total = (q * o.UnitPrice);

                        if (newTotals.hasOwnProperty(o.CurrencyCode)) {
                            newTotals[o.CurrencyCode] += total;
                        } else {
                            newTotals[o.CurrencyCode] = total;
                            currencies.push(o.CurrencyCode);
                        }
                    }
                });
            }

            totalStrings = [];
            currencies.forEach(function (c) {
                totalStrings.push(<li key={c}>{c} {newTotals[c].toFixed(2)}</li>);
            });
        }

        return (
            <Dialog
                hidden={!this.props.isOpen}
                onDismiss={() => this.props.closeCallback()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Place Order',
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}
            >
                <p>You are about to place an order as {this.props.contractAccount.Name} with {supplierName}.
                    The total cost will be:</p>
                <ul>{totalStrings}</ul>
                {supplierLink ?
                    <p>By continuing you agree to the <a data-testid='supplierAnchor' href={supplierLink} rel="noopener noreferrer" target="_blank"> {supplierName} Terms and Conditions.</a></p>
                    : null }

                <p>Set an optional reference for your order:</p>
                <input className="form-control" type="text" defaultValue={self.state.reference}
                    onChange={(e) => {
                        self.setState({ reference: e.currentTarget.value })
                    }}
                />

                <DialogFooter>
                    <DefaultButton onClick={() => this.props.closeCallback()} text="Cancel" />
                    <PrimaryButton onClick={() => this.props.orderCallback(self.state.reference)} text="Place Order" disabled={this.props.disableOrderButton} />
                </DialogFooter>
            </Dialog>
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        contractAccount: account.contractAccount,
        contractAccountInfo: account.contractAccount.info
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrder);
