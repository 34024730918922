import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BrandingTemplatesReactTable from './BrandingTemplatesReactTable';
import { withRouter } from 'react-router-dom';
import BrandingLogoForm from './BrandingLogoForm';
import BrandingImageForm from './BrandingImageForm';
import BrandingExternalLinks from './BrandingExternalLinks';
import { isSystemOwner, isSystemAccount, canAccessFeature } from '../../CallMSUIHelpers.js';
import PhoneAppBranding from './PhoneAppBranding';
import { getErrMsg, cmsPutPromise } from '../../CallMSAPI.js';
import { toast } from 'react-toastify';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

var _ = require('lodash');

class BrandingTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0,
            lastAccountRefreshTime: null,
            showAncestorPBXTemplatesHelp: false,
        };

        this.updateTab = this.updateTab.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
        this.dismissHelp = this.dismissHelp.bind(this);
    }

    goToAddTemplates = (accountId, serviceVariantCode) => {
        this.props.history.push(`/accounts/${accountId}/branding/templates/add/${serviceVariantCode}`);
    }

    setHideAncestorPBXTemplates = (props) => {
        var self = this;
        cmsPutPromise({
            accountId: props.account.Id,
            objectId: !props.account.HideAncestorPBXTemplates,
            objectType: "hideAncestorPBXTemplates",
        }).then(
            function () {
                props.refreshAccount(() => {
                    self.setState({
                        lastAccountRefreshTime: Date.now()
                    });
                });
            }, function (error) {
                toast.error(getErrMsg(error));
            }
        )
    }

    toggleHelp = (name) => {
        this.setState({ [name]: !this.state[name] });
    }

    dismissHelp = name => {
        this.setState({ [name]: false });
    }

    getTabs = () => {
        var self = this;
        var tabHeaders = [];
        var tabPanels = [];

        var isSystemOwnerRole = isSystemOwner(self.props.baseAccount.roles);
        var isSystemAccountLocation = isSystemAccount(self.props.account);

        var hasPBXTemplatesBrandingFeature =
            canAccessFeature(
                "PBXTEMPLATES",
                "Brand Contributor",
                self.props.account.roles,
                self.props.account);

        if (   hasPBXTemplatesBrandingFeature
            || isSystemOwnerRole) {
            tabHeaders.push(
                <Tab viewpriority={0} key="tab-head-templates">
                    <span>Templates {!hasPBXTemplatesBrandingFeature && (
                        <i className="fa-solid fa-user-secret" title="System Owner Only"></i>)}</span>
                </Tab>
            );

            tabPanels.push(
                <TabPanel viewpriority={0} key="tab-panel-templates">
                    <div className="row">
                        <div className="col-md-12" style={{ padding: "35px 0px 35px 15px" }}>
                            <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => {
                                self.goToAddTemplates(self.props.account.Id, "TRUNK");
                            }}><i className="fa-solid fa-plus"></i> Add Trunk Template
                            </button>
                            <button className="btn btn-primary" onClick={() => {
                                self.goToAddTemplates(self.props.account.Id, "PBX");
                            }}><i className="fa-solid fa-plus"></i> Add PBX Template
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{ padding: "0 0 35px 15px" }}>
                            <p style={{ display: "inline", marginRight: "10px" }}>Parent Templates are {self.props.account.HideAncestorPBXTemplates ? "disabled" : "enabled" }</p>
                            <button className="btn btn-primary" onClick={() => {
                                    self.setHideAncestorPBXTemplates(self.props);
                                }}>{self.props.account.HideAncestorPBXTemplates ? "Click to enable" : "Click to disable" }
                            </button>
                            <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleHelp("showAncestorPBXTemplatesHelp"); return false; }}>
                                <i className="fa-solid fa-question-circle" id={"AncestorPBXTemplatesHelp"}></i>
                            </button>
                        </div>
                    </div>
                    {self.state.showAncestorPBXTemplatesHelp
                        ?   <TeachingBubble
                                target={'#AncestorPBXTemplatesHelp'}
                                hasCondensedHeadline={true}
                                onDismiss={() => self.dismissHelp("showAncestorPBXTemplatesHelp")}
                                hasCloseIcon={true}
                                closeButtonAriaLabel="Close"
                                headline={"Hide Ancestor Templates"}>
                                <p>Hiding ancestor templates will mean all PBX and trunk templates 
                                configured above this account, including the platform's global templates, 
                                will no longer show as available within the portal.</p>
                            </TeachingBubble>
                        : null}
                    <BrandingTemplatesReactTable
                        lastAccountRefreshTime={self.state.lastAccountRefreshTime}
                        serviceVariants={self.props.serviceVariants}
                    />
                </TabPanel>
            );
        }

        var hasPortalLogoFeature =
            canAccessFeature(
                "PORTALLOGO",
                "Brand Contributor",
                self.props.account.roles,
                self.props.account);

        if (isSystemAccountLocation === false && (hasPortalLogoFeature || isSystemOwnerRole)) {
            tabHeaders.push(
                <Tab viewpriority={1} key="tab-head-images">
                    <span>Images {!hasPortalLogoFeature && (
                        <i className="fa-solid fa-user-secret" title="System Owner Only"></i>)}</span>
                </Tab>
            );

            tabPanels.push(
                <TabPanel viewpriority={1} key="tab-panel-images">
                    <BrandingLogoForm />

                    <BrandingImageForm
                        objectType="branding/iconImage"
                        header="Favicon"
                        recommendations="A square image is recommended for optimal display with a maximum height and width of 128px."
                        key="icon"
                    />
                </TabPanel>
            );
        }

        var hasSupportLinksBrandingFeature =
            canAccessFeature(
                "SUPPORTLINKS",
                "Brand Contributor",
                self.props.account.roles,
                self.props.account);

        if (isSystemAccountLocation === false && (hasSupportLinksBrandingFeature || isSystemOwnerRole)) {
            tabHeaders.push(
                <Tab viewpriority={2} key="tab-head-links">
                    <span>Links {!hasSupportLinksBrandingFeature && (
                        <i className="fa-solid fa-user-secret" title="System Owner Only"></i>)}</span>
                </Tab>
            );

            tabPanels.push(
                <TabPanel viewpriority={2} key="tab-panel-links">
                    <BrandingExternalLinks />
                </TabPanel>
            );
        }

        var hasPhoneAppFeature =
            canAccessFeature(
                "PHONEAPPBRANDING",
                "Brand Contributor",
                self.props.account.roles,
                self.props.account);

        if (hasPhoneAppFeature) {
            tabHeaders.push(
                <Tab viewpriority={2} key="tab-head-links">
                    <span>PhoneApp Branding</span>
                </Tab>
            );
            tabPanels.push(
                <TabPanel viewpriority={2} key="tab-panel-links">
                    <PhoneAppBranding />
                </TabPanel>
            );
        }

        return {
            Headers: tabHeaders,
            Panels: tabPanels
        };
    }

    updateTab = (index) => {
        this.setState({ tabIndex: index });
    }

    render = () => {
        var self = this;
        var tabs = self.getTabs();

        var tabs = (
            <Tabs selectedIndex={this.state.tabIndex} onSelect={self.updateTab}>
                <TabList>
                    {tabs.Headers}
                </TabList>
                {tabs.Panels}
            </Tabs>
        );

        return (
            <>
                {tabs}
            </>
         )
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount,
        baseAccountInfo: account.baseAccountInfo,
        contractAccount: account.contractAccount
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refreshAccount: (callBack = () => { }) => dispatch(actions.refreshAccount(callBack))
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandingTabs));
