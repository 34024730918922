import { matchPath } from 'react-router-dom';
import { cmsGetBaseAccounts, cmsGetPromise, getO365TenantLocalStorage, setLastAzureAccountLocalStorage, setO365TenantLocalStorage } from '../../CallMSAPI';
import { canAccess, showConsoleLogs } from '../../CallMSUIHelpers';
import { getIdToken } from '../../MSALAuthProvider';
import * as actionTypes from './actionTypes';

var _ = require('lodash');

const cb = () => { };

const start = () => {
    return {
        type: actionTypes.ACCOUNT_START,
        loaded: false
    };
};

const success = (account) => {
    return {
        type: actionTypes.ACCOUNT_SUCCESS,
        account
    };
};

const fail = (err) => {
    return {
        type: actionTypes.ACCOUNT_FAIL,
        account: {
            Id: '',
            Links: []
        },
        baseAccountInfo: {},
        loaded: true,
        err: err
    };
};

const switchingAccount = (switchingAcc = false) => {
    return {
        type: actionTypes.ACCOUNT_SWITCHING,
        switchingAcc: switchingAcc
    };
}

const getBaseAccountId = () => {
    return (dispatch, getState) => {
        let baseId = '';

        const baseAccount = getState().account.baseAccount;
        const baseAccounts = getState().account.baseAccounts;

        if (baseAccount.Id !== '') {
            baseId = baseAccount.Id;
        } else {
            let token = getIdToken();
            let tenant = getO365TenantLocalStorage(token.oid);

            if (tenant && tenant.hasOwnProperty('baseAccId')) {
                baseId = tenant.baseAccId;
            }

            if (baseAccounts && baseAccounts.length) {
                if (baseId === null) {
                    baseId = baseAccounts[0].Id;
                } else {
                    // We found a value from cookie...
                    // We need to check this is still valid...
                    const entry = baseAccounts.find(x => x.Id === baseId);
                    if (!entry) {
                        let firstValue = baseAccounts[0].Id;

                        baseId = firstValue;
                    }
                }
            }
        }

        if (baseId === null) {
            dispatch(fail(new Error('We are unable to find a usable base account')));
        }
        if(showConsoleLogs()) {
            console.log(`Returning base account => ${baseId}`);
        }

        return baseId;
    }
}

export const loginAccount = () => {
    return dispatch => {
        dispatch(start());

        const baseId = dispatch(getBaseAccountId());
        const match = accountMatch();
        let accountId = baseId;
        if (match) {
            if (match.params.accountId != null && match.params.accountId.length > 0) {
                accountId = match.params.accountId;
            }
        }

        dispatch(switchBaseAccount(baseId, accountId));
    };
}

const urlCheckAccChange = (url = '') => {
    if (url && url.length > 0) {
        switch (url) {
            case '/':
            case '/accounts':
                return false;
            default:
                return true;
        }

    }
    return false;
}

export const handleChangedAccount = (prevProps) => {
    return (dispatch, getState) => {
        const prevMatch = accountMatch(prevProps.location.pathname);
        const match = accountMatch();
        const reduxAccount = getState().account;

        if (!reduxAccount.loaded) {
            return;
        }

        if (match && prevMatch) {

            const checker = urlCheckAccChange(match.url);

            if (!checker) {
                return;
            }

            if (prevMatch.url != match.url || prevMatch.path != match.path) {
                const account = reduxAccount.account;

                if (match.params.accountId !== account.Id) {
                    dispatch(switchingAccount(true));
                    dispatch(setAccount(!match.params.accountId ? reduxAccount.baseAccount.Id : match.params.accountId, () => {
                        dispatch(switchingAccount(false));
                    }));
                }
            }

        }
    }
}

export const switchBaseAccount = (baseId = '', accountId = '', callBack = cb) => {
    return dispatch => {
        dispatch(getBaseAccounts(() => {
            if (!baseId) {
                baseId = dispatch(getBaseAccountId());
            }
            dispatch(setBaseAccount(baseId, () => {
                dispatch(setAccount(accountId ? accountId : baseId, callBack));
            }));
        }));
    }
}

/**
* Checks if user has the roles in base account. 
* Returns true if atleast one role is available.
* @param {Array<string>} roles identifier by key to value
* @param {boolean} isAll default false. When false will return true if atleast one role is available.
*/
export const hasUserRoles = (roles = [''], isAll = false) => {
    return (_dispatch, getState) => {
        const baseAccountInfo = getState().account.baseAccountInfo;
        if (baseAccountInfo && baseAccountInfo.Roles) {
            let hasRoles = true;

            roles.every(r => {
                const index = baseAccountInfo.Roles.findIndex(role => role.Name === r);

                if (index === -1) {
                    hasRoles = false;
                    //Check all so that atleast one is true.
                    return isAll ? false : true;
                }

                //Atleast one is true. hasRoles is true and return false to exit.
                if (!isAll) {
                    hasRoles = true;
                    return false;
                }

                return true;
            });

            return hasRoles;
        } else {
            return false;
        }
    }
}

/**
 * Deprecated. Use hasUserRoles.
 * @see 
 */
export const hasRole = (uiPart = '') => {
    return (_dispatch, getState) => {
        const baseAccountInfo = getState().account.baseAccountInfo;
        if (baseAccountInfo && baseAccountInfo.Roles) {
            return canAccess(uiPart, baseAccountInfo.Roles);
        } else {
            return false;
        }
    }
}

export const refreshAccount = (callBack = cb) => {
    return (dispatch, getState) => {
        const reduxAcc = getState().account;
        dispatch(setIncorrectLogin());
        dispatch(switchBaseAccount(reduxAcc.baseAccount.Id, reduxAcc.account.Id, callBack));
    }
}

export const setAccount = (id = '', callBack = cb) => {
    return (dispatch, getState) => {

        let baseAccount = getState().account.baseAccount;
        let baseAccountInfo = getState().account.baseAccountInfo;

        cmsGetPromise({
            accountId: dispatch(getBaseAccountId()),
            objectType: 'accountpath',
            objectId: id
        }).then(res => {
            let paths = res.data;

            let accPromises = [
                cmsGetPromise({ accountId: id }),
                cmsGetPromise({ accountId: id, objectType: 'admininvitationroles' }),
                cmsGetPromise({ accountId: dispatch(getBaseAccountId()), objectType: 'admininvitationroles' })
            ];


            // For any account we search the tree to find the first contract.
            // Not just sys owners, available for anyone
            var contractAccountObject = null;
            var contractUseAccount = false;
            var contractAccountPurchaseEnabled = false;

            // From last path array entry... look for the first that has
            // any sort of contract
            var reversePath = paths.slice(0).reverse();
            var contractAccId = baseAccount.Id;

            for (var i = 0, len = reversePath.length; i < len; i++) {
                var posAcc = reversePath[i];
                if (posAcc && posAcc.AccountType !== 'Standard') {
                    contractAccId = posAcc.Id;
                    break;
                }
            }
            if(showConsoleLogs()) {
                console.log(`Using contract ID: ${contractAccId}`);
            }

            // Mini optimisations...
            if (contractAccId === baseAccount.Id) {
                contractAccountObject = baseAccount;
                contractAccountPurchaseEnabled = baseAccountInfo.IsPurchaseEnabled;
            } else if (contractAccId === id) {
                contractUseAccount = true;
                contractAccountPurchaseEnabled = true; // search above means this must be true
            } else {
                contractAccountPurchaseEnabled = true; // search above means this must be true
                // Going to need a new request
                accPromises.push(cmsGetPromise({ accountId: contractAccId }));
            }

            Promise.allSettled(accPromises).then((resps = [new XMLHttpRequest()]) => {
                // each resps entry is an object with 'status' and value
                // status === "fulfilled" if all good.
                var accountResponse = resps[0];
                if (accountResponse.status !== 'fulfilled') {
                    dispatch(errHandler(id, 'Unable to retrieve account object'));
                    return;
                }

                var account = accountResponse.value.data;

                // Default to no account roles
                // TBC: Do we need account level roles for anything?
                var roles = [];
                if (resps[1].status === 'fulfilled') {
                    var fullRoles = resps[1].value.data;
                    if (fullRoles && fullRoles.Results) {
                        roles = fullRoles.Results;
                    }
                }

                var baseRoles = [];
                if (resps[2].status === 'fulfilled') {
                    baseRoles = resps[2].value.data;
                    if (baseRoles && baseRoles.Results) {
                        baseRoles = baseRoles.Results;
                    }
                }

                // Did we also request a contact account?
                var contractAccountResponse = resps[3];
                if (contractAccountResponse
                    && contractAccountResponse.status === 'fulfilled'
                    && contractAccountResponse.value.data
                ) {
                    // For contract account, could be new, mid-level account
                    contractAccountObject = contractAccountResponse.value.data;
                } else if (contractUseAccount) {
                    // Could be the account we are looking at
                    contractAccountObject = account;
                }
                // otherwise it should be baseAccount, already stored in contractAccountObject

                // Easy access to account info blob for contract account
                var infoBlob = null;
                if (contractAccountObject && contractAccountObject.Id) {
                    infoBlob = paths.find(x => x.Id === contractAccountObject.Id);
                }

                dispatch(success({
                    account: {
                        ...account,
                        roles
                    },
                    accountHierarchy: paths,
                    baseAccount: {
                        ...baseAccount,
                        roles: baseRoles
                    },
                    contractAccount: {
                        ...contractAccountObject,
                        purchasedEnabled: contractAccountPurchaseEnabled,
                        info: infoBlob
                    },
                    loaded: true
                }));

                callBack();

            }, err => {
                dispatch(errHandler('', err));
            });

        }).catch(err => {
            //Switch account...
            dispatch(errHandler('', err));
        });
    }
};

/**
 * Get branding link by ExternalLinkTypeName
 * When ExternalLinkTypeName is header-logo or favicon. We find link via account.
 * @param {string} exLinkTypeName ExternalLinkTypeName required.
 */
export const getLinkUrl = (exLinkTypeName = '') => {
    return (_dispatch, getState) => {

        const linkUrl = {
            ExternalLinkTypeName: '',
            URL: '',
            Text: '',
        };

        try {
            const account = getState().account;

            if (!account.loaded || !account.baseAccount || !account.account) {
                return linkUrl;
            }

            let links = account.baseAccount.Links && account.baseAccount.Links.length > 0 ? account.baseAccount.Links : [linkUrl];

            exLinkTypeName = exLinkTypeName.toLowerCase();

            switch (exLinkTypeName) {
                case 'header-logo':
                case 'favicon':
                case 'footer-text':
                case 'footer-text-secondary':
                case 'footer-text-subbrand-secondary':
                case 'footer-text-subbrand':
                    if (account.account.Links && account.account.Links.length > 0) {
                        links = account.account.Links;
                    }
                    break;
            }
            
            return links.find(x => x.ExternalLinkTypeName === exLinkTypeName);
        } catch (e) {
            console.error(e);
            return linkUrl;
        }
    }
}

export const accountMatch = (pathname = '') => {
    return matchPath(pathname.length > 0 ? pathname : window.location.pathname, {
        path: [
            '/accounts/:accountId',
            '/portal/:accountId',
            '/invite',
            '/',
            '/accounts'
        ],
        strict: false
    });
}

export const setUserUI = (params) => {
    return dispatch =>
    {
        const token = getIdToken();
        let tenant = getO365TenantLocalStorage(token.oid);

        if (tenant && tenant.hasOwnProperty('userUIState')) {
            if (!tenant['userUIState']) {
                tenant['userUIState'] = {
                    showPrivateFields: false
                };
            }

            _.merge(tenant['userUIState'], params, setO365TenantLocalStorage(token.oid, tenant));

            dispatch(success({
                userUI: tenant['userUIState']
            }));
        }
    }

   
}

const setBaseAccount = (id = '', callBack = cb) => {

    return (dispatch, getState) => {

        const baseAccount = getState().account.baseAccount;

        const baseAccounts = getState().account.baseAccounts;

        if(showConsoleLogs()) {
            console.log(`Set base account to ${id}`);
        }

        const isDiffAcc = baseAccount.Id !== id;

        /* Full base account info */
        if (isDiffAcc) {

            cmsGetPromise({
                accountId: id
            }).then(res => {
                const infoBlob = baseAccounts.find(x => x.Id === id);

                if (!infoBlob) {
                    //Too handle when a user deletes themself and refresh page. Redirects to AppSwitchScreen.js
                    return dispatch(errHandler('', { response: { status: 400 } }));
                }

                dispatch(success({
                    baseAccount: res.data,
                    baseAccountInfo: infoBlob
                }));

                callBack();
            }).catch(err => {
                if(showConsoleLogs()) {
                    console.log(`Unable to retrieve full account details for ${id}`);
                    console.log(err);
                }

                if (baseAccount.requireAccount) {
                    dispatch(fail(new Error(`Unable to load appropriate base account: ${err.message}`)));
                } else {
                    dispatch(fail(err));
                }
            });
        }

        let uiState = {};
        let token = getIdToken();
        const tenant = getO365TenantLocalStorage(token.oid);

        if (tenant && tenant.hasOwnProperty('userUIState')) {
            if (!tenant['userUIState']) {
                tenant['userUIState'] = {
                    showPrivateFields: false
                };
            }
            uiState = tenant['userUIState'];

            dispatch(success({
                userUI: tenant['userUIState']
            }));
        }

        // Don't rely on this state     
        setO365TenantLocalStorage(
            token.oid, {
            baseAccId: id,
            version: 2,
            tid: token.tid,
            iss: token.iss,
            upn: token.upn,
            userUIState: uiState
        });

        if (!isDiffAcc) {
            callBack();
        }
    }


}

const getBaseAccounts = (callBack = cb) => {
    return (dispatch) => {

        cmsGetBaseAccounts(resp => {
            if (resp && resp.Results.length === 0) {
                dispatch(setIncorrectLogin(true));
            } else {
                setLastAzureAccountLocalStorage();

                dispatch(success({
                    baseAccounts: resp.Results,
                    baseAccountsLoaded: true
                }));

                callBack();
                if(showConsoleLogs()) {
                    console.log('Base accounts loaded for user');
                }

            }
        }, err => {

            dispatch(setIncorrectLogin(true));
            if(showConsoleLogs()) {
                console.log(`No base accounts: ${err}`);
            }
        });
    }
}

const errHandler = (id = '', err) => {
    return (dispatch, getState) => {
        const account = getState().account;

        if (err.response) {
            if (err.response.status >= 400 && err.response.status < 500) {
                return dispatch(success({
                    ...account,
                    loaded: true,
                    baseAccountsLoaded: true,
                    account: {
                        ...account.account,
                        Id: id
                    }
                }));
            }
        }

        return dispatch(fail(new Error(`Unable to retrieve requested account: ${err}`)));
    }
}

const setIncorrectLogin = (incorrectLogin = false) => {
    return dispatch => {
        dispatch(success({
            incorrectLogin
        }));
    }
}