import React, { Component, useState, useEffect, useMemo } from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  useExpanded,
  useFlexLayout,
} from "react-table";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

var _ = require("lodash");

const ExpandingTable = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(null);
  const [pageNo, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(
    props.defaultPageSize ? props.defaultPageSize : 20
  );
  const [pageSizeOptions, setPageSizeOptions] = useState(props.pageSizeOptions ? props.pageSizeOptions : [5, 10, 20, 25, 50, 100]);
  const [sortSettings, setSortSettings] = useState(
    props.sortBy ? props.sortBy : {}
  );
  const [filters, setFilters] = useState(props.filters);
  const [searchText, setSearchText] = useState(props.searchText);

  const fetchTableData = () => props
    .fetchData(filters, searchText, pageSize, pageNo, sortSettings)
    .then((resp) => {
      if (resp && resp.data) {
        if(data != resp.data.Results || pages != resp.data.PageCount || page != resp.data.CurrentPage) {
          setData(resp.data.Results);
          setPages(resp.data.PageCount);
          setPage(resp.data.CurrentPage);
        }
        setLoading(false);
      }
    })
    .catch((err) => toast.error(err));

  useEffect(() => {
    if(props.fullRefresh) {
      setData([]);
      setPages(null);
      setPage(null);
      setFilters(null);
      setSearchText(null);
      setPageSize(props.defaultPageSize ? props.defaultPageSize : 20);
    } else {
      setLoading(true);
      fetchTableData();
    }
  }, [props.update]);

  useEffect(() => {
    if(searchText != props.searchText){
      setSearchText(props.searchText)
    }
  }, [props.searchText]);

  useEffect(() => {
    if(filters != props.filters){
      setFilters(props.filters)
    }
  }, [props.filters]);

  useEffect(() => {
    let debounceSearch = setTimeout(() => fetchTableData(), 750);
    return () => {
      clearTimeout(debounceSearch);
    }
  }, [searchText]);

  useEffect(() => {
    setLoading(true);
    fetchTableData();
  }, [filters, pageSize, pageNo, sortSettings]);


  const rt7Expander = {
    Header: () => null,
    id: "expander",
    width: 35,
    Cell: ({ row }) => (
      <div
        className={row.isExpanded ? "rt-expander -open" : "rt-expander"}
        {...row.getToggleRowExpandedProps()}
      >
        .
      </div>
    ),
  };

  const columns = useMemo(
    () =>
      props.columns
        ? props.SubComponent
          ? [rt7Expander, ...props.columns]
          : props.columns
        : [],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
  } = useTable(
    { columns, data, manualPagination: true },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  let filtersIncluded = columns.filter((x) => x.Filter).length > 0;

  return (
    <div className={`ReactTable ${props.className ? props.className : ""}`}>
      <div {...getTableProps()} className="table rt-table">
        {headerGroups &&
          headerGroups.map((headerGroup) => {
            const { key, ...restHeaderProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <>
                <div
                  key={`mainHeader-${key}-${Date.now()}`}
                  className=" rt-thead -header"
                  {...restHeaderProps}
                >
                  <div className="tr rt-tr">
                    {headerGroup.headers && headerGroup.headers.map((column, i) => {
                      let style = {};
                      if (column.id === "expander") {
                        style.flex = "35 0 auto";
                        style.width = "35px";
                        style.maxWidth = "35px";
                      }
                      if (column.colWidth) {
                        style.width = `${column.colWidth}px`;
                        style.maxWidth = `${column.colWidth}px`;
                      }

                      return (
                        <div
                          className={`th rt-th ${
                            column.id === "expander" ? "rt-expandable" : ""
                          } ${
                            column.isSorted
                              ? column.isSortedDesc
                                ? "-sort-desc"
                                : "-sort-asc"
                              : ""
                          }`}
                          {...column.getHeaderProps()}
                          style={style}
                          onClick={(e) => {
                            if (column.sortable) {
                              let newSortBy = {};
                              if (sortSettings.Column === column.Header) {
                                newSortBy.Column = column.Header;
                                newSortBy.SortDirection =
                                  sortSettings.SortDirection === "Ascending"
                                    ? "Descending"
                                    : "Ascending";
                              } else {
                                newSortBy.Column = column.Header;
                                newSortBy.SortDirection = "Descending";
                              }
                              setSortSettings(newSortBy);
                            } else {
                              column
                                .getHeaderProps(column.getSortByToggleProps())
                                .onClick(e);
                            }
                          }}
                        >
                          {column.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {filtersIncluded ? (
                  <div
                    className="rt-thead -filters"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    <div className="tr rt-tr">
                      {headerGroup.headers && headerGroup.headers.map((column) => {
                        let style = {};
                        if (column.id === "expander") {
                          style.flex = "35 0 auto";
                          style.width = "35px";
                          style.maxWidth = "35px";
                        }
                        if (column.colWidth) {
                          style.width = `${column.colWidth}px`;
                          style.maxWidth = `${column.colWidth}px`;
                        }
                        return (
                          <div
                            className={`th rt-th ${
                              column.id === "expander" ? "rt-expandable" : ""
                            }`}
                            {...column.getHeaderProps()}
                            style={style}
                          >
                            <div>
                              {column.canFilter && column.Filter
                                ? column.render("Filter")
                                : null}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </>
            );
          })}
        <div {...getTableBodyProps()} className="tbody rt-tbody">
          {rows &&
            rows.map((row) => {
              prepareRow(row);

              const { key, ...restRowProps } = row.getRowProps();
              let isOdd = row.index % 2 === 1 ? true : false;
              return (
                <div
                  className="rt-tr-group"
                  {...restRowProps}
                  key={`rowKey-${key}-${Date.now()}`}
                >
                  <div className={`tr rt-tr ${isOdd ? "-odd" : "-even"}`}>
                    {row.cells &&
                      row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();
                        let style = {};
                        if (cell.column.id === "expander") {
                          style.flex = "35 0 auto";
                          style.width = "35px";
                          style.maxWidth = "35px";
                        }
                        if (cell.column.colWidth) {
                          style.width = `${cell.column.colWidth}px`;
                          style.maxWidth = `${cell.column.colWidth}px`;
                        }
                        return (
                          <div
                            key={`key-cell-${key}-${Date.now()}`}
                            style={style}
                            className={`td rt-td ${
                              cell.column.id === "expander"
                                ? "rt-expandable"
                                : ""
                            } ${
                              cell.column.className ? cell.column.className : ""
                            }`}
                            {...restCellProps}
                          >
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                  </div>
                  {row.isExpanded ? (
                    <div className="tr">
                      <div>
                        {props.SubComponent(row, row.toggleRowExpanded)}
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
      {props.showPagination ? (
        <div className="pagination-bottom">
          <div className="-pagination">
            <div className="-previous">
              {rows && rows.length > 0 && page && page.length > 0 ? (
                <button
                  className="-btn"
                  onClick={() => {
                    if (pageNo > 0) {
                      setPage(pageNo - 1);
                    }
                  }}
                  disabled={pageNo <= 1}
                >
                  Previous
                </button>
              ) : null}
            </div>
            <div className="-center">
              {rows && rows.length > 0 && page && page.length > 0 ? (
                <>
                  <div>
                    Page{" "}
                    <em>
                      {pageNo} of {pages}
                    </em>
                  </div>

                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {pageSizeOptions && pageSizeOptions.map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </>
              ) : null}
            </div>
            <div className="-next">
              {rows && rows.length > 0 && page && page.length > 0 ? (
                <button
                  className="-btn"
                  onClick={() => {
                    if (pageNo < pages) {
                      setPage(pageNo + 1);
                    }
                  }}
                  disabled={pageNo >= pages}
                >
                  Next
                </button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {page.length === 0 && rows.length === 0 ? (
        <div className="rt-noData">{props.noDataText}</div>
      ) : null}
      {loading ? (
        <div className="-loading">
          <div className="-loading-inner">{props.loadingText}</div>
        </div>
      ) : null}
    </div>
  );
};

export default ExpandingTable;
