import React, { Component } from 'react';
import './AllApp.css';
import { NavLink } from 'react-router-dom'
import Header from './Header';
import Footer from './Footer';
import { isSyncOnlyContributor, isSystemOwnerSystemAcc } from './CallMSUIHelpers.js';

import { connect } from 'react-redux';
import * as actions from './store/actions/index';
var _ = require('lodash');

class AppPortal extends Component {

    componentDidMount() {
        this.props.getServices();
    }

    render() {
        var self = this;

        var links = [];

        if (!isSyncOnlyContributor(self.props.baseAccountInfo.Roles)) {

            links.push(
                <NavLink key='gettingstarted' data-testid='gettingStartedNavbarId' activeClassName='current' to={`/portal/${self.props.account.Id}/gettingstarted`}>Getting Started</NavLink>
            );


            links.push(
                <NavLink key='services' data-testid='servicesNavbarId' activeClassName='current' to={`/portal/${self.props.account.Id}/services`}>Services</NavLink>,
            );

            if (!isSystemOwnerSystemAcc(this.props.baseAccountInfo.Roles, this.props.account)) {
                links.push(
                    <NavLink key='users' data-testid='usersNavbarId' activeClassName='current' to={`/portal/${self.props.account.Id}/users`}>Users</NavLink>,
                );
            }

            links.push(
                <NavLink key='accountmanage' data-testid='accountNavbarId' activeClassName='current' exact to={`/portal/${self.props.account.Id}`}>Account</NavLink>
            );
        }

        return (
            <div className='page-wrapper'>
                <Header links={links} />

                <br />
                <div className='container container--main-content' role='main'>

                    <div className='col-md-12'>
                        <div className='row'>
                            {this.props.children}
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
        
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        baseAccountInfo: account.baseAccountInfo,
        account: account.account
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getServices: () => dispatch(actions.getServices())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppPortal);