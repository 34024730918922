import React, { Component } from 'react';
import { cmsGet, cmsGetAccountPrivateData, getErrMsg } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import SystemOwnerForm from './SystemOwnerForm';
import EditAccountForm from './EditAccountForm';
import AccountSubscriptions from './AccountSubscriptions';
import { connect } from 'react-redux';

class AccountRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetAccount: null,
            privateData: null
        };
        this.getFullAccount = this.getFullAccount.bind(this);
    }

   componentDidMount() {
        this.getFullAccount();
    }

    getFullAccount() {
        var self = this;
        cmsGet({
            accountId: this.props.targetAccountOverview.Id,
        }, function (data) {
            self.setState({
                targetAccount: data
            });
        }, function (error) {
            toast.error("Unable to load full account object for " + self.props.targetAccountOverview.Name);
            toast.error(getErrMsg(error));
        });

        cmsGetAccountPrivateData(
            self.props.baseAccount.Id,
            self.props.targetAccountOverview.Id,
            function (data) {
                self.setState({ privateData: data });
            }, function (err) {
                toast.error(getErrMsg(err));
                // Set non-null value to allow form to load
                self.setState({ privateData: {} });
            });
    }


    render() {
        var self = this;

        if (self.state.targetAccount === null || self.state.privateData === null) {
            return <p>Loading...</p>;
        }

        return (
            <div className="account-expand-wrapper">
                <SystemOwnerForm
                    targetAccount={self.state.targetAccount}
                    closeCallback={self.props.closeCallback}
                />
                <EditAccountForm
                    targetAccount={self.state.targetAccount}
                    privateData={self.state.privateData}
                    closeCallback={self.props.closeCallback}
                    accountUpdateTrigger={self.getFullAccount}
                />
                <AccountSubscriptions
                    targetAccount={self.state.targetAccount}
                    closeButtonText={"Close"}
                    submitButtonText={"Save"}
                    clearChanges={self.props.clearChanges}
                    closeCallback={self.props.closeCallback}
                />
            </div>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountRow);
