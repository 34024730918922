import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

import PBXTrunkServiceNew from './PBXTrunkServiceNew';
import TrunkServiceEdit from './TrunkServiceEdit';

class TrunkServicePanel extends Component {
    render() {
        var self = this;

        var addService = null;
        if (self.props.availableService
            && self.props.availableService.AllowMultipleInstances
            && self.props.allowNewService
        ) {
            addService = (
                <div>
                    <PBXTrunkServiceNew
                        serviceName="Trunk"
                        variant={self.props.availableServiceVariant}
                        existingCount={self.props.currentServices ? self.props.currentServices.length : 0}
                        canSelectTrueTransfer={self.props.services.canSelectTrueTransfer}
                        trueTransferSupported={self.props.services.trueTransferSupported}
                        restrictServiceTabs={self.props.restrictServiceTabs}
                        saveServiceCallback={self.props.saveServiceCallback}
                        limitToOneService={self.props.limitToOneService}
                    />
                </div>
            );
        }

        var serviceList = [];
        if (self.props.currentServices) {
            self.props.currentServices.forEach(function (service) {
                serviceList.push(
                    <div key={service.Id}>
                        <TrunkServiceEdit
                            totalPBXTrunk={self.props.currentServices.length}
                            currentService={service}
                            variant={self.props.availableServiceVariant}
                            refetchServices={self.props.refetchServices}
                            canSelectTrueTransfer={self.props.services.canSelectTrueTransfer}
                            trueTransferSupported={self.props.services.trueTransferSupported}
                        />
                    </div>
                );
            });
        }

        return (<div className="service-panel">{serviceList} {addService}</div>);
    }

}
const mapStateToProps = state => {
    const services = state.services;
    return {
        services
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refetchServices: () => dispatch(actions.refetchServices())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TrunkServicePanel);
