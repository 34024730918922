import React, { Component } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { cmsGetSingleServiceUserPartsPromise, cmsGetServiceUserPartsPromise } from './CallMSAPI';
import { serviceCodeTypeToIcon } from './CallMSUIHelpers';

class ServiceUserPartAsyncSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstValue: null,
            defaultValue: ''
        };
        this.loadOptions = this.loadOptions.bind(this);
        this.getOptionLabel = this.getOptionLabel.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

   UNSAFE_componentWillMount() {
        this.loadValue();
    }

    loadValue() {
        var self = this;

        if (this.props.value) {

            cmsGetSingleServiceUserPartsPromise(
                this.props.account.Id, this.props.service.Id, this.props.value
            )
                .then(function (data) {
                    if (data && data.data) {
                        self.setState({
                            firstValue: data.data,
                            defaultValue: data.data
                        })
                    }
                });
        } else {
            self.setState({
                defaultValue: this.props.value
            })
        }
    }

    async loadOptions(input, loadOpts, { page }) {
        var opts = {
            CurrentPage: page,
            isMapped: false,
            SortBy: 'NameOrIdentifier'
        };
        if (input !== '') {
            opts['searchText'] = input;
        }

        if (this.props.service.SyncEnabled) {
            // If sync we could have a large number of users, Name can be indexed, NameOrIdentifier cannot.
            opts['SortBy'] = 'Name';
        }

        var response = await cmsGetServiceUserPartsPromise(this.props.account.Id, this.props.service.Id, opts);
        var data = response.data;
        var results = data.Results;

        // Make the initial value available
        if (this.state.firstValue && page === 1) {
            results.unshift(this.state.firstValue)
        }

        return {
            options: data.Results,
            hasMore: data.PageCount > data.CurrentPage,
            additional: {
                page: page + 1
            }
        };
    }

    handleChange(opt) {
        // Also update the value we show back to the end-user
        this.setState({
            defaultValue: opt
        });

        // Let upstream/formik know about the change
        this.props.onChange(opt);
    }

    getOptionLabel(option) {
        var self = this;
        var label = <>{option.Id}</>;
        var icon = null;

        if (self.props.showEmailWithName && option.ServiceUserPartType) {
            icon = <>{serviceCodeTypeToIcon(option.ServiceUserPartType, false)}&nbsp;</>
        }

        if (option.Name && option.Email && self.props.showEmailWithName) {
            label = <>{option.Name} <small style={{ color: 'darkgrey' }}> ({option.Email}) </small> </>;
        } else if (option.Name && option.UserPrincipalName && self.props.showEmailWithName) {
            label = <>{option.Name} <small style={{ color: 'darkgrey' }}> ({option.UserPrincipalName}) </small> </>;
        } else if (option.Name && option.PhoneNumber && self.props.showNumberWithName) {
            label = <>{option.PhoneNumber} <small style={{ color: 'darkgrey' }}> ({option.Name}) </small></>;
        } else if (option.Name) {
            label = <>{option.Name}</>;
        } else if (option.PhoneNumber) {
            label = <>{option.PhoneNumber}</>;
        }

        return (<p style={{ margin: '0' }}>{icon}{label}</p>)
    }


    render() {
        var self = this;

        return (
            <AsyncPaginate
                cacheOptions
                formatOptionLabel={self.getOptionLabel}
                getOptionValue={(option) => option.Id}
                loadOptions={self.loadOptions}
                className="select-dropdown"
                defaultOptions
                {...this.props}
                
                name={this.props.name}
                value={this.state.defaultValue}
                onChange={this.handleChange}

                additional={{
                    page: 1,
                }}
            />
        );
    }

}


export default ServiceUserPartAsyncSelect;
