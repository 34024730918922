import React, { Component } from 'react';
import { getErrMsg, cmsDeleteService, cmsUpdateService, cmsGetPBXTemplate, cmsGetAccountServicePromise } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import { withRouter } from 'react-router-dom';
import { generatePath } from "react-router";
import { generateServicePageURL } from '../CallMSUIHelpers.js';

class PBXTrunkServiceEdit extends Component {

    constructor(props) {
        super(props);

        var showFormAtStart = false;
        if (props.totalPBXTrunk <= 1) {
            showFormAtStart = true;
        } else if ( props.match.params.accountServiceId && props.match.params.accountServiceId === props.currentService.Id ) {
            showFormAtStart = true;
        }

        this.state = {
            pbxTemplate: null,
            pbxTemplateLoaded: false,
            pbxTemplateInProgress: false,
            showForm: showFormAtStart,
            hideDialog: true,

            fullDataLoaded: false,
            fullService: null
        };

        this.deleteService = this.deleteService.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.updateFullServiceTrigger = this.updateFullServiceTrigger.bind(this);
    }

    componentDidUpdate(prevProps) {
        var self = this;

        if (
            this.props.match.params.accountServiceId
            && this.props.match.params.accountServiceId !== prevProps.match.params.accountServiceId
            && this.props.match.params.accountServiceId === this.props.currentService.Id
        ) {
            this.setState({
                fullDataLoaded: false,
                showForm: true
            }, function () {
                self.loadFullService();
            });
        } else if (
            (this.props.currentService && !prevProps.currentService)
            || (
                this.props.currentService && prevProps.currentService
                && this.props.currentService.Id !== prevProps.currentService.Id
            )
        ) {
            this.setState({
                fullDataLoaded: false
            }, function () {
                self.loadFullService();
            });
        }
    }

   componentDidMount() {
        if (this.state.showForm) {
            this.loadFullService();
        }
    }

    showModal() {
        this.setState({ hideDialog: false });
    }

    hideModal() {
        this.setState({ hideDialog: true });
    }

    updateFullServiceTrigger() {
        var self = this;
        this.setState({
            fullDataLoaded: false
        }, function () {
            self.loadFullService();
        });
    }

    loadFullService() {
        var self = this;
        if (self.state.fullDataLoaded === false) {
            cmsGetAccountServicePromise(self.props.account.Id,
                self.props.currentService.Id).then(resp => {
                    self.setState({ fullDataLoaded: true, fullService: resp.data }, () => {
                        self.getPBXTemplate();
                    });
                });
        };
    }

    getPBXTemplate() {
        var self = this;
        if (this.state.fullService && this.state.fullService.PBXSettings.AccountPBXTemplateId) {
            cmsGetPBXTemplate(
                self.props.account.Id,
                self.state.fullService.PBXSettings.AccountPBXTemplateId,
                function (callmsData) {
                    self.setState({
                        pbxTemplate: callmsData,
                        pbxTemplateLoaded: true
                    });
                },
                function (error) {
                    toast.error("Unable to get the required service template: " + getErrMsg(error));
                    self.setState({
                        pbxTemplateLoaded: true
                    });
                }
            );
        }
    }

    defaultServiceVariant(Variants) {
        var lastAvail;
        var availCount = 0;
        Variants.forEach(function (v) {
            if (v.IsAvailable) {
                lastAvail = v;
                availCount++;
            }
        });
        if (availCount === 1) {
            return lastAvail.Id;
        } else {
            return '';
        }
    }

    saveService(values, okCb, errorCb) {
        let accId = values['accountId'];
        delete values['accountId'];

        let objId = values['Id'];
        delete values['Id'];

        cmsUpdateService(
            accId,
            objId,
            values,
            function (data) {
                toast.success("Service successfully updated");
                okCb && okCb(false, data);
            },
            errorCb
        );
    }

    deleteService(e) {
        e.preventDefault();
        var self = this;
        self.setState({ hideDialog: true });

        cmsDeleteService(
            self.props.account.Id,
            self.props.currentService.Id,
            function () {
                toast.success("Service deleted");

                self.props.refetchServices();
            }, function () {
                toast.error("Unable to delete service");
            }
        );
    }

    toggleExpand(e) {
        var self = this;
        e.preventDefault();

        // Toggle display by updating URL, then letting param update trickle to components.
        if (!this.state.showForm) {
            var url = generateServicePageURL(this.props.account.Id, this.props.currentService);

            this.props.history.push({ pathname: url });
        } else {
            var url = generatePath("/portal/:accountId/services/:serviceName", {
                accountId: this.props.account.Id,
                serviceName: this.props.match.params.serviceName
            });

            this.props.history.push({ pathname: url });

            // _not_ having the param in the URL doesn't auto hide the entry
            this.setState({showForm: false});
        }
    }

    render() {
        return (
            <div className="alert alert-info">
                {this.props.render(this)}
            </div>
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccountInfo: account.baseAccountInfo
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refetchServices: () => dispatch(actions.refetchServices())
    }
}
export default withRouter( connect(mapStateToProps, mapDispatchToProps)(PBXTrunkServiceEdit) );
