import React, { Component } from 'react';
import { Form, Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import PBXTrunkServiceForm from './PBXTrunkServiceForm';
import { DocumentList } from '../CallMSUIHelpers';
import { TrunkNumberRanges, AllowedIPs, CountryHOC, ServiceNumbers, checkWillRegister, checkHasCredentials  } from './ServiceFormHelpers';
import { SubmitButton } from '../FormHelpers';


var _ = require('lodash');

class TrunkServiceForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
        }

    }

    getFormDefaults($this) {
        var baseDefaults = $this.getPBXFormDefaults(true);

        var trunkDefaults = {
            // Trunk Settings
            Identifier: $this.formInputDefault('TrunkSettings', 'Identifier'),
            AuthUsername: $this.formInputDefault('TrunkSettings', 'AuthUsername'),
            AuthPassword: $this.formInputDefault('TrunkSettings', 'AuthPassword'),
            NumberRanges: $this.formInputDefault('TrunkSettings', 'Ranges', [{ Start: '', End: '' }]),
            ServiceNumbers: $this.formInputDefault('TrunkSettings', 'ServiceNumbers', [{ DisplayName: '', PhoneNumber: '' }]),
            Emergency: $this.formInputDefault('TrunkSettings', 'Emergency', false),
            NumberRangesBulkImport: null
        };

        _.merge(baseDefaults, trunkDefaults);

        return baseDefaults;
    }

    getBottomFormKeys(values, $this) {
        var self = this;
        var fields = [];
        fields = fields.concat($this.getBaseFormKeys());
        fields = fields.concat($this.getPBXFormKeys(true, values));
        fields = fields.concat($this.getNameCountryFormKeys());

        if ($this.hasEmergencyTrunkFeature($this.props.account)) {
            fields = fields.concat($this.getEmergencyTrunkKey());
        };

        fields = fields.concat(self.getTrunkFormKeys(values));
        fields = $this.addFormKeyState(fields);
        fields = _.sortBy(fields, "ViewOrder");
        return fields;
    }

    requiresCredentials(values) {
        if (values.FauxDoRegistration
            && (checkHasCredentials(values['FauxDoRegistration'])
                || checkWillRegister(values.FauxDoRegistration)
            )
        ) {
            return true;
        }
        return false;
    }

    /**
     * Pass in current service JSON blob to correctly set the HasPassword field.
     */
    getTrunkFormKeys(values) {
        var self = this;
        var template = self.props.pbxTemplate;
        var cur = self.props.currentService;

        var displayType = self.requiresCredentials(values) ? 'text' : 'hidden';
        var forceState = self.requiresCredentials(values) ? null : 'Hidden';

        return [
            {
                Id: "Identifier",
                Label: 'Username',
                Type: displayType,
                PresetState: (checkWillRegister(template.SBCMode) ? 'EditMandatory' : 'Edit'),
                ForceState: forceState,
                Group: "AuthSection",
                VisibilityKey: 'Credentials',
                ViewOrder: 5
            },
            {
                Id: "AuthUsername",
                Label: 'Auth Username',
                Group: "AuthSection",
                Type: displayType,
                ForceState: forceState,
                ViewOrder: 5
            },
            {
                Id: "AuthPassword",
                Label: 'Password',
                Group: "AuthSection",
                VisibilityKey: 'Credentials',
                Type: self.requiresCredentials(values) ? 'password' : 'hidden',
                ForceState: forceState,
                HasPassword: cur && cur.hasOwnProperty('TrunkSettings') && cur.TrunkSettings && cur.TrunkSettings.hasOwnProperty('HasPassword') ? cur['TrunkSettings']['HasPassword']: false,
                ViewOrder: 5
            }
            // Array of NumberRanges objects, containing Start and End
        ];
    }

    showServiceNumbers(values)
    {
        // CallerId or CallerId prefix, e.g. CallerId_E164WithPlus etc
        return (values["FromHeaderType"]
            // Guard against unknown enum values
            && typeof values["FromHeaderType"] === 'string'
            && values["FromHeaderType"].indexOf("CallerId") === 0
            && values.Emergency.toString() != "true"
        );
    }

    showNumberRanges(values)
    {
        return values.Emergency.toString() != "true";
    }

    render() {
        var self = this;

        if (!self.props.pbxTemplate) {
            return (
                <>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="alert alert-warning">
                                No service template was found, which means the form cannot be displayed. Please contact your administrator to report this problem.
                        </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                {self.props.children}
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        var documents = null;
        if (self.props.pbxTemplate && self.props.pbxTemplate.Documents && self.props.pbxTemplate.Documents.length) {
            documents = (
                <>
                    <DocumentList
                        heading="Documents"
                        documents={self.props.pbxTemplate.Documents}
                    />
                    <hr />
                </>
            );
        }

        return (
            <PBXTrunkServiceForm
                {...this.props}
                render={($this) => {
                    return (
                        <>
                            {documents}
                            <Formik
                                // Required so updates from getFormDefaults get propagated down to form
                                enableReinitialize={false}
                                initialValues={self.getFormDefaults($this)}
                                validate={$this.validateTrunkValues}
                                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {

                                    var values = _.cloneDeep(originalValues);
                                    if (values['StateId']) {
                                        values['CountryId'] = '';
                                    }

                                    $this.displayMisconfigurationWarnings(values);

                                    // This has to happen ahead of handleSubmitPBXValues, which will fiddle the data (e.g. remove FauxDoRegistration)
                                    var TrunkFormKeys = self.getTrunkFormKeys(values);

                                    // Figure this here before handleSubmitPBXValues gobbles up FauxDoRegistration
                                    var shouldDeleteCredentials = !self.requiresCredentials(values);
                                    values = $this.handleSubmitPBXValues(true, values);

                                    /*
                                     * Move Trunk specific settings to live under TrunkSettings entry in post data
                                     */
                                    values['TrunkSettings'] = {};

                                    TrunkFormKeys.forEach(function (obj) {
                                        var k = obj.Id;
                                        values['TrunkSettings'][k] = values[k];
                                        if (values['TrunkSettings'][k] === '') {
                                            values['TrunkSettings'][k] = null;
                                        }
                                        delete values[k];
                                    });

                                    if (shouldDeleteCredentials) {
                                        delete values['TrunkSettings']['Identifier'];
                                        delete values['TrunkSettings']['AuthUsername'];
                                        delete values['TrunkSettings']['AuthPassword'];
                                    }

                                    values['TrunkSettings']['Emergency'] = values['Emergency'];
                                    values['TrunkSettings']['NumberRanges'] =
                                        String(values['Emergency']) === 'true'
                                            ? null
                                            : values['NumberRanges'];

                                    values['TrunkSettings']['ServiceNumbers'] = values['ServiceNumbers'];
                                    delete values['NumberRanges'];
                                    delete values['ServiceNumbers'];

                                    // Don't save the empty default blank service number
                                    if (values['TrunkSettings']['ServiceNumbers'].length == 1 && values['TrunkSettings']['ServiceNumbers'][0].PhoneNumber === '') {
                                        values['TrunkSettings']['ServiceNumbers'] = [];
                                    }

                                    self.props.saveCallback(values, function (doReset, data) {
                                        setSubmitting(false);

                                        // Do we need to prompt for service enable?
                                        //Nick- Idk but I'm replacing it with Redux Action
                                        $this.props.refetchServices();

                                        // Update full service, so service refreshes on save
                                        self.props.updateFullServiceTrigger && self.props.updateFullServiceTrigger();

                                        // reset form
                                        if (doReset) {
                                            resetForm && resetForm(self.getFormDefaults($this));
                                        }

                                        self.props.closeCallback && self.props.closeCallback();

                                    }, function (error) {
                                        setSubmitting(false);
                                        toast.error(error);
                                    });

                                }
                                }
                            >

                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue
                                    /* and other goodies */
                                }) => {
                                    var FormHidden = [
                                        <Field key="AccountPBXTemplateId" type="hidden" name="AccountPBXTemplateId" value={values['AccountPBXTemplateId']} />
                                    ];

                                    var warnings = null;

                                    var FormInputsBottomAll = self.getBottomFormKeys(values, $this);

                                    // Split array based on viewgroup
                                    var FormInputsNameCountry = _.filter(FormInputsBottomAll, function (e) { return e.Group === 'NameCountry' });
                                    FormInputsNameCountry = $this.formKeysToComponents(values, errors, touched, setFieldValue, FormInputsNameCountry);

                                    if (String(values['Emergency']) === 'false') {
                                        if (values['FauxDoRegistration'].startsWith('DynamicEmergencyTrunk')) {
                                            values['FauxDoRegistration'] = "";
                                        }
                                    } else {
                                        if (!values['FauxDoRegistration'].startsWith('DynamicEmergencyTrunk')) {
                                            values['FauxDoRegistration'] = "";
                                        }
                                    }

                                    var FormInputsAuth = _.filter(FormInputsBottomAll, function (e) { return e.Group === 'AuthSection' });
                                    FormInputsAuth = $this.formKeysToComponents(values, errors, touched, setFieldValue, FormInputsAuth);

                                    var FormInputsBottom = _.filter(FormInputsBottomAll, function (e) { return !e.Group });
                                    FormInputsBottom = $this.formKeysToComponents(values, errors, touched, setFieldValue, FormInputsBottom);

                                   //check null here
                                    var FormEmergencyTrunk = _.filter(FormInputsBottomAll, function (e) { return e.Group === 'EmergencyTrunk' });
                                    if (FormEmergencyTrunk && FormEmergencyTrunk.length > 0) {
                                        FormEmergencyTrunk = $this.formKeysToComponents(values, errors, touched, setFieldValue, FormEmergencyTrunk);
                                    }

                                    var PolicyFormInputs = _.filter(FormInputsBottomAll, function (e) { return e.Group === 'CallingPolicy' });
                                    PolicyFormInputs = $this.formKeysToComponents(values, errors, touched, setFieldValue, PolicyFormInputs);

                                    var closeButton = null;
                                    if (self.props.closeCallback
                                        && (!self.props.restrictServiceTabs || (self.props.restrictServiceTabs && self.props.currentServices && self.props.currentServices.length >= 1))) {
                                        closeButton = <button
                                            onClick={self.props.closeCallback}
                                            className="btn btn-default btn--service-form"
                                            disabled={isSubmitting || values.IsUIDisabled }>{self.props.closeText}</button>;
                                    }
                                    var revealButton = null;
                                    if (self.props.pbxTemplate.hasOwnProperty('AllowReveal') && self.props.pbxTemplate.AllowReveal) {
                                        revealButton = $this.getHiddenFieldToggleButton(isSubmitting, values);
                                    }

                                    var showAllowedIPs = true;
                                    var readOnlyIps = false;
                                    var template = self.props.pbxTemplate;
                                    if (!$this.state.showHiddenFields
                                        && (template && template.hasOwnProperty('Visibility') && template.Visibility.AllowedIPs.indexOf('Hide') > -1)) {
                                        showAllowedIPs = false;
                                    }
                                    if (template
                                        && template.hasOwnProperty('Visibility')
                                        && !template.Visibility.AllowedIPs.toLowerCase().includes('edit')
                                        && !template.Visibility.AllowedIPs.toLowerCase().includes('hide')) {
                                        readOnlyIps = true;
                                    }
                                    var accountId = $this.props.account.Id ? $this.props.account.Id : null;

                                    var SBCModeDialog = $this.getGlobalPBXUpgradeDialog(values, setFieldValue);

                                    return (
                                        <Form className="form form-new-pbx-service">
                                            <fieldset disabled={isSubmitting}>
                                                {FormHidden}

                                                {warnings}

                                                <div className="user-form-parts-wrapper">
                                                    {FormInputsNameCountry}
                                                </div>

                                                {FormEmergencyTrunk && FormEmergencyTrunk.length > 0
                                                    ? <div className="user-form-parts-wrapper">
                                                        {FormEmergencyTrunk}
                                                    </div>
                                                    : null
                                                }
                                              
                                                <div className="user-form-parts-wrapper">
                                                    <CountryHOC
                                                        CountryId={values.CountryId}
                                                        StateId={values.StateId}
                                                        accountId={accountId}
                                                    >

                                                        {this.showNumberRanges(values) ? <TrunkNumberRanges
                                                            formValues={values}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            setFieldValue={setFieldValue}
                                                        /> : null}

                                                        {this.showServiceNumbers(values) ?<ServiceNumbers
                                                            formValues={values}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            setFieldValue={setFieldValue}
                                                        />:null}

                                                    </CountryHOC>
                                                </div>

                                                <br />

                                                <div className="user-form-parts-wrapper">
                                                    {FormInputsAuth}

                                                    {showAllowedIPs
                                                        ? <AllowedIPs
                                                            formValues={values}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            setFieldValue={setFieldValue}
                                                            serviceName={"Trunk"}
                                                            readOnly={readOnlyIps}
                                                        />
                                                        : null
                                                    }

                                                </div>

                                                <div className="user-form-parts-wrapper">
                                                    {PolicyFormInputs}
                                                </div>

                                                <div className="user-form-parts-wrapper">
                                                    {FormInputsBottom}
                                                </div>

                                                {$this.getE164Render(values, errors, touched, setFieldValue, FormInputsBottomAll, true)}

                                                <hr />
                                                {$this.renderIsEmergencyService()}
                                                {$this.renderDedicatedIpTag()}
                                                
                                                {$this.getDedicatedIps()}
                                                {$this.getSBCs()}        

                                                {$this.getSRV(values)}

                                                {SBCModeDialog}

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group form-group--with-central">
                                                            {self.props.children}

                                                            <div className="central-button-wrapper">
                                                                {revealButton}
                                                            </div>
                                                            <SubmitButton forceDisable={values.IsUIDisabled} isSubmitting={isSubmitting} className={"btn btn-primary btn--service-form"}>{self.props.saveText}</SubmitButton>
                                                            {closeButton}
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </Form>
                                    )
                                }
                                }

                            </Formik>
                        </>
                    );
                }}
            />
        );
    }
}
export default TrunkServiceForm;
