import React, { Component } from 'react';
import SystemContractForm from './SystemContractForm';
import SystemOffers from './SystemOffers';
import { cmsGet, cmsPut, getErrMsg, cmsPost } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

var _ = require('lodash');

class SystemOwnerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetContract: undefined
        };

        this.updateContract = this.updateContract.bind(this);
        this.getContract = this.getContract.bind(this);
        this.createContract = this.createContract.bind(this);
    }

    componentDidMount() {
        this.getContract();
    }

    getContract() {
        var self = this;
        cmsGet({
            accountId: this.props.targetAccount.Id,
            objectType: 'contract'
        }, function (data) {
            var res = null;
            if (data && data.hasOwnProperty('AccountId')) {
                res = data;
            }
            self.setState({
                targetContract: res
            });
        }, function (err) {
            self.setState({
                targetContract: null
            });
        })
    }

    updateContract(formik, values, setSubmitting, setErrors, resetForm) {
        var self = this;
        values['objectType'] = 'contract';
        cmsPut(values, function (data) {
            setSubmitting(false);

            // Clear form, close form
            resetForm && resetForm(formik.getFormDefaults());
            toast.success("Contract updated");

            self.getContract();
        }, function (error) {
            setSubmitting(false);
            toast.error("Unable to update contract: " + getErrMsg(error));
        })
    }

    createContract(formik, values, setSubmitting, setErrors, resetForm) {
        var self = this;
        values['objectType'] = 'contract';
        cmsPost(values, function (data) {
            setSubmitting(false);

            // Clear form, close form
            resetForm && resetForm(formik.getFormDefaults());
            toast.success("Contract created");

            self.getContract();
        }, function (error) {
            setSubmitting(false);
            toast.error("Unable to create contract: " + getErrMsg(error));
        })
    }

    render() {
        var self = this;

        if (!_.find(self.props.baseAccountInfo.Roles, { 'Name': 'System Owner' })) {
            return null;
        } else if (self.state.targetContract === undefined) {
            return null;
        } else {
            return (
                <div className="system-owner-wrapper">
                    <div style={{ textAlign: 'center' }}><i className="fa-solid fa-user-secret" title="System Owner Only" style={{ float: 'left' }}></i><small >System Owner Only</small></div>
                    <SystemContractForm
                        targetAccount={self.props.targetAccount}
                        targetContract={self.state.targetContract}
                        submitCallback={self.state.targetContract ? self.updateContract : self.createContract}
                        closeCallback={self.props.closeCallback}
                    />
                    {self.state.targetContract
                        ? <SystemOffers
                            targetAccount={self.props.targetAccount}
                            contract={self.state.targetContract}
                        />
                        : <p><strong><em>A contract is required to allow offers to be made available for an account.</em></strong></p>
                    }
                    <div className="clearfix"></div>
                </div>
            );
        }
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        baseAccountInfo: account.baseAccountInfo
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SystemOwnerForm);