import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { func, string } from 'prop-types';
import { getEmergencyServicesOptionsPromise } from '../../CallMSAPI';

const EmergencyDropDown = (props) => {

    const [options, setOptions] = useState([{ Name: 'No Trunk', Id: null }]);

    useEffect(() => {
        getEmergencyServicesOptionsPromise(props.accountId).then((res) => {
            if (res) {
                setOptions(options.concat(res));
            }
        });
    }, []);

    const selectedOpt = options.find(x => x.Id === props.emergencyAccountServiceId);

    return (
        <>
            {props.systemOwnerMode && (
            <div>
                <i className="fa-solid fa-user-secret" title="System Owner Only" />
                <label htmlFor="emgTrunkDdl">Emergency Trunk:</label>
                <small className="fw-normal"> (System Owner Only)</small>
            </div>
            )}

            {!props.systemOwnerMode && (
            <div>
                <label htmlFor="emgTrunkDdl">Emergency Trunk:</label>
            </div>
            )}
            <Select
                inputId='emgTrunkDdl'
                options={options}
                value={selectedOpt}
                onChange={props.onChangeCallBack}
                className='select-dropdown select-dropdown--pbx'
                getOptionLabel={(option) => option.Name}
                getOptionValue={(option) => option.Id}
            />
        </>
    );

}
EmergencyDropDown.defaultProps = {
    onChangeCallBack: (opt = { Name: '', Id: '' }) => { },
    accountId: '',
    emergencyAccountServiceId: null
};
EmergencyDropDown.propTypes = {
    onChangeCallBack: func.isRequired,
    accountId: string.isRequired,
    emergencyAccountServiceId: string
};
export default EmergencyDropDown;
