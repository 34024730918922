import React, { Component } from 'react';
import TrunkServiceForm from './TrunkServiceForm';
import PBXTrunkServiceEdit from './PBXTrunkServiceEdit';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import TrunkRegistrationLight from '../ServiceUsers/TrunkRegistrationLight';

class TrunkServiceEdit extends Component {

    toggle = (e, $this) => {
        e.stopPropagation();
        //make sure this click event does not affect Links which are children to this element
        if (e.target.classList && !e.target.classList.contains('ext_link')) {
            $this.toggleExpand(e);
        }
    }

    renderName = ($this) => {
        const realShowForm = ($this.state.showForm || this.props.totalPBXTrunk <= 1 ? true : false);
        let text = null;
        let name = null;
        if (this.props.totalPBXTrunk > 1) {
            text = $this.state.showForm ? 'Hide' : 'Show';
        }

        name = (
            <div className={`button-header ${(realShowForm ? 'button-header--open' : '')}`} onClick={(e) => this.toggle(e, $this)}>
                <h4>
                    {this.props.currentService.Name}
                </h4>
                &nbsp;
                <div className='pull-right util-inline-block'>
                    <div className='util-inline-block trunk-header-status'>
                        <TrunkRegistrationLight
                            service={this.props.currentService}
                        />
                    </div>
                    {text}
                </div>
            </div>
        );

        return (
            <>
                {name}
                {!$this.state.pbxTemplateLoaded && realShowForm &&
                    <p>Loading template data...</p>
                }
            </>
        );
    }

    render() {
        return <PBXTrunkServiceEdit
            {...this.props}
            render={$this => {

                let isFormDisabled = false;
                let formTitle = null;
                let service = $this.state.fullService;
                if (service && service.hasOwnProperty('AllowEditInUI') && service.AllowEditInUI == false) {
                    isFormDisabled = true;
                    formTitle = 'UI edits have been disabled for this Service';
                }

                return (
                    <>
                        {this.renderName($this)}
                        {$this.state.showForm && $this.state.pbxTemplateLoaded &&
                            <>
                                <fieldset title={formTitle}>
                                    <TrunkServiceForm
                                        currentService={$this.state.fullService}
                                        updateFullServiceTrigger={$this.updateFullServiceTrigger}
                                        pbxTemplate={$this.state.pbxTemplate}
                                        saveCallback={$this.saveService}
                                        saveText='Save'
                                        readonly={true}
                                    >
                                        <DefaultButton
                                        text={<i className='fa-solid fa-trash' />} disabled={isFormDisabled} onClick={$this.showModal}
                                        />

                                        <Dialog
                                            hidden={$this.state.hideDialog}
                                            onDismiss={$this.hideModal}
                                            dialogContentProps={{
                                                type: DialogType.normal,
                                                title: 'Delete Trunk',
                                                subText: 'This is a destructive action. All service users linked with this trunk will be deleted.'
                                            }}
                                            modalProps={{
                                                styles: { main: { maxWidth: 450 } },
                                            }}
                                        >
                                            <DialogFooter>
                                                <PrimaryButton onClick={$this.deleteService} text='Delete Trunk' />
                                                <DefaultButton onClick={$this.hideModal} text='Cancel' />
                                            </DialogFooter>
                                        </Dialog>
                                    </TrunkServiceForm>
                                </fieldset>
                            </>

                        }
                    </>
                );
            }}
        />
    }


}
export default TrunkServiceEdit;

