import React from 'react';
// Helpers used by both service and user screens for calling policy options

export function getVMPolicyOptions() {
    return [
        { label: 'Select a Value', value: '' },
        { label: 'Allow Voicemail', value: "VM" },
        { label: 'Prohibit Voicemail', value: "NoVM" },
        { label: 'Prohibit Voicemail & Call Forwarding', value: "NoVMOrForward" },
    ];
}

export function getMOHPolicyOptions(serviceName = 'PBX') {
    return [
        { label: 'Select a Value', value: '' },
        { label: 'Teams Hold Music', value: "MOHTEAMS" },
        { label: serviceName + ' Hold Music', value: "MOHEXT" },
    ];
}

export const getCallPolicyFormObject = (newValueCb) => {
    return {
        Id: "DefaultCallingPolicy",
        Label: 'Calling Policy',
        Type: 'checkbox',
        Group: 'CallingPolicy',
        CheckedLabel: 'Manage Teams Calling Policy',
        CheckedValue: 'custom',
        NewValueCB: newValueCb,
        VisibilityKey: 'CallingPolicy',
        HelpTitle: 'Calling Policy',
        Help: `You can leave this as is, to use the
               default policies for Teams Voicemail and
               Music-on-Hold, or you can amend these
               settings here to apply them to every user on this PBX`
    };
}

export function getCallPolicyVMFormObject(values) {
    return {
        Id: "VMCallingPolicy",
        Label: 'Teams Voicemail',
        Type: 'select',
        Group: 'CallingPolicy',
        Values: getVMPolicyOptions(),
        VisibilityKey: 'CallingPolicy',
    };
}

export function getCallPolicyMOHFormObject(values, serviceName) {
    return {
        Id: "MOHCallingPolicy",
        Label: 'Music On Hold',
        Type: 'select',
        Group: 'CallingPolicy',
        Values: getMOHPolicyOptions(serviceName),
        VisibilityKey: 'CallingPolicy',
    };
}
