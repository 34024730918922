import React, { useEffect, useState } from 'react';
import { Icon, Modal, DefaultButton } from 'office-ui-fabric-react';

export const useIsOnline = () => {

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const handleOffline = () => {
        setIsOnline(false);
    }

    const handleOnline = () => {
        setIsOnline(true);
    }

    useEffect(() => {
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);
        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('offline', handleOffline);
        }
    }, []);

    return { isOnline };
}

const Offline = () => {

    const { isOnline } = useIsOnline();

    if (isOnline) {
        return null;
    }

    return (
        <Modal
            isOpen={true}
            isBlocking={false}>
            <div className='p-3'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <Icon
                            iconName='PlugDisconnected'
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h3>You're not connected</h3>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h5>And the web just isn't the same without you. Let's get you back online!</h5>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <DefaultButton onClick={() => window.location.reload()}>Refresh</DefaultButton>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default Offline;
const SyncInterruptedHOC = (props) => {
    const [syncInterrupted, setSyncInterrupted] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const handleOffline = () => {
        setIsOnline(false);
        setSyncInterrupted(true);
    }

    const handleOnline = () => {
        setIsOnline(true);
    }

    useEffect(() => {
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);
        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('offline', handleOffline);
        }
    }, []);

    if (isOnline && syncInterrupted && props.syncRunning) {
        window.location.reload();
    }

    return props.children;

}
export { SyncInterruptedHOC };