import React, { Component } from 'react';
import {
    cmsGetPromise,
    cmsTempAuthRedirect,
    getErrMsg,
    getBaseUrl
} from '../..//CallMSAPI.js';
import { toast } from 'react-toastify';
import ExpandingTable from '../../ExpandingReactTable';
import { connect } from 'react-redux';

import dayjs from '../../dayjs.js';

class BillingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // Used by table
            loading: true,
            data: [],
            pageCount: -1,
            expanded: {},

        };

        this.fetchData         = this.fetchData.bind(this);
        this.getColumns         = this.getColumns.bind(this);
        this.handleBillRequest = this.handleBillRequest.bind(this);
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: this.props.contractAccount.Id,
            objectType: 'billingstatements',
        };

        if (apiParams.accountId === undefined) {
            return;
        }

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            if(sortSettings.Column === 'Bill Date'){
                apiParams['SortBy'] = 'BillDate';
            }
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        return cmsGetPromise(apiParams);
    }

    handleBillRequest(e, bill, isSnapshot, isSummary) {
        var self = this;
        // Makes a temp token (for query string) then direct the user straight to download URL
        // GET / v1 / accounts / { AccountId } / billingstatements / { BillingStatementId }
        // SubscriptionSnapshot true/false, if true excludes arrears
        var p = cmsTempAuthRedirect(self.props.contractAccount.Id);
        p.then(function (data) {
            var tempToken = data.UriReadyToken;
            var url = getBaseUrl() + '/accounts/' + self.props.contractAccount.Id + '/billingstatements/' + bill.Id;
            if (isSummary) {
                url += '/summary';
            }
            url += '?tempToken=' + tempToken;
            url += '&OwnerAccountId=' + self.props.baseAccount.Id;
            url += '&SubscriptionSnapshot=' + (isSnapshot ? "true" : "false");
            url += '&format=csv'; // Ensure result comes in as CSV
            window.location = url;
        }, function (err) {
            toast.error("Unable to generate token for billing download URL: " + getErrMsg(err));
        })
    }

    getColumns() {
        var self = this;
        var columns = [];

        columns.push(
            {
                id: "billdate",
                Header: 'Bill Date',
                accessor: function (bill) {
                    var t = dayjs.utc(bill.BillDate);
                    return (
                        <>
                        <span>{t.format("MMMM YYYY")}</span>
                        {bill.HasMultipleCurrencies ? <i className="fa-solid fa-triangle-exclamation" title="Bill has multiple currencies"></i> : null}
                        </>
                    );
                },
                maxWidth: 200,
                sortable: true,
                filterable: false
            }
        );

        columns.push(
            {
                className: "download-bill-col text-centre row downloadBilling-button-wrapper",
                id: "subscriptionamount",
                Header: <p className="text-left">Subscription Value</p>,
                accessor: function (bill) {
                    return (
                        <>
                            <div className=' col-sm-4 col-xs-12 text-right'>{bill.PrimaryCurrencyCode + ' ' + bill.PrimaryCurrencyAdvanceAmount.toFixed(2)}</div>
                            <div className="downloadBilling-button col-sm-8 col-xs-12 " >
                                <button className="btn" >
                                    <i className="fa-solid fa-download" style={{ color: '#337ab7' }}></i>
                                </button>
                                <div className="downloadBilling-dropdown">
                                    <button title={"Download subscription detail"} className="btn btn-link btn-link--faux" onClick={(e) => self.handleBillRequest(e, bill, true, false)}>Detail</button>
                                </div>
                            </div>
                        </>
                    );
                },
                maxWidth: 400,
                sortable: false,
                filterable: false
            }
        );

        columns.push(
            {
                className: "download-bill-col text-left row downloadBilling-button-wrapper",
                id: "advanceAmount",
                Header: <p className="col-sm-4 col-xs-12 text-centre">Total Bill</p>,
                accessor: function (bill) {
                    var figure = bill.PrimaryCurrencyAdvanceAmount + bill.PrimaryCurrencyArrearsAmount;
                    return (
                        <>
                            <div className='col-sm-4 col-xs-12 text-right'>{bill.PrimaryCurrencyCode + ' ' + figure.toFixed(2)}</div>
                            <div className="downloadBilling-button col-sm-8 col-xs-12 " >
                                <button className="btn" >
                                    <i className="fa-solid fa-download" style={{ color: '#337ab7'}}></i>
                                </button>
                                <div className="downloadBilling-dropdown">
                                    <button title={"Download detail bill"} className="btn btn-link btn-link--faux" onClick={(e) => self.handleBillRequest(e, bill, false, false)}>Detail</button>
                                    <button title={"Download summary bill"} className="btn btn-link btn-link--faux" onClick={(e) => self.handleBillRequest(e, bill, false, true)}>Summary</button>
                                </div>
                            </div>
                        </>
                    );
                },
                sortable: false,
                filterable: false
            }
        );

        return columns;
    }

    render() {
        var self = this;

        return (
            <ExpandingTable
                loadingText={"Loading..."}
                filters={self.state.filters}
                searchText={self.state.searchText}
                showPagination={true}
                noDataText={"No billing data available."}
                fetchData={self.fetchData}
                sortBy={
                    {
                        Column: "started",
                        SortDirection: 'Descending'
                    }
                }
                columns={self.getColumns()}
            />
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        baseAccount: account.baseAccount,
        contractAccount: account.contractAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingTable);
