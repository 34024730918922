import React, { Component } from 'react';
import BrandingTemplateForm from './BrandingTemplateForm';
import { cmsAddTemplate } from '../../CallMSAPI.js';
import { getNewTemplateFormData } from './BrandingTemplateAssembler.js';
import { connect } from 'react-redux';

class AddBrandingTemplateForm extends Component {
    constructor(props) {
        super(props);
    }

    getSubmitAction = (data, ok, error) => {
        var self = this;
        return cmsAddTemplate(
            self.props.match.params.accountId,
            data,
            ok,
            error)
    }

    render = () => {
        var self = this;
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <h1>Add {self.props.match.params.serviceVariantCode} Template</h1>
                        <p className="lead">This {self.props.match.params.serviceVariantCode} template will be visible
                        to {self.props.account.Name} and all descendant accounts.</p>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-info domain-form-wrapper">
                            <BrandingTemplateForm
                                initialValues={getNewTemplateFormData(self.props.match.params.serviceVariantCode)}
                                submitAction={self.getSubmitAction}
                                submitButtonText="Save"
                                leaveButtonText="Cancel"
                            />
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBrandingTemplateForm);