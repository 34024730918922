import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as actions from '../store/actions/index';
import { connect } from 'react-redux';
import './CallLogTools.css';
import CallsModal from '../ServiceUsers/CallsModal';

var _ = require('lodash');

const GettingStarted = (props) => {

    if (!props.hasRole('SystemUserSummary')) {
        return <h2><strong>404</strong> Page Not Found</h2>;
    }

    return (
        <>
            <h2>Call Log List</h2>

            <p>System owner view of all available recent call logs for <strong>{props.account.Name}</strong></p>

            <hr />

            <div>
                <CallsModal
                    NoModal={1}
                    ShowAdminColumns={1}
                />
            </div>
        </>
    );
}

const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount,
        services: state.services.rawList
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GettingStarted));