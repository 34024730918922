import React from 'react';
import AccountTrials from './Accounts/AccountTrials';

const Trials = ()=> {
    return (
        <>
            <div className='action-header'>
                <div className='action-header-text'>
                    <h1>Trials</h1>
                </div>
            </div>
            <AccountTrials
                closeButtonText='Close'
                submitButtonText='Save'
                limitSubscriptions={null}
            />
        </>
    );
}
export default Trials;