import React, { Component } from 'react';
import {
    getErrMsg,
    cmsAddServiceUserPromise,
    cmsEditServiceUserPromise, cmsEditServiceUserPartPromise,
    cmsDeleteUser, cmsDeleteUserPart,
    cmsToggleDisableUser,
    cmsGetCountriesPromise
} from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import ServiceUserForm from './ServiceUserForm';
import { connect } from 'react-redux';
import ServiceUserService from '../js-services/ServiceUserService.js';
import queryClient from '../indexApp.js';
import { showConsoleLogs } from '../CallMSUIHelpers.js';

const fastIsEqual = require("react-fast-compare");
var _ = require('lodash');

class EditServiceUserForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingCountry: true,
            part2Country: null
        };

        this.saveEditServiceUser = this.saveEditServiceUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (!fastIsEqual(this.props, nextProps) || !fastIsEqual(this.state, nextState));
    }

   UNSAFE_componentWillMount() {
        this.expandPart2CountryId();
    }

    expandPart2CountryId() {
        var self = this;
        if (this.props.user.Part2
            && this.props.user.Part2.CountryId
        ) {
            self.setState({
                part2Country: self.props.user.Part2.CountryId,
                loadingCountry: false
            });

        } else {
            self.setState({
                loadingCountry: false
            });
        }
    }


    saveEditServiceUser(formik, values, setSubmitting, setErrors, resetForm) {
        var self = this;

        var services = [values.Part1.Id, values.Part2.Id];
        services = services.sort();
        var servicesString = services.join("_");
        var queryKey = "fullServices_" + self.props.account.Id + "_" + servicesString;

        queryClient.removeQueries(queryKey, { exact: true });

        if (values['StateId']) {
            values['CountryId'] = '';
        }
        if (values.Part1['StateId']) {
            values.Part1['CountryId'] = '';
        }
        if (values.Part2['StateId']) {
            values.Part2['CountryId'] = '';
        }

        if (values['Part1'].hasOwnProperty('PhoneNumber') && values['Part2'].hasOwnProperty('PhoneNumber')
            && values['Part1'].PhoneNumber && values['Part2'].PhoneNumber
            && values['Part1'].PhoneNumber !== values['Part2'].PhoneNumber
        ) {
            if(showConsoleLogs()) {
                console.log("Dropping part1 out-dated phone number");
            }
            values['Part1'].PhoneNumber = null;
        }

        // Any parts that have an existing ID and appear to have more fields
        // available we store the SerivceUserPart updates to happen. These happen
        // after the main user set.
        var SUPUpdates = [];
        ['Part1', 'Part2'].forEach(function (part) {
            var s = _.find(self.props.services.rawList, function (s) { return s.Id === values[part].AccountServiceId });

            // ID could be user direct _or_ sync user part
            let id = null;
            if (values[part].SyncUserPart) {
                id = values[part].SyncUserPart.Id;
            }
            if (!id) {
                id = values[part].Id;
            }

            if (id && _.keys(values[part]).length > 1) {
                SUPUpdates.push({
                    supId: id,
                    values: _.clone(values[part])
                })
            }
        });

        // Now we get to the user level update.
        // For those existing service user parts (which we will update later), collapse down to just the ID
        values = ServiceUserService.hashServiceUserParts(values);
        values.LicenceState = self.props.user.LicenceState;

        var userUpdate = null;
        if (!self.props.user.Id || values.Part1 || values.Part2) {
            userUpdate = cmsAddServiceUserPromise( self.props.account.Id, values );
        } else {
            userUpdate = cmsEditServiceUserPromise( self.props.account.Id, self.props.user.Id, values );
        }

        userUpdate.then(
            function () {
                // Now we run through the stored up ServiceUserPart adjustments
                var partUpdatePromises = [];

                _.forEach(SUPUpdates, function (s) {
                    partUpdatePromises.push(
                        cmsEditServiceUserPartPromise(
                            self.props.account.Id,
                            s.values.AccountServiceId,
                            s.supId,
                            s.values
                        )
                    );
                });

                var allProm = Promise.all(partUpdatePromises);
                allProm.then(
                    function () {
                        toast.success("Service user successfully updated");
                        setSubmitting(false);
                        self.props.closeForm();
                    },
                    function (err) {
                        setSubmitting(false);
                        toast.error("Unable to save full user changes: " + getErrMsg(err));
                    }
                );

            }, function (err) {
                setSubmitting(false);
                toast.error("Unable to save the user: " + getErrMsg(err));
            }
        );
    }

    deleteUserErr = (err) => {
        toast.error(getErrMsg(err));
        this.props.userUpdateTrigger && this.props.userUpdateTrigger();
        this.props.closeForm();
    }

    deleteUser() {
        var self = this;
        if (self.props.user.Id === null) {
            // Need to delete each part instead
            var p1 = self.props.user.Part1;
            if (p1 && p1.AccountServiceId && p1.Id) {
                cmsDeleteUserPart(
                    self.props.account.Id, p1.AccountServiceId, p1.Id,
                    function () {
                        toast.success("User part deleted successfully");
                        self.props.userUpdateTrigger && self.props.userUpdateTrigger();
                        self.props.closeForm();
                    },
                    this.deleteUserErr
                );
            }

            var p2 = self.props.user.Part2;
            if (p2 && p2.AccountServiceId && p2.Id) {
                cmsDeleteUserPart(
                    self.props.account.Id, p2.AccountServiceId, p2.Id,
                    function () {
                        toast.success("User part deleted successfully");
                        self.props.userUpdateTrigger && self.props.userUpdateTrigger();
                        self.props.closeForm();
                    },
                    this.deleteUserErr
                );
            }
        } else {
            cmsDeleteUser(
                self.props.account.Id, self.props.user.Id,
                function () {
                    toast.success("User deleted successfully");
                    self.props.userUpdateTrigger && self.props.userUpdateTrigger();
                    self.props.closeForm();
                },
                this.deleteUserErr
            );
        }
    }

    toggleDisableUser(e) {
        if (e !== undefined) {
            e.preventDefault();
        }

        var self = this;

        if (self.props.user.Id === null) {
            toast.error("Cannot modify user without an ID");
        } else {
            cmsToggleDisableUser(
                self.props.account.Id,
                self.props.user,
                function () {
                    toast.success("User successfully updated.");
                    self.props.userUpdateTrigger && self.props.userUpdateTrigger();
                },
                function (err) {
                    toast.error(err);
                    self.props.userUpdateTrigger && self.props.userUpdateTrigger();
                }
            );
        }
    }

    render() {
        var self = this;

        var formWrapperClasses = "alert domain-form-wrapper";

        if (!self.props.services.loaded) {
            return <p>Loading services...</p>;
        }
        if (self.state.loadingCountry) {
            return <p>Loading country details...</p>;
        }

        var userWithCountry = _.cloneDeep(self.props.user);
        if (self.state.part2Country) {
            userWithCountry.Part2.CountryId = self.state.part2Country;
        }

        return (
            <div className="domainForm domainForm-table-cell">
                <div className={formWrapperClasses}>
                    <ServiceUserForm
                        submitButtonText={self.props.submitButtonText}
                        countries={self.props.countries}
                        user={userWithCountry}
                        submitCallback={self.saveEditServiceUser}
                        showDelete={true}
                        showParts={true}
                        deleteCallback={self.deleteUser}
                        showDisable={false}
                        disableToggleCallback={self.toggleDisableUser}
                        closeForm={self.props.closeForm}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const services = state.services;
    const account = state.account;
    return {
        account: account.account,
        services
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(EditServiceUserForm);
