import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as actions from '../store/actions/index';
import { connect } from 'react-redux';
import ReactFileReader from 'react-file-reader';
import {CallDebugOutput} from '../components/call/CallDebugOutput';
import { getExampleLogLists } from './SIPErrors.js';
import './CallLogTools.css';
import { Buffer } from 'buffer';

var _ = require('lodash');

const GettingStarted = (props) => {
    const [uploadedLogData1, setUploadedLogData1] = useState(null);
    const [uploadedLogData2, setUploadedLogData2] = useState(null);
    const [showCallLogError, setShowCallLogError] = useState(1);
    const [showSystemMessages, setShowSystemMessages] = useState(1);

    const toggleCallLogError = () => {
        setShowCallLogError(!showCallLogError);
    }

    const toggleSystemMessages = () => {
        setShowSystemMessages(!showSystemMessages);
    }
    
    const handleLogUploadFile1 = files => {
        setUploadedLogData1(fileToLog(files));
    }
    const handleLogUploadFile2 = files => {
        setUploadedLogData2(fileToLog(files));
    }
    const fileToLog = files => {
        // Work our way from base64 back to an array of JSON blobs...
        let myArray = files.base64.split(',');
        let bigJson = Buffer.from(myArray[1], 'base64').toString('ascii');
        let lines = bigJson.split('\n');
        let logs = [];
        lines.forEach(function(v,i) {
            try {
                logs.push(JSON.parse(v));
            } catch {
                // probably empty line or comment
            }
        });

        return logs;
    }

    if (!props.hasRole('SystemUserSummary')) {
        return <h2><strong>404</strong> Page Not Found</h2>;
    }

    return (
        <>
            <h2>Call Log Tools</h2>

            <hr />
            <h3>Log Viewer</h3>
            <div>
                <p>
                Upload and compare platform JSON call log files. The log files do not leave the browser.
                </p>

                <div className='call-log-compare-options'>
                    <strong>Display Options</strong>
                    <button className="btn" onClick={toggleCallLogError}>
                        {showCallLogError ? "Hide Log Expanded Errors" : "Show Log Expanded Errors"}
                    </button>
                    <button className="btn" onClick={toggleSystemMessages}>
                        {showSystemMessages? "Hide System Messages" : "Show System Messages"}
                    </button>
                </div>

                <table className="table table-call-log-compare">
                    <tr>
                        <th>Call 1</th>
                        <th>Call 2</th>
                    </tr>
                    <tr>
                        <td>
                            <ReactFileReader fileTypes={[".log",".json"]} handleFiles={handleLogUploadFile1} base64={true}>
                                <button className='btn'>Upload JSON Log - Call 1</button>
                            </ReactFileReader>
                        </td>
                        <td>
                            <ReactFileReader fileTypes={[".log",".json"]} handleFiles={handleLogUploadFile2} base64={true}>
                                <button className='btn'>Upload JSON Log - Call 2</button>
                            </ReactFileReader>
                        </td>
                    </tr>
                    <tr>
                        <td className="call-wrap-1">
                            <CallDebugOutput
                                logs={uploadedLogData1}
                                includeSysMessages={showSystemMessages}
                                includeExpandedMessages={showCallLogError}
                            />
                        </td>
                        <td>
                            <CallDebugOutput
                                logs={uploadedLogData2}
                                includeSysMessages={showSystemMessages}
                                includeExpandedMessages={showCallLogError}
                            />
                        </td>
                    </tr>
                </table>
            </div>

            <hr />

            <div>
                <h3>Demo Call List</h3>
                { getExampleLogLists(props.account) }
            </div>
        </>
    );
}

const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount,
        services: state.services.rawList
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GettingStarted));