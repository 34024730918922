//Services START
export const SERVICES_START = 'SERVICES_START';
export const SERVICES_SUCCESS = 'SERVICES_SUCCESS';
export const SERVICES_FAIL = 'SERVICES_FAIL';
//Services END

//Account START
export const ACCOUNT_START = 'ACCOUNT_START';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAIL = 'ACCOUNT_FAIL';
export const ACCOUNT_SWITCHING = 'ACCOUNT_SWITCHING';
//Account END