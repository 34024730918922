import React, { Component } from 'react';
import PBXServiceForm from './PBXServiceForm';
import PBXTrunkServiceEdit from './PBXTrunkServiceEdit';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';


class PBXServiceEdit extends Component {

    renderName = ($this) => {
        let realShowForm = ($this.state.showForm || this.props.totalPBXTrunk <= 1 ? true : false);
        let name = null;

        if (this.props.totalPBXTrunk > 1) {
            let text = $this.state.showForm ? 'Hide' : 'Show';

            name = (
                <>

                    <div className={`button-header ${(realShowForm ? 'button-header--open' : '')}`} onClick={$this.toggleExpand}>
                        <h4>{this.props.currentService.Name}</h4>
                        &nbsp; <span className='pull-right'>{text}</span>
                    </div>

                    

                </>
            );
        }


        return (
            <>
                {name}
                {!$this.state.pbxTemplateLoaded && realShowForm &&
                    <p>Loading template data...</p>
                }
            </>
        );
    }

    render() {
        return <PBXTrunkServiceEdit
            {...this.props}
            render={$this => {

                let isFormDisabled = false;
                let formTitle = null;
                let service = $this.state.fullService;
                if (service && service.hasOwnProperty('AllowEditInUI') && service.AllowEditInUI == false) {
                    isFormDisabled = true;
                    formTitle = 'UI edits have been disabled for this Service';
                }

                return (
                    <>
                        {this.renderName($this)}
                        {$this.state.showForm && $this.state.pbxTemplateLoaded &&

                            <>
                                <fieldset title={formTitle}>
                                    <PBXServiceForm
                                        currentService={$this.state.fullService}
                                        pbxTemplate={$this.state.pbxTemplate}
                                        saveCallback={$this.saveService}
                                        saveText='Save'
                                        updateFullServiceTrigger={$this.updateFullServiceTrigger}
                                    >
                                        <DefaultButton
                                            text={<i className='fa-solid fa-trash' />} onClick={$this.showModal} disabled={isFormDisabled}
                                        />

                                        <Dialog
                                            hidden={$this.state.hideDialog}
                                            onDismiss={$this.hideModal}
                                            dialogContentProps={{
                                                type: DialogType.normal,
                                                title: 'Delete PBX',
                                                subText: 'This is a destructive action. All service users linked with this PBX will be deleted.'
                                            }}
                                            modalProps={{
                                                styles: { main: { maxWidth: 450 } },
                                            }}
                                        >
                                            <DialogFooter>
                                                <PrimaryButton onClick={$this.deleteService} text='Delete PBX' />
                                                <DefaultButton onClick={$this.hideModal} text='Cancel' />
                                            </DialogFooter>
                                        </Dialog>
                                    </PBXServiceForm>
                                </fieldset>

                            </>

                        }
                    </>
                );
            }}
        />
    }
}
export default PBXServiceEdit;
