import React, { Component } from 'react';

import ReactHoverObserver from 'react-hover-observer';
import { RegistrationLight } from './RegistrationLight';
import { cmsGetPBXStatus } from '../CallMSAPI';

import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

class UserPBXRegistrationLight extends Component {
    constructor(props) {
        super(props);

        this.updateWrapper = this.updateWrapper.bind(this);
    }

    updateWrapper(okCb, errCb) {
        cmsGetPBXStatus(this.props.account.Id, this.props.User.AccountServiceId, this.props.User.Id)
            .then(
                function (result) {
                    if (result.data) {
                        okCb && okCb(result.data);
                    } else {
                        errCb && errCb('No data available.');
                    }
                }, function (err) {
                    errCb && errCb(err);
                }
            );
    }

    render() {
        var serviceDoRegistration = this.props.service && this.props.service.RegistrationEnabled ? this.props.service.RegistrationEnabled : null;
        var serviceUserPartId = this.props.User ? this.props.User.Id : null
        var canAccessUserPartRegistrationStatus = this.props.hasRole('UserPartRegistrationStatus');
        return (
            <RegistrationLight
                updateCb={this.updateWrapper}
                updateTime={this.props.updateTime}
                RegistrationStatus={this.props.User.RegistrationStatus}
                ServiceDoRegistration={serviceDoRegistration}
                service={this.props.service}
                serviceUserPartId={serviceUserPartId}
                accountId={this.props.account.Id}
                canAccessUserPartRegistrationStatus={canAccessUserPartRegistrationStatus}
            />
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserPBXRegistrationLight);