import React from 'react';
import { useState } from 'react';
import { TeachingBubble } from 'office-ui-fabric-react';

const SBCNameHelp = (props) => {

    let SBC = props.SBC;
    let IPAddress = props.IPAddress;
    let IPRange = props.IPRange;
    let IPAddressId = IPAddress;
    IPAddressId = IPAddressId.replace(/\./g, '_');

    let isRealRange = IPRange && !IPRange.includes('/32');

    const [showHelp, setShowHelp] = useState(false);

    return (
        <>
            <button className="btn btn-link btn--sbc-ui-list btn--faux-link" onClick={(e) => { e.preventDefault(); setShowHelp(!showHelp); return false; }}>
                <i className="fa-solid fa-circle-info" id={"SBCIPHelp_" + IPAddressId}></i>
            </button>
            {showHelp ?
                <TeachingBubble
                    target={'#SBCIPHelp_' + IPAddressId}
                    hasCondensedHeadline={true}
                    onDismiss={() => setShowHelp(false)}
                    hasCloseIcon={true}
                    closeButtonAriaLabel="Close"
                    headline={"SBC IP Addresses"}
                >
                    <>
                        <p>{SBC.toLowerCase()} uses IP address {IPAddress}.</p>

                        {isRealRange ? (
                            <p>This IP address should only be used for diagnostic
                            purposes. For firewall or network configurations use the
                            range {IPRange}</p>
                        ):null}

                        <p>If you filter SIP signalling traffic based on source IP
                        addresses you will need to add all of the listed {isRealRange ?
                                'IP ranges' : 'IP addresses'} to the appropriate
                        trusted list. Media traffic may originate from additional IP
                        addresses to those listed.</p>
                    </>
                </TeachingBubble>
                : null}
        </>
    );
};

export default SBCNameHelp;
