import React, { Component } from 'react';
import { connect } from "react-redux";
import * as actions from '../store/actions/index';
import { cmsGetTrunkStatus } from '../CallMSAPI';
import { RegistrationLight } from './RegistrationLight';
import ReactHoverObserver from 'react-hover-observer';

class TrunkRegistrationLight extends Component {
    constructor(props) {
        super(props);

        this.updateWrapper = this.updateWrapper.bind(this);
    }

    updateWrapper(okCb, errCb) {
        cmsGetTrunkStatus(this.props.account.Id, this.props.service.Id)
            .then(
                function (result) {
                    if (result.data) {
                        okCb && okCb(result.data);
                    } else {
                        errCb && errCb('No data available.');
                    }
                }, function (err) {
                    errCb && errCb(err);
                }
            );
    }

    render() {
        var canAccessUserPartRegistrationStatus = this.props.hasRole('UserPartRegistrationStatus');
        return (
            <ReactHoverObserver className="col-status-light">
                <RegistrationLight
                    service={this.props.service}
                    accountId={this.props.account.Id}
                    updateCb={this.updateWrapper}
                    // No starting registration status passed in, leave auto update/timer to fix this
                    RegistrationStatus={null}
                    ServiceDoRegistration={this.props.service.RegistrationEnabled}
                    canAccessUserPartRegistrationStatus={canAccessUserPartRegistrationStatus}
                />
            </ReactHoverObserver>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TrunkRegistrationLight);