import React, { useRef,useEffect, Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AcceptInvite from './AcceptInvite';
import AppSwitchScreen from './AppSwitchScreen';
import { isOnlyBrandContributor, LoadingSplash } from './CallMSUIHelpers';
import Home from './Home';
import { getUserName, triggerSwitchAccounts } from './MSALAuthProvider';
import NoMatch from './NoMatch';
import Portal from './Portal';
import AppAccounts from './AppAccounts';

import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import PbxKazoo from './pbxKazoo';


//Not sure what to really call this Component?
class Layout extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.loginAccount();
    }

    shouldComponentUpdate(nextProps)
    {
        return !nextProps.switchingAcc;
    }

    componentDidUpdate(prevProps) {
        this.handleUpdate(prevProps);
    }

    handleUpdate = (prevProps) => {
        this.props.handleChangedAccount(prevProps);
    }

    renderRoutesByRoles = () => {

        const notFound = <Route path='*' component={NoMatch} />

        const appAccounts = (
            <Route
                path='/accounts/'
                component={AppAccounts}
            />
        );

        return (
            <Switch>
                <Route
                    path='/'
                    exact
                    component={Home}
                />
                <Route path='/pbxkazoo' component={PbxKazoo} />
                {this.renderRouteAcceptInvite()}
                {appAccounts}
                <Route
                    path='/portal/:accountId'
                    component={Portal}
                />
                
                <Redirect to='/' path='/:base(accounts|portal)/:oldAccountId([0-9]{0,4})' />
                {notFound}
            </Switch>
        );
    }

    renderRouteAcceptInvite = () => {
        return <Route
            path='/accept'
            exact
            component={AcceptInvite}
        />
    }

    renderRoutes = () => {
        return this.renderRoutesByRoles();
    }

    renderIncorrectLogin = () => {

        const userName = getUserName();

        const switchAccount = (e) => {
            e.preventDefault();
            triggerSwitchAccounts();
        };

        const content = () => (
            <LoadingSplash>
                <h4>Unknown Account</h4>
                <p>
                    We can't find any accounts for user {userName}.
                        If you have not yet received an invite to
                        the service please contact your administrator.
                    </p>
                <hr />
                <button className='btn' onClick={switchAccount}>Log in with a different account</button>
            </LoadingSplash>
        );

        return (
            <Switch>
                {this.renderRouteAcceptInvite()}
                <Route component={content} />
            </Switch>
        );
    }

    //Not sure what to really call this method?
    renderAccountLogic = () => {

        if (this.props.incorrectLogin){
            return this.renderIncorrectLogin();
        }

        if (!this.props.loaded) {
            return <LoadingSplash text='Loading account data' />;
        }

        if (this.props.loaded && this.props.err.message) {
            throw new Error(this.props.err.message);
        }

        if (this.props.account.Id === '' && this.props.baseAccountsLoaded && this.props.loaded) {
            return (
                <div>
                    <AppSwitchScreen
                        text={[
                            `The base account ${this.props.baseAccount.Name} `,
                            `doesn't contain the requested account ID ${this.props.account.Id}`
                        ].join('')}
                    />
                </div>
            );
        }

        return this.renderRoutes();
    }

    render() {
        return (
            <div className='route-wrapper'>
                <ToastContainer
                    pauseOnFocusLoss={false}
                />
                {this.renderAccountLogic()}
            </div>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        loaded: account.loaded,
        baseAccountsLoaded: account.baseAccountsLoaded,
        err: account.err,
        account: account.account,
        baseAccount: account.baseAccount,
        incorrectLogin: account.incorrectLogin,
        switchingAcc: account.switchingAcc
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        loginAccount: () => dispatch(actions.loginAccount()),
        handleChangedAccount: (prevProps) => dispatch(actions.handleChangedAccount(prevProps)),
        switchBaseAccount: (baseId = '', accountId = '') => dispatch(actions.switchBaseAccount(baseId, accountId))
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));