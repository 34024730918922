import React, { Component } from 'react';
import { ErrorSplash, showErrorDebugInfo } from './CallMSUIHelpers';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { Router } from 'react-router-dom'
import { triggerLogout, getIdToken, getMsalConfig } from './MSALAuthProvider';
import { showConsoleLogs } from './CallMSUIHelpers';

/**
 * Setup application insights now that we have a key from the appSettings data
 */
// Get msal config stuff... it must be set to have got this far.
var msalConf = getMsalConfig();
var browserHistory = null;
if (msalConf && msalConf.app && msalConf.app.appInsightsInstrumentationKey) {
    browserHistory = createBrowserHistory({ basename: '' });
    var reactPlugin = new ReactPlugin();
    var appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: msalConf.app.appInsightsInstrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            },
            // appId: msalConf.clientId,
            // We run the API elsewhere
            enableCorsCorrelation: true,
            enableAjaxErrorStatusText: true,
            maxAjaxCallsPerView: 250,
            enableUnhandledPromiseRejectionTracking: true,
            enableAutoRouteTracking: true,
            isCookieUseDisabled: true
        }
    });
    appInsights.addTelemetryInitializer(envelope => {
        envelope.tags = envelope.tags || [];
        envelope.tags.push({ "ai.cloud.role": "portal" });
    });
    if(showConsoleLogs()) {
        console.log("Using IK: " + msalConf.app.appInsightsInstrumentationKey);
    }
    appInsights.loadAppInsights();
}


class ErrorReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errMsg: error.message, err: error };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
        // console.log("Error logging");
        // console.log(error);
        // console.log(info);
    }

    logOut(e) {
        e.preventDefault();
        triggerLogout();
    }

    render() {
        if (this.state.hasError) {
            var errMsg = "Error accessing '" + window.location.href + "'. ";
            if (this.state.errMsg) {
                errMsg += this.state.errMsg;
            } else {
                errMsg += "Please refresh the page and try again. If the problem persists contact your administrator.";
            }

            var stacktrace = null;
            if (this.state.err && this.state.err.hasOwnProperty('stack') && showErrorDebugInfo()) {
                stacktrace = (
                    <>
                    <hr />
                    <p><strong>Stack:</strong></p> <pre className="text-left">{this.state.err.stack}</pre>
                    </>
                );
            }

            var userDetails = null;
            var logout = null;

            if (showErrorDebugInfo()) {
                var user = getIdToken();
                if (user) {
                    logout = <button className="btn btn-link" onClick={(e) => this.logOut(e)}>Log out</button>;
                    userDetails = (
                        <>
                            <p><strong>User Details:</strong></p>
                            <pre className="text-left">{JSON.stringify(user, null, 4)}</pre>
                        </>
                    );
                } else {
                    userDetails = (
                        <pre className="text-left">No further user authorisation details are available.</pre>
                    );
                }
            }

            return (
                <ErrorSplash>
                    <h4>We've hit an unexpected error</h4>
                    <p>{errMsg}</p>
                    {logout}
                    {stacktrace}

                    {showErrorDebugInfo() && <hr />}
                    {userDetails}
                </ErrorSplash>
            );
        }

        return (
            <>
            <Router history={browserHistory}>
            {this.props.children}
            </Router>
            </>
       );
    }
}

export default withAITracking(appInsights, ErrorReport);
