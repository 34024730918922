import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { compareOBs } from '../CallMSUIHelpers';
import ExpandingTable from '../ExpandingReactTable';
import { connect } from 'react-redux';
import { showConsoleLogs } from '../CallMSUIHelpers';

class ServiceAuditFormWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            service: null,
            rawServices: [],
            prevService: null,
        };
        this.fetchData = this.fetchData.bind(this);
        this.calculateTableRows = this.calculateTableRows.bind(this);
    }
    calculateTableRows() {
        var self = this;
        let differences = [];

        const compareArea = (area, oldArea, specialCase) => {
            if (specialCase === 'SBCs') {
                differences.push(CreateSpecialCase(area, oldArea, 'SBC', CreateSBCEntry, 'SBCs'));
            } else if (specialCase == 'Ranges') {
                differences.push(CreateSpecialCase(area, oldArea, 'Id', CreateRange, 'Ranges'));
            } else if (specialCase == 'ServiceNumbers') {
                differences.push(CreateSpecialCase(area, oldArea, 'Id', CreateServiceNumber, 'Service Numbers'));
            } else if (specialCase == 'SyncSettings') {
                if (!compareOBs(area, oldArea, 'Key')) {
                    differences.push(CreateSpecialCase(area, oldArea, 'Key', CreateSyncSetting, 'Sync Settings'));
                }
            } else {

                if (area || oldArea) {

                    let hlKeys = area && Object.keys(area) ? Object.keys(area) : Object.keys(oldArea);
                    hlKeys.forEach(key => {
                        if (key != 'PBXSettings'
                            && key != 'TrunkSettings'
                            && key != 'Ranges'
                            && key != 'DedicatedIps'
                            && key != 'LicenceState'
                            && key != 'HasPassword'
                            && key != 'ServiceSyncModuleCode'
                            && key != 'ServiceSyncModuleId'
                            && key != 'SyncEnabled'
                            && key != 'SyncSettings'
                            && key != 'SyncRequired'
                            && key != 'SyncRequiresPerJobToken'
                            && key != 'ServiceNumbers'
                            && key != 'Id'
                            && key != 'SBCs'
                            && key != 'NumSBCs'
                            && key != 'NumActiveSBCs'
                            && key != 'NumRegistrationSBCs'
                            && key != 'AccountServiceId'
                            && key != 'AllowedCodecsString'
                            && key != 'AccountId'
                            && key != 'TeamsSettings'
                            && key != 'Variant'
                            && key != 'AccountPBXTemplateId'
                            && key != 'TeamsToPBXStateMapping'
                            && key != 'NumberRanges'
                            && key != 'ServiceNumbers') {

                            let newVal = null;
                            let oldVal = null;

                            if (oldArea
                                && (oldArea[key] != null && oldArea[key] != ''
                                    || (oldArea[key] && typeof oldArea[key] == 'object' && oldArea[key].length > 0))) {
                                oldVal = FormatValue(oldArea[key], key);
                            }
                            if (area
                                && (area[key] != null && area[key] != ''
                                    || (area[key] && typeof area[key] == 'object' && area[key].length > 0))) {
                                newVal = FormatValue(area[key], key);
                            }
                            if ((oldVal 
                                    && newVal
                                    && typeof oldVal == 'object'
                                    && !compareOBs(oldVal, newVal))
                                || (oldVal
                                    && newVal
                                    && typeof oldVal == 'string'
                                    && oldVal != newVal)
                                || (oldVal && !newVal)
                                || (!oldVal && newVal)) {
                                if (area && area[key] && typeof area[key] == 'object' && area[key].length > 0) {
                                    newVal = (
                                        <div>{area[key].map((it, i) => <div key={i}>{FormatValue(it, key)}</div>)}</div>
                                    );
                                }
                                if (oldArea && oldArea[key] && typeof oldArea[key] == 'object' && oldArea[key].length > 0) {
                                    oldVal = (
                                        <div>{oldArea[key].map((it, i) => <div key={i}>{FormatValue(it, key)}</div>)}</div>
                                    );
                                }

                                differences.push({
                                    fieldName: MapFieldName(key),
                                    oldValue: oldVal,
                                    newValue: newVal
                                });
                            }
                        }
                    });
                } else {
                    toast.error('An error occured parsing the service values');
                }
            }
        }

        switch (self.props.AuditAction) {
            case 'Created':
                compareArea(self.props.service, null, null);
                if (self.props.service.TrunkSettings != null) {
                    compareArea(self.props.service.TrunkSettings, null, null);
                    compareArea(self.props.service.TrunkSettings.Ranges, null, 'Ranges');
                    compareArea(self.props.service.TrunkSettings.ServiceNumbers, null, 'ServiceNumbers');
                }
                if (self.props.service != null && self.props.service.TeamsSettings != null) {
                    compareArea(self.props.service.TeamsSettings, null, null);
                    let syncSettings = self.props.service.SyncSettings.filter(x => (x.Key == 'GroupIdListNative' || x.Key == 'GroupIdListCE') && x.Value != null);
                    if (syncSettings && syncSettings.length > 0) {
                        compareArea(syncSettings, null, 'SyncSettings');
                    }
                }
                if (self.props.service != null && self.props.service.PBXSettings != null) {
                    compareArea(self.props.service.PBXSettings, null, null);
                    let currPBXSIPMap = FormatPBXToSipMappings(self.props.service.PBXSettings.TeamsToPBXStateMapping);
                    compareArea(currPBXSIPMap, null, null);
                }
                if (self.props.service.SBCs != null) {
                    compareArea(self.props.service.SBCs, null, 'SBCs');
                }
                break;
            case 'Modified':
                compareArea(self.props.service, self.props.prevService, null);
                if (self.props.service.TrunkSettings != null) {
                    compareArea(self.props.service.TrunkSettings, self.props.prevService.TrunkSettings, null);
                    compareArea(self.props.service.TrunkSettings.Ranges, self.props.prevService.TrunkSettings.Ranges, 'Ranges');
                    compareArea(self.props.service.TrunkSettings.ServiceNumbers, self.props.prevService.TrunkSettings.ServiceNumbers, 'ServiceNumbers');
                }
                if (self.props.service.TeamsSettings != null) {
                    compareArea(self.props.service.TeamsSettings, self.props.prevService.TeamsSettings, null);
                    let syncSettings = self.props.service.SyncSettings.filter(x => (x.Key == 'GroupIdListNative' || x.Key == 'GroupIdListCE') && x.Value != null);
                    let syncSettingsOld = self.props.prevService.SyncSettings.filter(x => (x.Key == 'GroupIdListNative' || x.Key == 'GroupIdListCE') && x.Value != null);
                    if ((syncSettings && syncSettings.length > 0) || (syncSettingsOld && syncSettingsOld.length > 0)) {
                        compareArea(syncSettings, syncSettingsOld, 'SyncSettings');
                    }
                }
                if (self.props.service.PBXSettings != null) {
                    compareArea(self.props.service.PBXSettings, self.props.prevService.PBXSettings, null);
                    let currentPBXSIPMap = FormatPBXToSipMappings(self.props.service.PBXSettings.TeamsToPBXStateMapping);
                    let prevPBXSIPMap = FormatPBXToSipMappings(self.props.prevService.PBXSettings.TeamsToPBXStateMapping);
                    compareArea(currentPBXSIPMap, prevPBXSIPMap, null);
                }
                if ((self.props.service.SBCs != null || self.props.prevService.SBCs != null) && !(!self.props.service.SBCs && !self.props.prevService.SBCs)) {
                    if (self.props.service.NumSBCs != self.props.prevService.NumSBCs
                        || self.props.service.NumActiveSBCs != self.props.prevService.NumActiveSBCs
                        || self.props.service.NumRegistrationSBCs != self.props.prevService.NumRegistrationSBCs
                        || !compareOBs(self.props.service.SBCs, self.props.prevService.SBCs, 'SBC')) {
                        compareArea(self.props.service.SBCs, self.props.prevService.SBCs, 'SBCs');
                    }
                }
                break;
            case 'Deleted':
                compareArea(null, self.props.prevService, null);
                if (self.props.prevService.TrunkSettings != null) {
                    compareArea(null, self.props.prevService.TrunkSettings, null);
                    compareArea(null, self.props.prevService.TrunkSettings.Ranges, 'Ranges');
                    compareArea(null, self.props.prevService.TrunkSettings.ServiceNumbers, 'ServiceNumbers');
                }
                if (self.props.prevService != null && self.props.prevService.TeamsSettings != null) {
                    compareArea(null, self.props.prevService.TeamsSettings, null);
                    let syncSettingsOld = self.props.prevService.SyncSettings.filter(x => (x.Key == 'GroupIdListNative' || x.Key == 'GroupIdListCE') && x.Value != null);
                    if (syncSettingsOld && syncSettingsOld.length > 0) {
                        compareArea(null, syncSettingsOld, 'SyncSettings');
                    }
                }
                if (self.props.prevService != null && self.props.prevService.PBXSettings != null) {
                    compareArea(null, self.props.prevService.PBXSettings, null);
                    let prevPBXSIPMap = FormatPBXToSipMappings(self.props.prevService.PBXSettings.TeamsToPBXStateMapping);
                    compareArea(null, prevPBXSIPMap, null);
                }
                if (self.props.prevService.SBCs != null) {
                    compareArea(null, self.props.prevService.SBCs, 'SBCs');
                }
                break;
        }

        return {
            data: {
                Results: differences,
                PageCount: 1,
                CurrentPage: 1
            }
        }
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;
        return new Promise(function(res, rej) {
            let results = self.calculateTableRows();
            res(results);
          });
    }

    render() {
        var self = this;

        return (
            <div className="inner-audit-table">

                <ExpandingTable
                    columns={[
                        {
                            id: "name",
                            Header: "Field",
                            accessor: function (d) {
                                return d.fieldName;
                            },
                            sortable: false,
                            filterable: false,
                        },
                        {
                            id: "oldValue",
                            Header: "Old Value",
                            accessor: function (d) {
                                return d.oldValue;
                            },
                            sortable: false,
                            filterable: false,
                        },
                        {
                            id: "newValue",
                            Header: "New Value",
                            accessor: function (d) {
                                return d.newValue
                            },
                            sortable: false,
                            filterable: false
                        }
                    ]
                    }
                    sortBy={{
                        Column: 'name',
                        SortDirection: 'Descending',
                    }}
                    fetchData={self.fetchData}
                    loadingText={"Loading..."}
                    noDataText={"You don't have any Service History."}
                    pageSizeOptions={[10, 20, 50]}
                    showPagination={true}
                    className="-striped -highlight"
                />
            </div>
        );
    }
}
const CreateSpecialCase = (area, oldArea, prop, method, key) => {
    let oldItems = [];
    let newItems = [];
    let oldArr = [];
    let newArr = [];
    if (oldArea && area) {
        for (let i = 0; i < area.length; i++) {
            let include = false;
            let item = area[i];
            let match = oldArea.find(x => x[prop] == item[prop]);
            if (match) {
                let isDifferent = !compareOBs(item, match, prop);
                if (isDifferent) { include = true; }
            } else {
                include = true;
            }
            if (include) {
                if (match && !oldItems.find(x => x[prop] == match[prop])) { oldItems.push(match); }
                if (!newItems.find(x => x[prop] == item[prop])) { newItems.push(item); }
            }
        }
        for (let i = 0; i < oldArea.length; i++) {
            let include = false;
            let item = oldArea[i];
            let match = area.find(x => x[prop] == item[prop]);
            if (match) {
                let isDifferent = !compareOBs(item, match, prop);
                if (isDifferent) { include = true; }
            } else {
                include = true;
            }
            if (include) {
                if (match && !newItems.find(x => x[prop] == match[prop])) { newItems.push(match); }
                if (!oldItems.find(x => x[prop] == item[prop])) { oldItems.push(item); }
            }
        }
        for (let i = 0; i < oldItems.length; i++) {
            oldArr.push(method(oldItems[i], i));
        }
        for (let i = 0; i < newItems.length; i++) {
            newArr.push(method(newItems[i], i));
        }
    } else if (oldArea && !area) {
        for (let i = 0; i < oldArea.length; i++) {
            oldArr.push(method(oldArea[i], i));
        }
    } else if (area && !oldArea) {
        for (let i = 0; i < area.length; i++) {
            newArr.push(method(area[i], i));
        }
    }
    return {
        fieldName: key,
        oldValue: oldArr.length > 0 ? oldArr : null,
        newValue: newArr.length > 0 ? newArr : null
    }
}
const CreateSyncSetting = (area, i) => {

    let groups = area.Value.split(',');
    let groupObs = [];
    groups.forEach(group => {
        let temp = group.split('=');
        groupObs.push({
            Name: temp[1],
            Id: temp[0]
        })
    })
    return (
        <div key={i}>
            <p><b>Sync Setting: </b> {area.Key}</p>
            {groupObs.map((entry, i) => {
                return (
                    <>
                        <p><b>Name: </b> {entry.Name}</p>
                        <br />
                    </>
                );
            })}
        </div>
    )
};
const CreateServiceNumber = (area, i) => (
    <div key={i}>
        <p><b>Display Name: </b> {area.DisplayName}</p>
        <p><b>Number: </b> {area.PhoneNumber}</p>
        <br />
    </div>
    );
const CreateRange = (area, i) => (
    <div key={i}>
        <p><b>Start: </b> {area.Start}</p>
        <p><b>End: </b> {area.End}</p>
        <p><b>Range Size : </b> {area.RangeSize}</p>
        <p><b>Number of Linked Users: </b> {area.NumLinkedUsers}</p>
        <br />
    </div>
    );
const CreateSBCEntry = (area, i) => (
    <div key={i}>
        <p><b>SBC: </b> {area.SBC}</p>
        <p><b>IPAddress: </b> {area.IPAddress}</p>
        <p><b>IPRange: </b> {area.IPRange}</p>
        <p><b>IsActive: </b> {JSON.stringify(area.IsActive)}</p>
        <p><b>IsRegistration: </b> {JSON.stringify(area.IsRegistration)}</p>
        <p><b>Port: </b> {area.Port}</p>
        <p><b>Protocol: </b> {area.Protocol}</p>
        <br />
    </div>
);
const FormatPBXToSipMappings = (stringValue) => {
    let pbxSipValues = JSON.parse(stringValue);
    let valResponse = {};

    if (pbxSipValues && pbxSipValues.length > 0) {
        pbxSipValues.forEach(function (entry) {
            var keys = Object.keys(entry);
            var statusName = keys.find(x => x != 'rejTeamsToPBXStateMappingectCalls');

            if (statusName === 'PresenceDelivery') {
                valResponse["PresenceDelivery"] = entry[statusName];
            } else {
                var fieldName = 'teams_' + statusName;
                let fieldType = fieldName + '_response';
                let responseVal = entry['rejectCalls'] ? entry['rejectCalls'] : null;
                let mappingVal = entry[statusName] ? entry[statusName] : null;
                if (responseVal) {
                    if (typeof (responseVal) == 'string' && responseVal.includes('{')) {
                        responseVal = JSON.parse(responseVal)
                    }
                }
                if (mappingVal) {
                    if (typeof (mappingVal) == 'string' && mappingVal.includes('{')) {
                        mappingVal = JSON.parse(mappingVal)
                    }
                }
                if (mappingVal) {
                    valResponse[fieldName] = mappingVal.value;
                }
                if (responseVal) {
                    valResponse[fieldType] = responseVal.value;
                }
            }
        })
    }
    return valResponse
}
const FormatValue = (value, key) => {
    if (key == 'AllowEditInUI'
        || key == 'EncryptMedia'
        || key == 'SuppressContactDataParam'
        || key == 'SupportsRefer'
        || key == 'EnforceProxy'
        || key == 'Emergency'
        || key == 'IsPresenceEnabled'
        || key == 'EnforceRegionCDRs') {
        return JSON.stringify(value)
    } else {
        return value
    }
};
const MapFieldName = (fieldName) => {
    if (fieldName.includes('_')) {
        let words = fieldName.split('_');
        let newWords = [];
        words.forEach(word => {
            newWords.push(word.charAt(0).toUpperCase() + word.slice(1));
        });
        let resp = newWords.join(' ');
        return resp;
    } else if (fieldName === 'CountryId') {
        return 'Country'
    } else if (fieldName === 'AllowEditInUI') {
        return 'Allow Edit In UI'
    } else if (fieldName === 'SupportsRefer') {
        return 'Supports Refer'
    } else if (fieldName === 'TransferMode') {
        return 'Transfer Mode'
    } else if (fieldName === 'SuppressContactDataParam') {
        return 'Suppress Contact Data Param'
    } else if (fieldName === 'E164Mode') {
        return 'E164 Mode'
    } else if (fieldName === 'UserAgent') {
        return 'User Agent'
    } else if (fieldName === 'SBCMode') {
        return 'Registration'
    } else if (fieldName === 'EncryptMedia') {
        return 'Encrypt Media'
    } else if (fieldName === 'AllowedIPs') {
        return 'Allowed IPs'
    } else if (fieldName === 'AllowedCodecs') {
        return 'Allowed Codecs'
    } else if (fieldName === 'CallingPolicy') {
        return 'Calling Policy'
    } else if (fieldName === 'PresenceDelivery') {
        return 'Presence Delivery'
    } else {
        return fieldName
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceAuditFormWrapper);