import React from 'react';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';

const Footer = props => {

    const renderFooterTxt = () => {
        var footerLink = props.getLinkUrl('footer-text');
        var secondFooter = props.getLinkUrl('footer-text-secondary');
        var subBrandText = props.getLinkUrl('footer-text-subbrand');
        var subBrandSecond = props.getLinkUrl('footer-text-subbrand-secondary');

        var footerText = null;
        if (footerLink || secondFooter) {

            footerText = (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {footerLink ? footerLink.URL && footerLink.Text ? <span style={{ padding: '0 15px'}}><a href={footerLink.URL}>{footerLink.Text}</a></span> : <span>{footerLink.Text}</span> : null}
                    {secondFooter ? secondFooter.URL && secondFooter.Text ? <span style={{ padding: '0 15px' }}><a href={secondFooter.URL}>{secondFooter.Text}</a></span> : <span>{secondFooter.Text}</span> : null}
                </div>
            );
        }
        if (subBrandText || subBrandSecond) {
            footerText = (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {subBrandText ? subBrandText.URL && subBrandText.Text ? <span style={{ padding: '0 15px' }}><a href={subBrandText.URL}>{subBrandText.Text}</a></span> : <span>{subBrandText.Text}</span> : null}
                    {subBrandSecond ? subBrandSecond.URL && subBrandSecond.Text ? <span style={{ padding: '0 15px' }}><a href={subBrandSecond.URL}>{subBrandSecond.Text}</a></span> : <span>{subBrandSecond.Text}</span> : null}
                </div>
            );
        }
        return footerText;
    }

    return (
        <div className="footer">
            <div>
                {renderFooterTxt()}
            </div>
        </div>
    );
}
export default connect(state => ({
    switchingAcc: state.account.switchingAcc
}), dispatch => ({
    getLinkUrl: (exLinkTypeName = '') => dispatch(actions.getLinkUrl(exLinkTypeName))
}))(Footer);
