import React, { Component } from 'react';
import {
    cmsGet, cmsPut, cmsDelete, getErrMsg,
    cmsGetSystemSubscriptionOffers 
} from '../CallMSAPI.js';
import { toast } from 'react-toastify';

var _ = require('lodash');

class SystemOffers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemOffers: [],
            ownOffers: [],
            showFullList: false
        };

        this.enableOffer = this.enableOffer.bind(this);
        this.disableOffer = this.disableOffer.bind(this);
    }

   componentDidMount() {
        this.getOwnOffers();
        this.getSystemOffers();
    }

    getSystemOffers() {
        var self = this;
        cmsGetSystemSubscriptionOffers(
            self.props.contract.CurrencyCode,
            function (data) {
                data.Results.forEach(function (o) {
                    if (!self.hasOwnProperty('input' + o.Id)) {
                        self['input'+o.Id] = React.createRef();
                    }
                });

                self.setState({
                    systemOffers: (data && data.Results ? data.Results : [])
                });
            }, function (err) {
                self.setState({
                    systemOffers: null
                });
            }
        );
    }


    getOwnOffers() {
        var self = this;
        cmsGet({
            accountId: this.props.targetAccount.Id,
            objectType: 'subscriptionoffers'
        }, function (data) {
            self.setState({
                ownOffers: data.Results
            });
        }, function (err) {
            self.setState({
                ownOffers: null
            });
        })
    }

    enableOffer(o) {
        var self = this;

        var extId = self["input" + o.Id].current.value;

        cmsPut({
            accountId: self.props.targetAccount.Id,
            objectType: 'subscriptionoffers',
            objectId: o.Id,
            ExternalId: extId
        }, function () {
            toast.success("Offer enabled");
            self.getSystemOffers();
            self.getOwnOffers();
        }, function (err) {
            toast.error("Cannot enable offer: " + getErrMsg(err));
        });

    }

    disableOffer(o) {
        var self = this;

        cmsDelete({
            accountId: self.props.targetAccount.Id,
            objectType: 'subscriptionoffers',
            objectId: o.Id
        }, function () {
            toast.success("Offer disabled");
            self.getSystemOffers();
            self.getOwnOffers();
        }, function (err) {
            toast.error("Cannot disable offer: " + getErrMsg(err));
        });
    }

    toggleFullList() {
        this.setState(prevState => ({
            showFullList: !prevState.showFullList
        }));
    }

    render() {
        var self = this;

        if (!self.state.systemOffers) {
            return null;
        }

        // Get all offers
        // List these with 'add' or 'delete' (if already available)

        var subRows = [];
        self.state.ownOffers.forEach(function (o) {
            subRows.push(self.offerToTr(o, false));
        });

        if (self.state.showFullList === true) {
            self.state.systemOffers.forEach(function (o) {
                // Attributes also available: "MinQuantity", "MaxQuantity", "IsAvailable", "Id"
                var found = _.find(self.state.ownOffers, { 'Id': o.Id });

                if (found) {
                    // Will have already been shown using ownOffers above
                    return;
                }

                subRows.push( self.offerToTr(o, true) );
            })
        }

        return (
            <>
            <h4>Subscription Offers</h4>
            <button className="btn btn-default" onClick={() => self.toggleFullList()}>
                {self.state.showFullList ? "Show Enabled Offers Only" : "Show All Available Offers"}
            </button>

            {subRows.length
                ? (
                    <table className="table">
                        <thead>
                            <tr>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Price</th>
                            <th>Term</th>
                            <th>Available?</th>
                            <th>External ID</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {subRows}
                        </tbody>
                    </table>
                )
                : <><p>No subscription offers to show.</p><hr /></>
            }
            </>
        );
    }

    offerToTr(o, isNew) {
        var self = this;
        var price = '';
        if (o.hasOwnProperty('UnitPrice') && o.UnitPrice !== null) {
            price = o.UnitPrice.toFixed(2);
        }
        return (
            <tr key={o.Id + "_" + (isNew ? "new" : "existing")}>
                <td>{o.Description ? o.Description : o.PlanName}</td>
                <td>{o.Code}</td>
                <td>{o.CurrencyCode} {price}</td>
                <td>{o.Term} {o.TermUnit}</td>
                <td>{o.IsAvailable ? "Yes" : "No"}</td>
                <td>
                    <input className="form-control" type="text" name={"externalId-" + o.Id}
                        defaultValue={o && o.ExternalId ? o.ExternalId : ''}
                        disabled={!isNew}
                        ref={self["input" + o.Id]}
                    />
                </td>
                <td>
                    {!isNew
                        ? <button className="btn btn-danger"
                            onClick={() => self.disableOffer(o)}
                        >Disable</button>
                        : <button className="btn btn-default" disabled={!o.IsAvailable}
                            onClick={() => self.enableOffer(o)}
                        > Enable</button>
                    }
                </td>
            </tr>
        );
    }
}

export default SystemOffers;
