import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cmsGetCallData } from '../CallMSAPI';
import CallSingle from './CallSingle';

const CallSingleWrapper = (props) => {

    const [state, setState] = useState({
        callLogData: null,
        callLogUser: null
    });

    useEffect(() => refetchCallData(), []);

    const refetchCallData = () => {
        cmsGetCallData(props.account.Id, props.match.params.callId, resp => {
            setState({
                ...state,
                callLogData: resp.callLogData,
                callLogUser: resp.callLogUser
            });
        }, (e, errGetDebugOutput) => {
            if (e.response && e.response.status && e.response.status === 404 && !errGetDebugOutput)
            {
                setState({
                    ...state,
                    callLogData: false,
                    callLogUser: null
                });
            }
            else if (errGetDebugOutput)
            {
                // No message to show, no debug output but the CallDebugOutput
                // component itself will already handle that with a neat message
                // rather than a confusing toast error
            }
            else
            {
                toast.error('Error has occured while retrieving a call log');
            }
        });
    }

    return <CallSingle
        callLogData={state.callLogData}
        callLogUser={state.callLogUser}
        refetchCallData={refetchCallData}
    />
}
export default withRouter(connect(state => ({
    account: state.account.account
}))(CallSingleWrapper));
