import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { cmsGetPhoneAppBranding, cmsSetPhoneAppBranding, cmsPostPromise, getErrMsg } from '../../CallMSAPI';
import { SubmitButton } from '../../FormHelpers';
import { toast } from 'react-toastify';
import PhoneAppBrandingIcon from './PhoneAppBrandingIcon';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';

class PhoneAppBranding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneAppData: null,
            isDraft: null,
            showHelp: false
        }
        this.getInitial = this.getInitial.bind(this);
        this.saveBranding = this.saveBranding.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
        this.dismissHelp = this.dismissHelp.bind(this);
    }

    toggleHelp() {
        this.setState(prevState => ({ showHelp: !prevState.showHelp }));
    }
    dismissHelp() {
        this.setState({ showHelp: false });
    }

    componentDidMount() {
        cmsGetPhoneAppBranding(this.props.account.Id).then(res => {
            if (res && res.data) {
                this.setState({
                    phoneAppData: res.data,
                    isDraft: false
                });
            }
        });
    }

    getInitial() {
        const defaults = {
            'AboutText': null,
            'ApplicationName': null,
            'IsPublished': true,
            'PrivacyUrl': null,
            'TermsUrl': null,
            'PhoneAppHeaderLogo': [],
            'PhoneAppSideBarLogo': [],
            'AutoReceptionistName': 'Auto Receptionist'
        };
        if (this.state.phoneAppData) {
            defaults['ApplicationName'] = this.state.phoneAppData.ApplicationName
                ? this.state.phoneAppData.ApplicationName
                : null;
            defaults['PrivacyUrl'] = this.state.phoneAppData.PrivacyUrl
                ? this.state.phoneAppData.PrivacyUrl
                : null;
            defaults['TermsUrl'] = this.state.phoneAppData.TermsUrl
                ? this.state.phoneAppData.TermsUrl
                : null;
            defaults['AboutText'] = this.state.phoneAppData.AboutText
                ? this.state.phoneAppData.AboutText
                : `Offering a completely native calling experience within Microsoft Teams, this app acts in conjunction with the native Teams client to:\n\n- Make and receive calls within Teams\n\n- Manage active calls\n\n- View, manage and call contacts directly from your contacts and call history\n\n- Perform call transfers, call merges, and convert calls into Teams meetings with other Teams users.\n\n`;
            if (defaults['AboutText']) {
                defaults['AboutText'] = defaults['AboutText'].replace(/\\n/g, '\n');
            }
            defaults['AutoReceptionistName'] = this.state.phoneAppData.AutoReceptionistName
                ? this.state.phoneAppData.AutoReceptionistName
                : 'Auto Receptionist';
        }
        return defaults;
    }

    saveBranding(values, setSubmitting, isDraft) {
        let self = this;
        let promises = [];

        let data = {
            'ApplicationName': values.ApplicationName,
            'isToBePublished': !isDraft,
            'AutoReceptionistName': values.AutoReceptionistName
        };

        if (values.AboutText) {
            data['AboutText'] = values.AboutText.replace(/(?:\r\n|\r|\n)/g, '\n');
        }

        if (values.PrivacyUrl != null) {
            data['PrivacyUrl'] = values.PrivacyUrl;
        }
        if (values.TermsUrl != null) {
            data['TermsUrl'] = values.TermsUrl;
        }
        if (values['PhoneAppHeaderLogo']) {
            data['ApplicationIcon'] = values['PhoneAppHeaderLogo'].replace(/^data:.+;base64,/, '');
        }
        if (values['PhoneAppSideBarLogo']) {
            data['SidebarIcon'] = values['PhoneAppSideBarLogo'].replace(/^data:.+;base64,/, '');
        }

        cmsSetPhoneAppBranding(self.props.account.Id, data).then((res) => {
            toast.success('Saved Successfully');
        }).catch((err) => {
            toast.error('Error: ' + err.message);
        }).finally(() => setSubmitting(false));

    }

    render() {
        let self = this;

        return (
            <Formik
                // Required so updates from getFormDefaults get propagated down to form
                enableReinitialize={true}
                initialValues={this.getInitial()}
                validate={values => {
                    let errors = {};

                    if (values.PrivacyUrl && values.PrivacyUrl != null) {
                        if (!/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(values.PrivacyUrl)) {
                            errors.PrivacyUrl = 'You must enter a valid URL';
                        }
                    }
                    if (values.TermsUrl && values.TermsUrl != null) {
                        if (!/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(values.TermsUrl)) {
                            errors.TermsUrl = 'You must enter a valid URL';
                        }
                    }
                    if(values.AboutText && values.AboutText != null) {
                        if(values.AboutText.replace(/(?:\r\n|\r|\n)/g, '\n').length > 4000){
                            errors.AboutText = 'The about text should be no longer than 4000 characters.';
                        }
                    }

                    return errors;
                }}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                    var formik = this;
                    setSubmitting(true);
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setSubmitting,
                    resetForm
                    /* and other goodies */
                }) => {

                    let disabled = false;

                    if (!values.ApplicationName || !values.AboutText || !values.PrivacyUrl || !values.TermsUrl
                        || (!values.PhoneAppHeaderLogo || values.PhoneAppHeaderLogo.length === 0)
                        || (!values.PhoneAppSideBarLogo || values.PhoneAppSideBarLogo.length === 0)
                        || (errors && errors.AboutText)
                        ) {
                        disabled = true;
                    }

                    return (
                        <div>
                            <form className="form update-api-key-form" onSubmit={handleSubmit}>
                                <fieldset disabled={isSubmitting}>
                                    {self.state.isDraft && <p className="branding-draft-warning"><strong>This has been saved as a draft and is not yet published.</strong></p>}
                                    <table className="table new-admin-invite-form">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <label>App Name</label>
                                                </th>
                                                <td>
                                                    <input type="text" className="form-control" name="ApplicationName" value={values.ApplicationName} onChange={handleChange} onBlur={handleBlur} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <label>Auto Receptionist Name</label>
                                                    <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); this.toggleHelp() }}>
                                                        <i className="fa-solid fa-question-circle" id="helpForAutoreceptionist"></i>
                                                    </button>
                                                    {self.state.showHelp ?
                                                        <TeachingBubble
                                                            target={'#helpForAutoreceptionist'}
                                                            hasCondensedHeadline={true}
                                                            onDismiss={() => self.dismissHelp()}
                                                            hasCloseIcon={true}
                                                            closeButtonAriaLabel="Close"
                                                            headline={'Auto Receptionist'}
                                                        >
                                                            <p>Changing this field will allow you to set the call forwarding details
                                                                for when an outbound call is initiated by a Call2Teams Go user,
                                                                so you should see the name configued here.</p>
                                                            <p> If no name is configured, the default value is 'Auto Receptionist' </p>
                                                        </TeachingBubble>
                                                        : null}
                                                </th>
                                                <td>
                                                    <input type="text" className="form-control" name="AutoReceptionistName" value={values.AutoReceptionistName} onChange={handleChange} onBlur={handleBlur} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>
                                                    <th>
                                                        <label>Privacy URL</label>
                                                    </th>
                                                    <td style={{ width: '100%', padding: '0px 12px' }}>
                                                        <input type="text" className="form-control" name="PrivacyUrl" value={values.PrivacyUrl} onChange={handleChange} onBlur={handleBlur} />
                                                        {touched['PrivacyUrl'] && errors['PrivacyUrl'] && <span className="error-message">{errors['PrivacyUrl']}</span>}
                                                    </td>
                                                </td>
                                                <td style={{ width: '50%' }}>
                                                    <th>
                                                        <label>Terms URL</label>
                                                    </th>
                                                    <td style={{ width: '100%', padding: '0px 12px' }}>
                                                        <input type="text" className="form-control" name="TermsUrl" value={values.TermsUrl} onChange={handleChange} onBlur={handleBlur} />
                                                        {touched['TermsUrl'] && errors['TermsUrl'] && <span className="error-message">{errors['TermsUrl']}</span>}
                                                    </td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>
                                                    <th>
                                                        <label>About Text</label>
                                                    </th>
                                                    <td>
                                                        <div style={{ maxWidth: 'fit-content' }}>
                                                            <small>This text should be written using Markdown. For guidance on how to do this, please see
                                                                <a target="blank" href="https://www.markdownguide.org/cheat-sheet/"> this page</a>
                                                            </small>
                                                        </div>
                                                        <textarea className="form-control" rows="10" name="AboutText" value={values.AboutText} onChange={handleChange} onBlur={handleBlur} />
                                                        {touched['AboutText'] && errors['AboutText'] && <span className="error-message">{errors['AboutText']}</span>}
                                                    </td>
                                                </td>
                                                <td style={{ width: '50%' }}>
                                                    <th>
                                                        <label>Preview</label>
                                                    </th>
                                                    <td>
                                                        <div style={{ maxWidth: 'fit-content', maxHeight: '500px', overflow: 'auto', padding: '6px 12px', wordBreak: 'break-word' }}>
                                                            <ReactMarkdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
                                                                {values.AboutText}
                                                            </ReactMarkdown>
                                                        </div>
                                                    </td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {self.state.isDraft != null &&
                                        <>
                                            <PhoneAppBrandingIcon
                                                objectType="branding/phoneAppLogoImage"
                                                header="Colour Icon"
                                                recommendations="The color version of your icon 
                                                    displays in most Teams scenarios and must be
                                                    192x192 pixels. Your icon symbol (96x96 pixels)
                                                    can be any color, but it must sit on a solid or
                                                    fully transparent square background"
                                                type="PhoneAppHeaderLogo"
                                                value={values["PhoneAppHeaderLogo"]}
                                                isDraft={self.state.isDraft}
                                                data={self.state.phoneAppData}
                                                errors={errors}
                                                setSubmitting={setSubmitting}
                                                setFieldValue={setFieldValue}
                                            />
                                            <PhoneAppBrandingIcon
                                                objectType="branding/phoneAppLogoImage"
                                                header="Outline Icon"
                                                recommendations="The icon must be 32x32 pixels. It can 
                                                    be white with a transparent background or transparent
                                                    with a white background (no other colors are permitted).
                                                    The outline icon should not have any extra padding
                                                    around the symbol."
                                                type="PhoneAppSideBarLogo"
                                                value={values["PhoneAppSideBarLogo"]}
                                                isDraft={self.state.isDraft}
                                                data={self.state.phoneAppData}
                                                errors={errors}
                                                setSubmitting={setSubmitting}
                                                setFieldValue={setFieldValue}
                                            />
                                        </>
                                    }
                                    <div className="row" style={{ paddingBottom: '8px' }}>
                                        <strong>
                                            <span style={{ float: 'right' }}>
                                                After publishing you will need to run a Sync for users to receive the updated branding.
                                            </span>
                                        </strong>
                                    </div>
                                    <SubmitButton className={"btn btn-primary pull-right"}
                                        isSubmitting={isSubmitting}
                                        disabled={disabled}
                                        onClick={() => {
                                            this.saveBranding(values, setSubmitting, false);
                                        }}
                                        style={{ marginRight: '10px' }}>
                                        Save and Publish
                                    </SubmitButton>
                                    {disabled && (!errors || errors && !errors.AboutText) &&
                                        <span className="error-message"
                                            style={{ float: 'right', paddingRight: '10px', paddingTop: '7px' }}>
                                            You must fill out all fields
                                        </span>}
                                </fieldset>
                            </form>
                        </div>
                    )
                }
                }

            </Formik >
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(PhoneAppBranding);