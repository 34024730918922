import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { getOrganisationGroups } from '../../MSGraphAPI';

const AzureADGroupSelect = (props) => {
    const loadOptions = async (input, _prevOptions, additional) => {
        // If we loading more optoins from last search then grab the previous link
        var nextUrl = null;
        if (additional && additional.hasOwnProperty('prevInput') && additional.prevInput === input) {
            if (additional.hasMoreLink) {
                nextUrl = additional.hasMoreLink;
            }
        }

        // Default page size of 10 is fine here..
        var filteredOptions = await getListOptions(input, nextUrl);

        return {
            options: filteredOptions.options,
            hasMore: filteredOptions.hasMoreLink ? true : false,
            additional: {
                prevInput: input,
                hasMoreLink: filteredOptions.hasMoreLink
            }
        };
    };

    const getListOptions = (filterName, nextUrl) => {
        return getOrganisationGroups(props.token, filterName, nextUrl).then(function (fullResponse) {
            var res = fullResponse.data.value;

            var dropdownOptions = [];
            for (var i = 0; i < res.length; i++) {
                var entry = res[i];
                dropdownOptions.push({
                    value: entry.id,
                    label: entry.displayName,
                });
            }

            return {
                options: dropdownOptions,
                hasMoreLink: fullResponse.data['@odata.nextLink']
            }
        });
    }

    const guidStrToOptions = (guids) => {
        var out = [];

        if (guids && typeof guids === 'string') {
            var all = guids.split(',');
            all.forEach(function (v) {
                var vv = v.split('=');
                out.push({ label: vv[1], value: vv[0] });
            });
        }

        return out;
    }

    const optionsToGuidStr = (val) => {
        var out = [];

        if (val) {
            val.forEach(function (v) {
                out.push(v.value + '=' + v.label)
            })
        }

        return out.join(',');
    }

    return (
        <AsyncPaginate
            id={props.name}
            debounceTimeout={1}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            loadOptions={loadOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            isMulti
            name={props.name}
            value={guidStrToOptions(props.value)}
            onChange={(opt) => {
                props.setFieldValue(props.name, optionsToGuidStr(opt));
            }}
        />
    );
}
export default AzureADGroupSelect;