import React, { Component } from 'react';
import { cmsAcceptToken } from './CallMSAPI.js';
import { Link, Redirect, withRouter } from 'react-router-dom'
import { ErrorSplash, LoadingSplash } from './CallMSUIHelpers';
import { getUserName } from './MSALAuthProvider';
import { showConsoleLogs } from './CallMSUIHelpers';
import { connect } from 'react-redux';
import * as actions from './store/actions/index.js';
var URLSearchParams = require('url-search-params');


class AcceptInvite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            tokenProcessed: false,
            tokenResult: '',
            timer: 5
        };

        this.startTimer = this.startTimer.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    startTimer() {
        var self = this;
        setTimeout(() => {
            if (self.state.timer > 0) {
                self.setState({ timer: self.state.timer - 1 }, self.startTimer)
            } else {
                self.redirect();
            }
        }, 1000)
    }

    redirect() {
        this.props.history.push({
            pathname: '/',
            search: ''
        });
    }

    componentDidMount() {
        var self = this;

        var urlParams = new URLSearchParams(window.location.search);
        var token = urlParams.get('token');

        if (token === false) {
            self.setState({
                error: 'A valid token was not found. Please contact your administrator.'
            })
            return;
        }

        cmsAcceptToken(
            token,
            function (result) {
                self.setState({
                    tokenProcessed: true,
                    tokenResult: result
                });

                setTimeout(function () {
                    self.redirect();
                    this.props.refreshAccount(() => {
                        self.redirect();
                    });
                }.bind(self), 2000);
                if(showConsoleLogs()) {
                    console.log("Token result");
                    console.log(result);
                }
            }, function (err) {
                var userName = getUserName();
                self.setState({ error: "Unable to accept token for " + userName + ": " + err });
                self.startTimer();
            }
        );

    }

    render() {
        const userName = getUserName();

       if (this.state.error !== false) {
           return (
               <ErrorSplash>
                   <p>{this.state.error}</p>
                   <p>Redirecting you in { this.state.timer }</p>
                   <p><Link to="/">Portal Home</Link></p>
               </ErrorSplash>
           );
        } else if (!this.state.tokenProcessed) {
           return <LoadingSplash text={"Processing Invitation for " + userName} />;
        } else {
           return (
               <LoadingSplash>
                   <h4>Invitation accepted for {userName}</h4>
                   <p>We are now redirecting you to the portal.</p>
               </LoadingSplash>
           );
        }
    }
}
const mapStateToProps = () => ({}); 
const mapDispatchToProps = (dispatch) => {
    return {
        refreshAccount: (cb = () => { }) => dispatch(actions.refreshAccount(cb))
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcceptInvite));
