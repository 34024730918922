export const CallLogType = {
    PBXToEdge: 'PBXToEdge',
    EdgeToPBX: 'EdgeToPBX',
    EdgeToApp: 'EdgeToApp',
    SIP: 'SIP',
    LOG: 'LOG'
}
export const Roles = {
    AccountOwner: 'Account Owner',
    SubscriptionContributor: 'Subscription Contributor',
    SubscriptionViewer: 'Subscription Viewer',
    ServiceContributor: 'Service Contributor',
    ServiceViewer: 'Service Viewer',
    BrandContributor: 'Brand Contributor',
    SyncContributor: 'Sync Contributor'
};