import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import { extractNewSubscriptionData } from '../CallMSAPI';
import OrderFormTable from './OrderFormTable';
import PlaceOrder from './PlaceOrder';
import AvailableServicesFormPart from './AvailableServicesFormPart';
import { SubmitButton } from '../FormHelpers';
import Select from 'react-select';
import CountryIdAsyncSelect from '../CountryIdAsyncSelect';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { isValidWord } from '../CallMSUIHelpers';

var _ = require('lodash');

class NewAccountFormInner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showFullService: false,
            showAddNameHelp: false,
            showAddReferenceHelp: false,
            openModal: false
        };

        this.toggleFullService = this.toggleFullService.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
        this.dismissHelp = this.dismissHelp.bind(this);
    }

    showModal() {
        this.setState({
            openModal: true
        })
    }

    hideModal(entry) {
        this.setState({
            openModal: false
        })
    }

    getFormDefaults() {
        var baseDefaults = {
            accountId: this.props.account.Id,
            CountryId: '',
            StateId: '',
            Name: '',
            ExternalId: '',
            AllowEditInUI: true,
            AvailableServiceVariants: [],
            AvailableServicesBase: [],
            InviteEmail: '',
            OrderReference: '',
            BrandId: '',
            Brands: [],
        };

        this.props.offers.forEach(function (o) {
            baseDefaults["quantity-" + o.Id] = 0;
        });


        // Default to parent account's country/state
        if (this.props.account
            && this.props.account.hasOwnProperty('CountryId')
            && this.props.account.hasOwnProperty('StateId')
        ) {
            baseDefaults['CountryId'] = this.props.account.CountryId ? this.props.account.CountryId : '';
            baseDefaults['StateId'] = this.props.account.StateId ? this.props.account.StateId : '';
        }

        if (this.props.account && this.props.account.hasOwnProperty('AvailableServices')) {
            _.orderBy(this.props.account.AvailableServices, ['Name']).forEach(function (s) {
                baseDefaults.AvailableServicesBase.push(s);
                s.Variants.forEach(function (v) {
                    baseDefaults[v.Id] = true;
                });
            });
        }

        if (this.props.brands) { 
            baseDefaults.Brands = _.map(this.props.brands, x => { return { label: x.Name, value: x.Id } });
        };

        return baseDefaults;
    }

    toggleFullService() {
        this.setState(prevState => ({
            showFullService: !prevState.showFullService
        }))
    }

    toggleHelp = (name) => {
        this.setState({ [name]: !this.state[name] });
    }

    dismissHelp = name => {
        this.setState({ [name]: false });
    }

    render() {
        var self = this;
        return (
            <Formik
                // Required so updates from getFormDefaults get propagated down to form
                enableReinitialize={true}
                initialValues={this.getFormDefaults()}
                validate={values => {
                    let errors = {};
                    // Ensure PBX username is set
                    if (!values.Name) {
                        errors.Name = 'You must enter an Account Name.';
                    } else if (!isValidWord(values.Name)) {
                        errors.Name = 'You must enter a valid Account Name.';
                    }

                    if (!values.CountryId && !values.StateId) {
                        errors.CountryId = 'You must select a country.';
                    }

                    if (values.ExternalId && !isValidWord(values.ExternalId)) {
                        errors.ExternalId = 'You must enter a valid Reference.';
                    }

                    return errors;
                }}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {

                    var formik = this;

                    // We make various mods to the data before submission, we
                    // don't want to fiddle the underlying data though.
                    var values = _.cloneDeep(originalValues);

                    if (values['StateId']) {
                        values['CountryId'] = '';
                    }

                    if (values.ExternalId === '') {
                        values.ExternalId = null;
                    }

                    // Collapse down country/states object to single ID value
                    if (values.StateId) {
                        values.CountryId = null;
                    } else {
                        values.StateId = null;
                    }

                    if (values.BrandId) {
                        values.BrandId = values.BrandId.value;
                    };

                    // Get selected account services into the required order
                    values.AvailableServicesBase.forEach(function (s) {
                        s.Variants.forEach(function (v) {
                            if (values.hasOwnProperty(v.Id)) {
                                if (values[v.Id]) {
                                    values.AvailableServiceVariants.push(v.Id);
                                }
                                delete values[v.Id];
                            }
                        })
                    });
                    delete values.AvailableServicesBase;
                    delete values.Brands;

                    self.props.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setSubmitting,
                    setErrors,
                    resetForm
                    /* and other goodies */
                }) => {
                    var serviceDisplay = <AvailableServicesFormPart {...self.props} values={values}
                        handleChange={handleChange} handleBlur={handleBlur} />;

                    var modal = (
                        <PlaceOrder
                            {...self.props}
                            isOpen={self.state.openModal}
                            formValues={_.cloneDeep(values)}
                            offers={_.cloneDeep(self.props.offers)}
                            orderCallback={(reference) => {
                                self.hideModal();
                                values['OrderReference'] = reference;
                                handleSubmit(
                                    values,
                                    setSubmitting,
                                    setErrors,
                                    resetForm
                                )
                            }}
                            closeCallback={self.hideModal}
                        />
                    );

                    var hasOffers = self.props.offers && self.props.offers.length;

                    return (
                        <div>
                            {modal}
                            <div className="row">
                                <form className="form w-100" onSubmit={handleSubmit}>
                                    <fieldset className="w-100 user-form-parts-wrapper form-padding" disabled={isSubmitting}>
                                        <h4 className="w-100">1) Account Details</h4>
                                        <div className="form-group">
                                            <label>Name</label>
                                            <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleHelp("showAddNameHelp"); return false; }}>
                                                <i className="fa-solid fa-question-circle" id={"AddNameHelp"}></i>
                                            </button>
                                            {self.state.showAddNameHelp ?
                                                <TeachingBubble
                                                    target={'#AddNameHelp'}
                                                    hasCondensedHeadline={true}
                                                    onDismiss={() => self.dismissHelp("showAddNameHelp")}
                                                    hasCloseIcon={true}
                                                    closeButtonAriaLabel="Close"
                                                    headline={"Name Help"}
                                                >
                                                    <p>The Name field is restricted to alphanumeric characters, punctuation, spaces and the special characters "|" and "+".</p>
                                                </TeachingBubble>
                                                : null}
                                            <input name="Name" data-testid="addAccountNameFieldId" type="text" className="form-control mb-2 mr-sm-2" value={values.Name} required={true} onChange={handleChange} onBlur={handleBlur} />
                                            <span className="error-message">{errors.Name && touched.Name && errors.Name}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Reference</label>
                                            <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleHelp("showAddReferenceHelp"); return false; }}>
                                                <i className="fa-solid fa-question-circle" id={"showAddReferenceHelp"}></i>
                                            </button>
                                            {self.state.showAddReferenceHelp ?
                                                <TeachingBubble
                                                    target={'#showAddReferenceHelp'}
                                                    hasCondensedHeadline={true}
                                                    onDismiss={() => self.dismissHelp("showAddReferenceHelp")}
                                                    hasCloseIcon={true}
                                                    closeButtonAriaLabel="Close"
                                                    headline={"Reference Help"}
                                                >
                                                    <p>The Reference field is restricted to alphanumeric characters, punctuation, spaces and the special characters "|" and "+".</p>
                                                </TeachingBubble>
                                                : null}
                                            <input name="ExternalId" data-testid="addAccountReferenceFieldId" type="text" className="form-control mb-2 mr-sm-2" value={values.ExternalId} onChange={handleChange} onBlur={handleBlur} />
                                            <span className="error-message">{errors.ExternalId && touched.ExternalId && errors.ExternalId}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Primary Country</label>
                                            <CountryIdAsyncSelect
                                                countryName="CountryId"
                                                countryValue={values.CountryId}
                                                stateName="StateId"
                                                stateValue={values.StateId}
                                                onChange={setFieldValue}
                                            />
                                            <span className="error-message">{errors.CountryId}</span>
                                            <span className="error-message">{errors.StateId}</span>
                                        </div>
                                        <div className="form-group px-3">
                                            <p>{serviceDisplay}</p>
                                        </div>
                                        {self.props.hasRole('AccountAddBranding') && values.Brands && values.Brands.length > 0
                                            ?
                                            <div className="form-group system-owner-action form-padding">
                                                <label>Brand <i className="fa-solid fa-user-secret" title="System Owner Only"></i></label>
                                                <Select
                                                    name="BrandId"
                                                    options={values.Brands}
                                                    className="select-dropdown"
                                                    onChange={function (opt) {
                                                        setFieldValue("BrandId", opt);
                                                    }}
                                                    value={values.BrandId}
                                                    getOptionLabel={option => option.label}
                                                    getOptionValue={option => option.value}
                                                />
                                            </div>
                                            : null}

                                        {hasOffers ? <h4 className="w-100">2) Order Subscriptions</h4> : null}
                                        <OrderFormTable
                                            values={values}
                                            okOffers={self.props.offers}
                                            setFieldValue={setFieldValue}
                                        />

                                        {self.props.showInvitePart
                                            ? (
                                                <div className="user-form-parts-wrapper">
                                                    <div className="w-100">
                                                        <h4>{hasOffers ? "3)" : "2)"} Invite Account Owner</h4>
                                                        <p>Whilst creating an account you can
                                                            optionally send an email invite for an
                                                            account owner. User invitations can
                                                            also be sent via the 'Account' screen
                                                            once setup is complete.
                                                        </p>
                                                    </div>
                                                    <div className="form-group form-padding">
                                                        <div className="w-100">
                                                            <label>Email Address</label>
                                                        </div>
                                                        <div className="w-100">
                                                            <Field type="email" data-testid="addAccountEmailFieldId" name="InviteEmail" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}


                                        <div className="user-form-action-buttons form-group-full">
                                            <SubmitButton className={"btn btn-primary mb-2 right-action-button"} data-testid="addAccountAddBtnId"
                                                isSubmitting={isSubmitting}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (!errors || _.isEmpty(errors)) {
                                                        // Prep as if we are ordering, they may have selected nothing in which case
                                                        // we don't want to show the order modal.
                                                        var changes = extractNewSubscriptionData(
                                                            _.map(self.props.offers, function (o) { if (o) { return o.Id; } }),
                                                            _.cloneDeep(values)
                                                        );
                                                        if (changes.length === 0) {
                                                            handleSubmit(
                                                                values, setSubmitting, setErrors, resetForm
                                                            );
                                                        } else {
                                                            self.showModal();
                                                        }

                                                    }
                                                }}>{this.submitButtonText()}</SubmitButton>
                                            <button className="btn right-action-button mb-2 close-form" data-testid="addAccountCancelBtnId" onClick={this.props.closeForm}>{this.closeButtonText()}</button>
                                        </div>

                                        {/* <!-- required due to floating-right action buttons --> */}
                                        <div className="clearfix"></div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    )
                }
                }

            </Formik>
        );
    }

    submitButtonText() {
        if (this.props.submitButtonText) {
            return this.props.submitButtonText;
        } else {
            return 'Add';
        }
    }

    closeButtonText() {
        if (this.props.closeButtonText) {
            return this.props.closeButtonText;
        } else {
            return 'Cancel';
        }
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewAccountFormInner);
