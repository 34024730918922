import React from 'react';
import ServiceSync from '../Services/ServiceSync';
import ActionHeader from '../ActionHeader.js';
import { connect } from 'react-redux';

const SyncContributor = (props) => {
    if (!props.loaded) {
        return <p>Loading service overview...</p>
    }
    if (props.loaded && props.services.length === 0) {
        return (
            <div className='Portal'>
                <ActionHeader headerText='Sync Contributor' />
                <hr />
                <div className='alert alert-info'>
                    There are no services enabled on your account.
                </div>
            </div>
        );
    }

    return (
        <ActionHeader
            headerText='Sync Administrator'
            subtitle='When instructed by your service provider, please click the sync button to the right and sign in when prompted.'
        >
            {props.services.length ? <ServiceSync /> : null}
        </ActionHeader>
    );
};
export default connect(state => ({
    account: state.account.account,
    loaded: state.services.loaded,
    services: state.services.rawList
}))(SyncContributor);