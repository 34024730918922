import React, { Component } from 'react';
import BrandingTemplateForm from './BrandingTemplateForm';
import { getErrMsg, cmsGetPBXTemplate, cmsGetPBXTemplateHistory, cmsEditTemplate, cmsDeleteTemplate } from '../../CallMSAPI.js';
import { getExistingTemplateFormData, getTemplateHistorySelectOptions } from './BrandingTemplateAssembler.js';
import { toast } from 'react-toastify';

class EditBrandingTemplateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pbxTemplate: null,
            pbxTemplateHistory: null,
            submitButtonText: "Save",
            selectedPBX: null
        };

    }

    componentDidMount() {
        this.getPBXTemplate();
        this.getPBXTemplateHistory();
    }

    getSubmitAction = (data, ok, error) => {
        var self = this;
        return cmsEditTemplate(
            self.props.match.params.accountId,
            self.state.pbxTemplate.Id,
            data,
            ok,
            error)
    }

    getDeleteAction = (ok, error) => {
        var self = this;
        return cmsDeleteTemplate(
            self.props.match.params.accountId,
            self.state.pbxTemplate.Id,
            ok,
            error)
    }

    getPBXTemplate = () => {
        var self = this;

        cmsGetPBXTemplate(
            self.props.match.params.accountId,
            self.props.match.params.templateId,
            function (response) {
                self.setState({
                    pbxTemplate: response,
                    selectedPBX: response
                });
            },
            function (error) {
                toast.error("Unable to get the required service template: " + getErrMsg(error));
                self.setState({
                    pbxTemplate: null,
                    selectedPBX: null
                });
            }
        );
    }

    getPBXTemplateHistory = () => {
        var self = this;

        cmsGetPBXTemplateHistory(
            self.props.match.params.accountId,
            self.props.match.params.templateId,
            function (response) {
                if (response && response.data && response.data.Results) {
                    self.setState({
                        pbxTemplateHistory: response.data.Results
                    });
                }
            },
            function (error) {
                toast.error("Unable to get the required history for service template: " + getErrMsg(error));
                self.setState({
                    pbxTemplateHistory: null
                });
            }
        );
    }
    
    selectPBXTemplate = (id, success) => {
        var self = this;
        var template = null;
        var buttonText = "Save";

        if (self.state.pbxTemplate && self.state.pbxTemplate.Id && self.state.pbxTemplate.Id == id) {
            template = self.state.pbxTemplate;
        } else {
            if (self.state.pbxTemplateHistory) {
                var histTemplate = self.state.pbxTemplateHistory.find(x => x.HistoryId.toString() === id);
                if (histTemplate) {
                    template = histTemplate;
                    buttonText = "Restore";
                }
            }
        }

        self.setState({ selectedPBX: template, submitButtonText: buttonText });
    }

    render = () => {
        var self = this;
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <h1>Edit Template</h1>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-info domain-form-wrapper">
                            {self.state.selectedPBX
                                ? <BrandingTemplateForm
                                    initialValues={getExistingTemplateFormData(self.state.selectedPBX)}
                                    submitAction={self.getSubmitAction}
                                    submitButtonText={self.state.submitButtonText}
                                    leaveButtonText="Close"
                                    deleteAction={self.getDeleteAction}
                                    pbxTemplateHistory={getTemplateHistorySelectOptions(self.state.pbxTemplate, self.state.pbxTemplateHistory)}
                                    selectPBXTemplate={self.selectPBXTemplate}
                                    templateInUse={self.state.selectedPBX.InUse}
                                />
                                : <p>Template loading...</p>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default EditBrandingTemplateForm;