import 'react-app-polyfill/ie11';
import { setMsalConfig } from './MSALAuthProvider';
import { getBaseUrl, cmsGetPublic } from './CallMSAPI.js';

cmsGetPublic(
    {
        objectType: 'appSettings'
    },
    function (data) {
        setMsalConfig(data);

        // eslint-disable-next-line
        require('./indexApp.js');
    }, function (error) {
        alert("Error accessing the appSettings via " + getBaseUrl() + " for portal: " + error);
    }
);

