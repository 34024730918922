import React, { Component } from 'react';
import { connect } from 'react-redux';
import FieldWithDisposition from './FieldWithDisposition';


class MicrosoftAdvancedSyncOptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAdvanced: false,
            showO365Help: false
        };

    }

    toggleShow(e) {
        e.preventDefault();
        this.setState(prevState => ({ showAdvanced: !prevState.showAdvanced }));
    }

    toggleO365Help() {
        this.setState(prevState => ({
            showO365Help: !prevState.showO365Help
        }) );
    }

    dismissO365Help() {
        this.setState({
            showO365Help: false
        })
    }

    render() {
        var self = this;

        var arrowClass = "fa-solid fa-arrow-down";
        var showAdvancedDiv = null;

        var obj = {
            Id: 'PSNamePrefix',
            Label: 'Tenant Configuration Prefix',
            ViewOrder: 50,
            Type: 'text',
            readOnly: self.props.readOnly ? true : false,
            placeholder: self.props.PSNamePrefixPlaceholder ? self.props.PSNamePrefixPlaceholder : null,
            HelpTitle: 'Tenant Configuration Prefix',
            Help: (
                <>
                    <p>
                        The default value should be used unless you have
                        multiple {self.props.account.Brand} accounts connected
                        to a single Microsoft Office 365 account, in which case
                        you should choose a different tenant configuration prefix
                        for each account
                    </p>
                </>
                )
        };

        var psUsageObj = {
            Id: 'PSUsageLocations',
            Label: 'Teams PowerShell Usage Locations',
            ViewOrder: 50,
            Type: 'text',
            readOnly: false,
            HelpTitle: 'Teams PowerShell Usage Locations',
            Help: (
                <>
                    <p>Changing this field may result in unintended consequences.</p>
                    <p>This field can be used to filter retrieved user data in the 
                    sync process with Microsoft and is only suitable for advanced 
                    Large Tenant instances.</p>
                </>
            )
        };

        var showPSNamePrefix = false;
        if (self.props.values.PSNamePrefix || self.props.readOnly === false) {
            showPSNamePrefix = true;
        }

        if (self.state.showAdvanced) {
            arrowClass = "fa-solid fa-arrow-up";
            showAdvancedDiv = (
                <div className="ms-advanced-option-dropdown">
                    {showPSNamePrefix ?
                        <div>
                            <FieldWithDisposition
                                visibility={self.props.readOnly ? 'ViewOnly' : 'LockedEditOptional'}
                                formObject={obj}
                                formValues={self.props.values}
                                errors={self.props.errors}
                                touched={self.props.touched}
                                setFieldValue={self.props.setFieldValue}
                                account={self.props.account}
                            />
                        </div>
                        : null}
                        <br />
                        <div>
                            <FieldWithDisposition
                                visibility={'LockedEditOptional'}
                                formObject={psUsageObj}
                                formValues={self.props.values}
                                errors={self.props.errors}
                                touched={self.props.touched}
                                setFieldValue={self.props.setFieldValue}
                                account={self.props.account}
                            />
                        </div>
                </div>
            );
        }

        return (
            <div className="ms-advanced-sync-options">
                <button className="btn btn-link" onClick={(e) => self.toggleShow(e)}>
                    Show Advanced Options <i className={arrowClass}></i>
                </button>
                {showAdvancedDiv}
            </div>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MicrosoftAdvancedSyncOptions);