import React, { Component } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, Modal, PrimaryButton } from "@fluentui/react";
import AzureADGroupSelect from "./AzureADGroupSelect";
import { getAccessToken, GroupReadScope } from '../../MSGraphAPI';

const _ = require('lodash');

export class AzureADGroupSelectWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            token: null,
            startingNativeGroups: '',
            startingCEGroups: '',
            showWarning: false,
            missingNativeNames: [],
            missingCENames: [],
        };

        this.parseStringvalue = this.parseStringvalue.bind(this);
        this.showModal = this.showModal.bind(this);
        this.checkAndHideModal = this.checkAndHideModal.bind(this);
        this.toggleHideDialog = this.toggleHideDialog.bind(this);
        this.hideWithReset = this.hideWithReset.bind(this);
        this.realHideAll = this.realHideAll.bind(this);
    }

    toggleHideDialog() {
        this.setState({ showWarning: !this.state.showWarning });
    }

    hideWithReset() {
        var self = this;
        self.props.setFieldValue(
            'GroupIdListNative',
            self.state.startingNativeGroups
        )
        self.props.setFieldValue(
            'GroupIdListCE',
            self.state.startingCEGroups
        )
        this.realHideAll();
    }

    showModal() {
        this.setState({
            showModal: true,
            // Each time modal is shown, stash the starting groups so we can use in warning
            startingNativeGroups: this.props.groupIdListNative,
            startingCEGroups: this.props.groupIdListCE,
            missingCENames: null,
            missingNativeNames: null
        });

        this.getToken();
    }

    checkAndHideModal() {
        var self = this;

        if (this.state.startingNativeGroups) {
            var prev = self.buildGuidHash(this.state.startingNativeGroups);
            var now = self.buildGuidHash(this.props.groupIdListNative);
            let missingNames = [];
            prev.guids.forEach(guid => {
                if(now.guids.indexOf(guid) < 0) {
                    if (prev.keyed && prev.keyed.hasOwnProperty(guid)) {
                        missingNames.push(prev.keyed[guid]);
                    }
                }
            })
            if(missingNames.length === 0) {
                this.realHideAll();
            } else {
                this.setState({
                    missingNativeNames: missingNames
                }, function () {
                    this.toggleHideDialog();
                });
            }
        }
        if (this.state.startingCEGroups) {
            var prev = self.buildGuidHash(this.state.startingCEGroups);
            var now = self.buildGuidHash(this.props.groupIdListCE);
            let missingNames = [];
            prev.guids.forEach(guid => {
                if(now.guids.indexOf(guid) < 0) {
                    if (prev.keyed && prev.keyed.hasOwnProperty(guid)) {
                        missingNames.push(prev.keyed[guid]);
                    }
                }
            })
            if(missingNames.length === 0) {
                this.realHideAll();
            } else {
                this.setState({
                    missingCENames: missingNames
                }, function () {
                    this.toggleHideDialog();
                });
            }

            return;
        }

        this.realHideAll();
    }

    realHideAll() {
        this.setState({
            showWarning: false,
            showModal: false,
            token: null
        });
    }

    buildGuidHash(str) {
        var keyed = [];
        var guids = [];

        if (str && typeof str === 'string') {
            var vv = str.split(',');
            vv.forEach(function (v) {
                var el = v.split('=');
                keyed[el[0]] = el[1];
                guids.push(el[0]);
            })
        }

        return {
            guids: guids,
            keyed: keyed
        }
    }

    parseStringvalue(val) {
        var fullresponseString = [];
        var entryResponseString = [];
        if (val.includes(',')) {
            fullresponseString = val.split(',');
        } else {
            fullresponseString.push(val)
        }
        for (var i = 0; i < fullresponseString.length; i++) {
            var entryName = fullresponseString[i].split('=')[1];
            entryResponseString.push(entryName);
        }

        return entryResponseString.join(', ');
    }

    getToken() {
        var self = this;
        var service = this.props.service;

        var syncSettings = {
            SyncClientId: null,
            TenantId: 'common'
        };

        if (service && service.SyncSettings) {
            var tenantId = _.find(service.SyncSettings, { Key: 'TenantId' });
            if (tenantId && tenantId.Value) {
                syncSettings.TenantId = tenantId.Value;
            }
        }

        var reqArg = {
            SyncModuleDefaultSettings: syncSettings,
            additionalScope: GroupReadScope
        };

        return getAccessToken(reqArg, null, null).then(function (tok) {
            self.setState({
                token: tok.accessToken
            });
        });
    }

    render() {
        var self = this;
        var stringPlaceholderNative = "Click to load";
        var groupNativeText = "No groups set."
        var stringPlaceholderCE = "Click to load";
        var groupCEText = "No groups set."
        if (self.props.groupIdListNative && typeof self.props.groupIdListNative === 'string') {
            stringPlaceholderNative = self.parseStringvalue(self.props.groupIdListNative);
            groupNativeText = stringPlaceholderNative
        }
        if (self.props.groupIdListCE && typeof self.props.groupIdListCE === 'string') {
            stringPlaceholderCE = self.parseStringvalue(self.props.groupIdListCE);
            groupCEText = stringPlaceholderCE
        }

        var missingNames = '';
        if (self.state.missingNativeNames && self.state.missingNativeNames.length > 0) {
            missingNames += '"' + self.state.missingNativeNames.join(', ') + '" ';
        }
        if (self.state.missingCENames && self.state.missingCENames.length > 0) {
            missingNames += '"' + self.state.missingCENames.join(', ') + '" ';
        }


        const dialogContentProps = {
            type: DialogType.normal,
            title: 'User Impacting Group Change',
            closeButtonAriaLabel: 'Close',
            subText: 'Groups ' + missingNames + 'have been removed which will result in an impact to user\'s service. Are you sure you want to continue?'
        };

        return (
            <>
                <label
                    className="ms-Label ms-Toggle-label ms-Toggle--faux-label"
                    htmlFor='chooseGroupsBtn'
                >
                    Azure AD Groups
                </label>
                <div>
                    {self.props.legacySyncGroupInfo
                        ? self.props.legacySyncGroupInfo
                        : <p>
                            {groupNativeText}
                            &nbsp;
                            &nbsp;
                            <button
                                id='chooseGroupsBtn'
                                type="button"
                                className="btn btn-link btn-link--faux me-2"
                                onClick={self.showModal}
                                disabled={self.state.showModal}
                            >
                                Choose Groups
                            </button>
                        </p>
                    }
                    <span className="error-message"></span>
                </div>
                <div>
                </div>
                {self.props.isCallingEssentialsEnabled &&
                    <>
                        <label
                            className="ms-Label ms-Toggle-label ms-Toggle--faux-label"
                            htmlFor='choosePhoneAppGroupsBtn'
                        >
                            Azure AD Phone App Groups
                        </label>
                        <div>
                            {self.props.legacySyncGroupInfo
                                ? self.props.legacySyncGroupInfo
                                : <p>
                                    {groupCEText}
                                    &nbsp;
                                    &nbsp;
                                    <button
                                        type="button"
                                        id='choosePhoneAppGroupsBtn'
                                        className="btn btn-link btn-link--faux me-2"
                                        onClick={self.showModal}
                                        disabled={self.state.showModal}
                                    >
                                        Choose Groups
                                    </button>
                                </p>
                            }
                            <span className="error-message" />
                        </div>
                        <div/>
                    </>
                }

                <Dialog
                    hidden={!self.state.showWarning}
                    onDismiss={self.toggleHideDialog}
                    dialogContentProps={dialogContentProps}
                >
                    <div className="alert alert-warning push-top">
                        <i className="fa-solid fa-triangle-exclamation"></i>
                        &nbsp;Removing a group will cause users in that group to be deleted during the next sync.
                    </div>
                    <DialogFooter>
                        <PrimaryButton onClick={self.realHideAll} text="Yes" />
                        <DefaultButton onClick={self.hideWithReset} text="Restore Groups" />
                    </DialogFooter>
                </Dialog>

                <Modal
                    isOpen={self.state.showModal}
                    onDismiss={self.hideWithReset}
                    isBlocking={false}
                    style={{ minWidth: '80%', minHeight: '400px', overflow: 'visible' }}
                    className="allow-overflow-modal"
                >
                    <div className="ranges-table-wrapper">
                        <p>Choose the groups which contain the users you wish to sync in to the platform:</p>
                        {self.state.token ?
                            <>
                                <label htmlFor='GroupIdListNative'>Azure AD Groups</label>
                                <AzureADGroupSelect
                                    value={self.props.groupIdListNative}
                                    service={self.props.service}
                                    setFieldValue={self.props.setFieldValue}
                                    name={'GroupIdListNative'}
                                    token={self.state.token}
                                />
                                {self.props.isCallingEssentialsEnabled ?
                                    <>
                                        <br />
                                        <label htmlFor='GroupIdListCE'>Azure AD Phone App Groups</label>
                                        <AzureADGroupSelect
                                            value={self.props.groupIdListCE}
                                            service={self.props.service}
                                            setFieldValue={self.props.setFieldValue}
                                            name={'GroupIdListCE'}
                                            token={self.state.token}
                                        />
                                    </>
                                    : null}
                            </> : <span>Unable to get access token for groups.</span>
                        }

                        <hr />
                        <DialogFooter>
                            <button
                                type="button"
                                className="btn btn-primary ms-2"
                                onClick={self.checkAndHideModal}
                                disabled={!self.state.showModal}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-default ms-2"
                                onClick={self.hideWithReset}
                                disabled={!self.state.showModal}
                            >
                                Cancel
                            </button>
                        </DialogFooter>
                    </div>

                </Modal>
            </>
        );
    }
}