import React, { Component } from 'react';

class RefreshButton extends Component {
    render() {
        var self = this;
        var icon = <i className={"fa-solid fa-arrows-rotate" + (self.props.refreshInProgress ? " fa-spin-2x" : "")}></i>;

        if (self.props.refreshPaused.length) {
            icon = <i className="fa-solid fa-pause"></i>;
        }

        return (
            <button
                className={"btn btn-default " + (self.props.leftAlign ? "" : "right-action-button")}
                disabled={self.props.refreshInProgress || self.props.refreshPaused.length}
                onClick={function (e) { e.preventDefault(); self.props.triggerRefresh() }}
            >
                {icon}
            </button>
        );
    }
}

export default RefreshButton;
