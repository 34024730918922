import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cmsGetPromise } from '../CallMSAPI.js';
import { expandCallData, isSystemOwner } from '../CallMSUIHelpers.js';
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import ActionHeader from '../ActionHeader';
import { toast } from 'react-toastify';
import ExpandingTable from '../ExpandingReactTable';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { connect } from 'react-redux';

import dayjs from '../dayjs.js';

class CallsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

            filters: [],
            searchText:[],
            showCallLogHelp: false,
            systemOwner: { Id: '', Name: '' }

        };

        this.fetchData = this.fetchData.bind(this);
        this.getColumns = this.getColumns.bind(this);
    }

    componentDidMount(){
        const systemOwner = isSystemOwner(this.props.account.roles);
        if (systemOwner) {
            this.setState({ systemOwner });
        }
    }

    toggleCallHelp() {
        this.setState(prevState => ({ showCallLogHelp: !prevState.showCallLogHelp }));
    }

    dismissCallHelp() {
        this.setState({ showCallLogHelp: false });
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: this.props.account.Id,
            objectType: 'calls',
            ServiceUserId: (this.props.user ? this.props.user.Id : null)
        };

        if (apiParams.accountId === undefined) {
            return;
        }

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            if(sortSettings.Column == 'Started'){
                apiParams['SortBy'] = 'StartedOn';
            }
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        if(filterValues) {
            if(filterValues['Disposition'] && filterValues['Disposition'] != 'all'){
                apiParams['onlyFailedCalls'] = true;
            }
            if(filterValues['Callee ID'] && filterValues['Callee ID'] != 'all'){
                apiParams['onlyEmergency'] = true;
            }
            if(filterValues['Direction'] && filterValues['Direction'] != 'all'){
                apiParams['callDirection'] = filterValues['Direction'];
            }
        }

        return cmsGetPromise(apiParams).then(res => {
            if (res.data) {

                let out = res;
                out.data.Results.forEach(function (call) {
                    call = expandCallData(call);
                });

                return out;
            }
        }).catch(err => {
            console.log('what is happening here', err)
        })
    }

    showModal() {
        this.setState({ hideDialog: false });
    }

    hideModal() {
        this.setState({ hideDialog: true });
    }

    getColumns() {
        var self = this;
        var columns = [];

        columns.push(
            {
                id: "direction",
                Header: "Direction",
                accessor: function (call) {
                    return call.Direction === 'FromPart1' ? 'In' : 'Out';
                },
                sortable: false,
                width: 100,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "true") {
                        return true;
                    }
                    return false;
                },
                Filter: function ({ filter, onChange }) {
                    return (
                        <select
                            onChange={event => {
                                let temp = [...self.state.filters];
                                temp["Direction"] = event.target.value;
                                self.setState({filters: temp});
                            }}
                            style={{ width: "100%" }}
                            value={self.state.filters && self.state.filters['Direction'] ? self.state.filters['Direction'] : "all"}
                        >
                            <option key='all' value='all'>All</option>
                            <option key='Inbound' value='Inbound'>In</option>
                            <option key='Outbound' value='Outbound'>Out</option>
                        </select>
                    );
                }
            }
        );

        columns.push(
            {
                id: "started",
                Header: "Started",
                width: 160,
                accessor: function (call) {
                    var started = call.StartedOn;
                    var titleStarted = call.StartedOn;
                    { 
                        let t = dayjs.utc(call.StartedOn);
                        if (t.isValid()) {
                            started = t.fromNow();
                            titleStarted = t.local().format('MMM DD YYYY, H:mm:ss');
                        }
                    }

                    return <span title={titleStarted}>{titleStarted}</span>;
                },
                sortable: true,
                filterable: false
            }
        );

        columns.push(
            {
                id: "duration",
                Header: "Duration (h:m:s)",
                width: 130,
                accessor: function (call) {
                    if (call.Disposition === 'answered') {
                        return 'In Progress';
                    } else if (call.Duration) {
                        return call.Duration;
                    } else {
                        return null;
                    }
                },
                sortable: false,
                filterable: false
            }
        );

        columns.push({
            id: "callerid",
            Header: "Caller ID",
            accessor: call => call.neatCallerId,
            sortable: false,
            filterable: false
        });

        columns.push({
            id: "calleeid",
            Header: "Callee ID",
            width: 200,
            accessor: function (call) {
                return <span title="Emergency Number" className={`${call.IsEmergency ? 'emergency-call' : ''}`}>{call.neatCalleeId} {call.IsEmergency? <span >(Emergency Number)</span> : null} </span>;
            },
            sortable: false,
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "true") {
                    return true;
                }
                return false;
            },
            Filter: function ({ filter, onChange }) {
                return (
                    <select
                        onChange={event => {
                            let temp = [...self.state.filters];
                            temp["Callee ID"] = event.target.value;
                            self.setState({filters: temp});
                        }}
                        style={{ width: "100%" }}
                        value={self.state.filters && self.state.filters['Callee ID'] ? self.state.filters['Callee ID'] : "all"}
                    >
                        <option key='all' value='all'>All</option>
                        <option key='emergencyCalls' value='emergencyCalls'>Emergency Calls</option>
                    </select>
                );
            }
        });

        if (this.props.ShowAdminColumns) {
            columns.push({
                id: "sbchost",
                Header: "Edge SBC",
                accessor: call => call.SBCHost,
                sortable: false,
                filterable: false
            });

            columns.push({
                id: "suid",
                Header: (<span>Service<br/>User ID</span>),
                accessor: call => call.ServiceUserId,
                sortable: false,
                filterable: false
            });

            columns.push({
                id: "diaghold",
                Header: (<span>Diagnostic<br />Hold</span>),
                accessor: function(call) {
                    if (call.DiagnosticHold) {
                        return <i className="fa-solid fa-check" title="Success"></i>;
                    }
                    return '';
                },
                sortable: false,
                filterable: false
            });
        }

        columns.push({
            id: "disposition",
            Header: "Disposition",
            className: "text-center",
            width: 100,
            accessor: function (call) {
                if (call.Disposition === 'success') {
                    return <i className="fa-solid fa-check" title="Success"></i>;
                }
                if (call.Disposition === 'answered') {
                    return <i className="fa-solid fa-check" title="Answered - In Progress"></i>;
                }
                if (call.Disposition === 'failed') {
                    return <i className="fa-solid fa-xmark" title="Failure"></i>;
                }
                if (call.Disposition === 'transferfailed') {
                    return <i className="fa-solid fa-xmark" title="Transfer Failed"></i>;
                }
                if (call.Disposition === 'cancelled') {
                    return <i className="fa-solid fa-circle text-muted" title="Cancelled"></ i>;
                }
                return <i className="fa-solid fa-question text-muted" title={call.Disposition}></ i>;
            },
            sortable: false,
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "true") {
                    return true;
                }
                return false;
            },
            Filter: function ({ filter, onChange }) {
                return (
                    <select
                        onChange={event => {
                            let temp = [...self.state.filters];
                            temp["Disposition"] = event.target.value;
                            self.setState({filters: temp});
                        }}
                        style={{ width: "100%" }}
                        value={self.state.filters && self.state.filters["Disposition"] ? self.state.filters["Disposition"] : "all"}
                    >
                        <option key='all' value='all'>All</option>
                        <option key='failedCalls' value='failedCalls'>Failed Calls</option>
                    </select>
                );
            }
        });

        columns.push({
            id: "link",
            Header: "",
            className: "text-right",
            colWidth: 50,
            accessor: function (call) {
                //This is a page with demo content which we shouldn't direct users to
                //const to = `/portal/${self.props.account.Id}/calls/${self.state.systemOwner.Id > 0 ? 'system_owner/' : ''}${call.Id}`; 
                const to = `/portal/${self.props.account.Id}/calls/${call.Id}`;

                if (self.props.NoModal) {
                    return (
                        <Link to={to}>
                            <i className="fa-solid fa-arrow-right"></i>
                        </Link>
                    );
                } else {
                    return (
                        <Link to={to} target="_blank">
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                        </Link>
                    );
                }
            },
            sortable: false,
            filterable: false
        });

        return columns;
    }

    render() {
        var self = this;

        // Also available:
        // [CallIdPart1]
        // [CallIdPart2]
        // [CallIdInternal]

        var callLogTable = (
            <ExpandingTable
                loadingText={"Loading..."}
                filters={self.state.filters}
                searchText={self.state.searchText}
                noDataText={"No calls to show."}
                fetchData={self.fetchData}
                showPagination={true}
                sortBy={
                    {
                        Column: "started",
                        SortDirection: 'Descending'
                    }
                }
                columns={self.getColumns()}
            />
        );

        if (this.props.NoModal) {
            return callLogTable;
        }
       
        return (
            <Modal
                isOpen={this.props.isOpen}
                onDismiss={this.props.closeCallback}
                isBlocking={false}
            >
                <div className="call-table-wrapper">
                    <ActionHeader headerText="Recent Call Log" smallHeader={true}>
                        <button className="btn btn-link btn--faux-link btn-sbc-help" onClick={(e) => { e.preventDefault(); self.toggleCallHelp(); return false; }}>
                            <i className="fa-solid fa-question-circle" id="CallHelp"></i>
                        </button>
                        {self.state.showCallLogHelp ?
                            <TeachingBubble
                                target={'#CallHelp'}
                                hasCondensedHeadline={true}
                                onDismiss={() => self.dismissCallHelp()}
                                hasCloseIcon={true}
                                closeButtonAriaLabel="Close"
                                headline={"Recent Call Logs"}
                            >
                                <p>This table contains recent calls made to and from the selected user. Calls are typically kept for 5 days.</p>
                                <p>Calls that are placed to the user but then cancelled with 'answered elsewhere' are never stored in the log.</p>
                            </TeachingBubble>
                            : null}
                        <button className="btn btn-default" onClick={this.props.closeCallback}>
                            <Icon iconName="Cancel" className="fa-lg" />
                        </button>
                    </ActionHeader>
                    <hr />

                    {callLogTable}
                </div>
            </Modal>
        );
    }
}
export default connect(state => ({
    account: state.account.account
}))(CallsModal);
