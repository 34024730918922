import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Modal, TeachingBubble } from 'office-ui-fabric-react';
import { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast } from 'react-toastify';
import { cmsGetCallData } from '../../CallMSAPI';
import CallFlowDiagram from './CallFlowDiagram';
import CallFlowPacket from './CallFlowPacket';
import ActionHeader from '../../ActionHeader';
import { CallLog } from '../../models/CallLog';
import { CallLogType } from '../../models/Enums';
import { connect } from 'react-redux';
import CallSingle from '../../ServiceUsers/CallSingle';

const SystemOwnerCallSingle = (props) => {

    const [callLogData, setCallLogData] = useState({ DebugOutput: [new CallLog()] });
    const [callLogUser, setCallLogUser] = useState({});

    const [lineModal, setLineModel] = useState({
        toggled: false,
        callLog: new CallLog()
    });

    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //props.history.push(`/portal/${props.account.Id}/calls/${props.match.params.callId}`);
        cmsGetCallData(props.account.Id, props.match.params.callId,
            resp => {
                setCallLogData(resp.callLogData);
                setCallLogUser(resp.callLogUser);
                setLoading(false);
            },
            () => toast.error('Error has occured while retrieving a call log')
        );
       
    }, [setCallLogData, setLoading]);

    const callFlowPcapToggle = (log = new CallLog()) => {
        setLineModel({
            callLog: log,
            toggled: !lineModal.toggled
        });
    }

    const renderTabCallFlow = () => {
        //('Call flow is a ladder diagram, plus the ability to click and view packets.');
        return (
            <>
                <Modal
                    onDismiss={callFlowPcapToggle}
                    isOpen={lineModal.toggled}
                    isBlocking={false}>
                    <div className='m-3'>
                        <ActionHeader headerText='MSG ID: 2' smallHeader={true}>
                            <button className='btn btn-link btn--faux-link btn-sbc-help'>
                                <i className='fa-solid fa-question-circle' id='CallHelp' />
                            </button>
                            {/*Needs detail about what we need to describe...*/}
                            {false &&
                                <TeachingBubble
                                    target={'#CallHelp'}
                                    hasCondensedHeadline={true}
                                    hasCloseIcon={true}
                                    closeButtonAriaLabel='Close'
                                    headline='Call Logs'
                                >
                                    <p>This table contains recent calls made to and from the selected user. The latest call is always kept in the log, one for inbound and one for outbound. Other calls are only kept for 5 days.</p>
                                    <p>Calls that are placed to the user but then cancelled with 'answered elsewhere' are never stored in the log.</p>
                                </TeachingBubble>
                            }
                            <button onClick={callFlowPcapToggle} className='btn btn-default'>
                                <Icon iconName='Cancel' className='fa-lg' />
                            </button>
                        </ActionHeader>
                        <CallFlowPacket callLog={lineModal.callLog} />
                    </div>
                </Modal>
                <CallFlowDiagram
                    clickLineTitle={callFlowPcapToggle}
                    callLogs={[
                        new CallLog({
                            content: 'INVITE (SDP)',
                            time: 1622709524.10855,
                            type: CallLogType.PBXToEdge
                        }),
                        new CallLog({
                            content: '100 Trying',
                            time: 1622709524.10965,
                            type: CallLogType.EdgeToPBX
                        }),
                        new CallLog({
                            content: 'INVITE (SDP)',
                            time: 1622709524.10899,
                            type: CallLogType.EdgeToApp
                        }),
                        new CallLog({
                            content: '180 Ringing',
                            time: 1622709524.10899,
                            type: CallLogType.EdgeToPBX
                        }),
                        new CallLog({
                            content: '180 Ringing',
                            time: 1622709524.10899,
                            type: CallLogType.EdgeToPBX
                        }),
                        new CallLog({
                            content: '180 Ringing',
                            time: 1622709524.10899,
                            type: CallLogType.EdgeToPBX
                        }),
                        new CallLog({
                            content: '200 OK (SDP)',
                            time: 1622709524.10899,
                            type: CallLogType.EdgeToPBX
                        }),
                        new CallLog({
                            content: 'ACK',
                            time: 1622709524.10899,
                            type: CallLogType.PBXToEdge
                        }),
                        new CallLog({
                            content: 'BYE',
                            time: 1622709524.10899,
                            type: CallLogType.PBXToEdge
                        }),
                        new CallLog({
                            content: '200 Closing',
                            time: 1622709524.10899,
                            type: CallLogType.EdgeToPBX
                        })
                    ]}
                />
            </>
        );
    }

    const renderTabInternal = () => {
        return ('Internal is for future use.');
    }


    return (
        <>
            <ActionHeader headerText='System Owner Call Data' />

            <hr />
            {loading &&
                <p>Loading Call Data...</p>
            }

            {!loading &&
                <Tabs
                    selectedIndex={index}
                    onSelect={(i) => setIndex(i)}
                >
                    <TabList>
                        <Tab key='events'>
                            Events
                        </Tab>
                        <Tab key='call_flow'>
                            Call Flow
                        </Tab>
                        {/*Internal for later use*/}
                        {/*<Tab key='internal'>*/}
                        {/*    Internal*/}
                        {/*</Tab>*/}
                    </TabList>
                    <div className='mt-2'>
                        <TabPanel key='events'>
                        <CallSingle
                            callLogData={callLogData}
                            callLogUser={callLogUser}
                        />
                        </TabPanel>
                        <TabPanel key='call_flow'>
                            {renderTabCallFlow()}
                        </TabPanel>
                        {/*Internal for later use*/}
                        {/*<TabPanel key='internal'>*/}
                        {/*    {renderTabInternal()}*/}
                        {/*</TabPanel>*/}
                    </div>

                </Tabs>
            }
        </>
    );
}
export default withRouter(connect(state => ({
    account: state.account.account
}))(SystemOwnerCallSingle));