import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initState = {
    account: {
        Id: '',
        roles: []
    },
    baseAccount: {
        Id: '',
        roles: [],
        Name: ''
    },
    accountHierarchy: [],
    baseAccounts: [],
    baseAccountsLoaded: false,
    baseAccountInfo: {},
    requireAccount: true,
    contractAccount: {
        purchasedEnabled: false,
        info: {}
    },
    //user configuration...
    userUI: {
        showPrivateFields: false
    },
    err: {},
    loaded: false,
    incorrectLogin: false,
    switchingAcc: false
};

const start = (state, action) => {
    return updateObject(state, {
        ...state,
        loaded: false
    });
}
export const success = (state, action) => {
    return updateObject(state, {
        ...action.account,
        err: {}
    });
}
export const fail = (state, action) => {
    return updateObject(state, {
        ...state,
        loaded: true,
        err: action.err
    });
}
export const switchingAccount = (state, action) => {
    return updateObject(state, {
        ...state,
        switchingAcc: action.switchingAcc
    });
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.ACCOUNT_START: return start(state, action);
        case actionTypes.ACCOUNT_SUCCESS: return success(state, action);
        case actionTypes.ACCOUNT_FAIL: return fail(state, action);
        case actionTypes.ACCOUNT_SWITCHING: return switchingAccount(state, action);
        default:
            return state;
    }
}
export default reducer;