import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { getErrMsg, cmsGetExternalLinks } from '../../CallMSAPI.js';
import { showImageExternalLinks } from './BrandingExternalLinksAssembler.js';
import BrandingExternalLinkForm from './BrandingExternalLinkForm';
import { canAccess, canAccessFeature } from '../../CallMSUIHelpers.js';
import { connect } from 'react-redux';

var _ = require('lodash');

class BrandingExternalLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            externalLinks: null
        };
    }

    componentDidMount() {
        this.getExternalLinks();
    }

    getExternalLinks = () => {
        var self = this;

        cmsGetExternalLinks(
            self.props.account.Id,
            function (response) {
                self.setState({
                    externalLinks: showImageExternalLinks(
                        response.Results,
                        canAccess(
                            'ImageExternalLinks',
                            self.props.baseAccount.roles),
                        canAccessFeature(
                            "FOOTERLINKS",
                            "Brand Contributor",
                            self.props.account.roles,
                            self.props.account)
                    )
                });
            },
            function (error) {
                toast.error("Unable to get the external links: " + getErrMsg(error));
                self.setState({
                    externalLinks: null
                });
            }
        )
    }

    getForms = (links) => {
        var response = [];
        if (links !== null) {
            _.map(links, function (link, i) {
                response.push(
                    <BrandingExternalLinkForm 
                        initialValues={link}
                        key={i}
                    />
                )
            })
        }

        return response;
    }

    render = () => {
        var self = this;
        var forms = self.getForms(self.state.externalLinks);
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <h2>Links</h2>
                        <hr />
                    </div>
                </div>
                {forms}
            </>
        )
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandingExternalLinks);