import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { cmsGetWizardPrevPreReqsPromise } from '../CallMSAPI.js';
import { Link } from 'react-router-dom';
var _ = require('lodash');

class PrerequisiteDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showGlbAdmDialog: true,
            showOff365Dialog: true,
            showSysLicBubble: true,
            showPbxTnkLicDialog: true,
            showResAccntDialog: true,
            hasHistory: false
        }
        this.showModal = this.showModal.bind(this);
        this.showBubble = this.showBubble.bind(this);
    }

    componentDidMount() {
        var self = this;
        cmsGetWizardPrevPreReqsPromise(self.props.account.Id).then(function (res) {
            if (res && res.data && res.data.Results && res.data.Results.length > 0) {
                self.setState({ hasHistory: true });
            }
        });
    }

    showModal(prop, visible) {
        this.setState({ [prop]: visible });
    }

    showBubble(prop, visible) {
        this.setState({ [prop]: visible });
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.statuses, this.props.statuses)) {
            this.setState({
                showSysLicBubble: true,
                showGlbAdmDialog: true,
                showOff365Dialog: true,
                showPbxTnkLicDialog: true,
                showResAccntDialog: true
            });
        }
    }

    getModal(id) {
        var data;
        switch (id) {
            case "glbAdm":
                data = {
                    stateId: "showGlbAdmDialog",
                    show: this.state.showGlbAdmDialog,
                    title: 'No global admin rights.',
                    subText: 'Please grant a user/login to your Office 365 account with Global Admin rights and then press Check My Tenant.'
                }
                break;
            case "off365":
                data = {
                    stateId: "showOff365Dialog",
                    show:this.state.showOff365Dialog,
                    title:'No unassigned Office 365 user license.',
                    subText: (
                        <>
                            <p>No unassigned Office 365 user licenses could be found.</p>
                            <p>For the automatic service activation please make available at least one licence, not assigned to a user, of any the following types:</p>
                            <ul>
                                <li>Microsoft 365 Business Basic</li>
                                <li>Microsoft 365 Business Premium*</li>
                                <li>Office 365 E3</li>
                                <li>Office 365 E5*</li>
                                <li>Common Area Phone*</li>
                                <li>Skype for Business Plan 2</li>
                            </ul>
                            <p>*Free Trial offers for these licences may be available in the Microsoft 365 portal.</p>
                        </>
                        )
                }
                break;
            case "sysLic":
                data = {
                    stateId: "showSysLicBubble",
                    show: this.state.showSysLicBubble,
                    title: 'No phone system licenses founds.',
                    subText: 'Without first purchasing a license, you cannot set up any users.'
                }
                break;
            case "pbxTnkLic":
                data = {
                    stateId: "showPbxTnkLicDialog",
                    show: this.state.showPbxTnkLicDialog,
                    title: 'No PBX or Trunk license found.',
                    subText: 'Please purchase a PBX or Trunk license from your supplier.'
                }
                break;
            case "resAccnt":
                data = {
                    stateId: "showResAccntDialog",
                    show: this.state.showResAccntDialog,
                    title: 'Ensure you have sufficient licenses before you continue:',
                    subText: 'You need at least one unassigned Microsoft Teams Phone Resource Account license on your tenant if using Phone App.'
                }
                break;
            default:
                data = {
                    show: false
                }
        }

        return (
            data.show && (
                <Dialog
                    hidden={false}
                    onDismiss={() => this.showModal(data.stateId, false)}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: data.title,
                        closeButtonAriaLabel: 'Close',
                        subText: data.subText
                    }}
                    modalProps={{
                        styles: { main: { maxWidth: 450 } },
                    }}>
                    <DialogFooter>
                        <PrimaryButton text="Close" onClick={() => this.showModal(data.stateId, false)} />
                    </DialogFooter>
                </Dialog>
            )
        )
    }

    getStatus = (status, id) => {
        switch (status) {
            case "not checked":
                return (
                    <p className="text-align--center prerequisite-status">{status}</p>
                )
                break;
            case "passed":
                return (
                    <>
                        <div className='col-md-6'>
                            <Icon iconName="CheckMark" className="prerequisite-status-icon--success" style={{ fontWeight: 'bold', fontSize: '25px', float: 'right' }} />
                        </div>
                        <div className='col-md-6'>
                        </div>
                    </>
                )
                break;
            case "failed":
                if (id === 'servicePrinc') {
                    return (
                        <>
                            <div className="row">
                                <div className='col-md-6'>
                                    <Icon iconName="CalculatorMultiply" className="prerequisite-status-icon--failure" style={{ fontWeight: 'bold', fontSize: '25px', float: 'right' }} />
                                </div>
                                <div className='col-md-6'>
                                    <button type="button" className="btn btn-primary" style={{ float: 'left' }} onClick={this.props.createServicePrincipal}>Fix</button>
                                </div>
                            </div>
                        </>
                    )
                } else if (id === 'off365' && this.props.isPattiFPresent) {
                    return (
                        <>
                            <div className="row">
                                <div className='col-md-6'>
                                    <Icon iconName="CalculatorMultiply" className="prerequisite-status-icon--failure" style={{ fontWeight: 'bold', fontSize: '25px', float: 'right' }} />
                                </div>
                                <div className='col-md-6'>
                                    <button type="button" className="btn btn-primary" style={{ float: 'left' }} onClick={this.props.delicenceTestUser}>Delicense Patti F</button>
                                </div>
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div className="row">
                                <div className='col-md-6'>
                                    <Icon iconName="CalculatorMultiply" className="prerequisite-status-icon--failure" style={{ fontWeight: 'bold', fontSize: '25px', float: 'right' }} />
                                </div>
                                <div className='col-md-6'>
                                </div>
                            </div>
                            {this.getModal(id)}
                        </>
                    )
                }
                break;
            case "warning":
                return (
                    <>
                        <div className="row">
                            <div className='col-md-6'>
                                <Icon iconName="Warning" className="prerequisite-status-icon--warning" style={{ fontWeight: 'bold', fontSize: '25px', float: 'right' }} />
                            </div>
                            <div className='col-md-6'>
                            </div>
                        </div>
                        {this.getModal(id)}
                    </>
                )
                break;
            default:
                return (
                    <p className="text-align--center prerequisite-status">{status}</p>
                )
        }
    }

    render = () => {
        const { props } = this;
        var isPBX = false;
        var isTRUNK = false;

        if (props.availableServices.length > 0) {
            if (props.availableServices.filter(x => x.Code === 'PBX')) { isPBX = true; }
            if (props.availableServices.filter(x => x.Code === 'TRUNK')) { isTRUNK = true; }
        }
        return (
            <div id="prereq">
                <div className='row'>
                    <div className="col-md-12">
                        <h2>Welcome to the {props.account.Brand} Wizard</h2>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div className="col-md-9">
                        <p>This wizard will help you setup your service and first user ready for calls. Before you begin the admin setup you will need:</p>
                    </div>
                    <div className="col-md-3"></div>
                </div>
                <br />
                <div className='row'>
                    <div className="col-md-12">
                        <ul>
                            <li className='row'>
                                <div className="col-md-9" style={{ marginTop: '-15px' }}>A login with Global Admin permissions for your Microsoft 365 account.</div>
                                <div className="col-md-3 text-align--center prerequisite-status" style={{ marginTop: '-15px' }}>{this.getStatus(props.statuses.glbAdm, "glbAdm")}</div>
                            </li>
                            <li className='row'>
                                <div className="col-md-9" style={{ marginTop: '-15px' }}>Microsoft Phone System license add-ons/E5 licences for service end users. (This is not needed if you intend to only use Phone App).</div>
                                <div className="col-md-3 text-align--center prerequisite-status" style={{ marginTop: '-15px' }}>{this.getStatus(props.statuses.sysLic, "sysLic")}</div>
                            </li>
                            <li className='row'>
                                <div className="col-md-9" style={{ marginTop: '-15px' }}>Two unassigned Microsoft 365 user licences (Business Basic/Premium/E1/E3/E5) available during the initial setup.</div>
                                <div className="col-md-3 text-align--center prerequisite-status" style={{ marginTop: '-15px' }}>{this.getStatus(props.statuses.off365, "off365")}</div>
                            </li>
                            <li className='row'>
                                <div className="col-md-9" style={{ marginTop: '-15px' }}>Microsoft Teams Phone Resource Account license for Phone App.</div>
                                <div className="col-md-3 text-align--center prerequisite-status" style={{ marginTop: '-15px' }}>{this.getStatus(props.statuses.resAccnt, "resAccnt")}</div>
                            </li>
                            <li className='row'>
                                <div className="col-md-9" style={{ marginTop: '-15px' }}>PBX or Trunk management portal access to setup SIP credentials.</div>
                                <div className="col-md-3 text-align--center prerequisite-status" style={{ marginTop: '-15px' }}>{this.getStatus(props.statuses.pbxSip, "off365")}</div>
                            </li>
                            <li className='row'>
                                <div className="col-md-9" style={{ marginTop: '-15px' }}>A modern compatible web browser such as Chrome or Edge.</div>
                                <div className="col-md-3 text-align--center prerequisite-status" style={{ marginTop: '-15px' }}>{this.getStatus(props.statuses.isIE, "isIE")}</div>
                            </li>
                            <li className='row'>
                                <div className="col-md-9" style={{ marginTop: '-15px' }}>A minimum of one {props.account.Brand} license.</div>
                                <div className="col-md-3 text-align--center prerequisite-status" style={{ marginTop: '-15px' }}>{this.getStatus(props.statuses.pbxTnkLic, "pbxTnkLic")}</div>
                            </li>
                            <li className='row'>
                                <div className="col-md-9" style={{ marginTop: '-15px' }}>To ensure that your Microsoft tenant supports Direct Routing configuration.</div>
                                <div className="col-md-3 text-align--center prerequisite-status" style={{ marginTop: '-15px' }}>{this.getStatus(props.statuses.servicePrinc, "servicePrinc")}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='row wizard-button--container'>
                    <div className='col-md-12'>
                        <div className='col-md-push-6 col-md-6'>
                            <div className="pull-right">
                                <button className='btn btn-primary wizard-btn-primary' onClick={props.handleUpdate}>Check My Tenant</button>
                            </div>
                        </div>
                    </div>
                    {this.state.hasHistory ?
                        <div className='col-md-12'>
                            <div className='col-md-push-6 col-md-6'>
                                <div className="pull-right">
                                    <Link target="_blank" style={{ paddingRight: '60px' }} to={"/portal/" + this.props.account.Id + "/prereqhistory"}>
                                        View previous results
                                </Link>
                                </div>
                            </div>
                        </div>
                    : null}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrerequisiteDetails);

PrerequisiteDetails.propTypes = {
    handleUpdate: PropTypes.func.isRequired,
    statuses: PropTypes.object.isRequired,
};


