import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initState = {
    list: [],
    rawList: [],
    fullTeams: [],
    totalList: [],
    err: {},
    trueTransferSupported: false,
    canSelectTrueTransfer: false,
    loaded: false
};

const start = (state, action) =>
{
    return updateObject(state, {
        ...state,
        loaded: false
    });
}
export const success = (state, action) =>
{
    return updateObject(state, {
        ...action.services,
        err: {},
        loaded: true
    });
}
export const fail = (state, action) =>
{
    return updateObject(state, {
        ...state,
        loaded: true,
        err: action.error
    });
}

const reducer = (state = initState, action) =>
{
    switch (action.type) {
        case actionTypes.SERVICES_START: return start(state, action);
        case actionTypes.SERVICES_SUCCESS: return success(state, action);
        case actionTypes.SERVICES_FAIL: return fail(state, action);
        default:
            return state;
    }
}
export default reducer;