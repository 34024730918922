import React from 'react';
import { connect } from 'react-redux';
import BrandingTabs from './BrandingTabs';

const Branding = (props) => {

    const getServiceVariants = () => {
        if (props.account.AvailableServices === null) {
            return null;
        }
        else {
            var services = props.account.AvailableServices.find(x => x.Code === 'pbx');
            return services === undefined
                ? null
                : services.Variants
        }
    }


    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <h1>{props.account.Name} Branding</h1>
                    <hr />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <BrandingTabs
                        serviceVariants={getServiceVariants()}
                    />
                </div>
            </div>
        </>
    );
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Branding);