import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ServiceTabs from './ServiceTabs.js';
//NEED TO REPLACE REDUX ERROR WITH TOAST!
import { toast } from 'react-toastify';
import ActionHeader from '../ActionHeader.js';
import { isSystemOwnerSystemAcc } from '../CallMSUIHelpers';
import {
    extractLicence,
    PBX_USER_LICENCE_CODE,
    TRUNK_USER_LICENCE_CODE
} from '../LicenceHelpers';

import ServiceSync from './ServiceSync';

import { connect } from 'react-redux';

var _ = require('lodash');

class Services extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableRevision: 1,
            tabIndex: 0
        };
    }

    render() {
        var self = this;
        var serviceSync = null;

        if (this.props.services.rawList.length) {
            serviceSync = (
                <ServiceSync />
            );
        }

        return (
            <div className="Portal">
                <ActionHeader headerText="Services">{serviceSync}</ActionHeader>
                <hr />

                <ServiceTabs restrictServiceTabs={false} />
            </div>
        );
    }
}
const mapStateToProps = state => {
    const services = state.services;
    return {
        services
    };
}
const mapDispatchToProps = (_dispatch) =>
{
    return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Services));
