import React, { Component } from 'react';

import APIKeyForm from './APIKeyForm';
import APIKeyReactTable from './APIKeyReactTable';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';

class TabAPIKey extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            tableApiRevision: 1,
            hideAPIForm: true,
            newApiData: null,
        }

        this.toggleApiFormClick = this.toggleApiFormClick.bind(this);
        this.triggerAPITableUpdate = this.triggerAPITableUpdate.bind(this);
    }

    justCopiedAction() {
        toast.success("API Key copied to clipboard");
    }

    triggerAPITableUpdate(data = null) {
        this.setState(prevState => ({
            newApiData: data,
            tableApiRevision: prevState.tableApiRevision + 1,
            hideAPIForm: true,
        }));
    }

    toggleApiFormClick(e) {
        e.preventDefault();
        this.setState(prevState => ({
            hideAPIForm: !prevState.hideAPIForm
        }));
    }


    render() {
        let disableAddButton = true;
        let formWrapperClasses = "alert alert-info user-form-wrapper user-form-wrapper--admin";
        if (this.state.hideAPIForm) {
            formWrapperClasses += " hidden";
            disableAddButton = false;
        }

        var newApiKeyReveal = null;
        if (this.state.newApiData !== null) {
            newApiKeyReveal = (
                <div className="alert alert-success">
                    <p>Keep this API key somewhere safe. You will not be able to view the key again.</p>
                    <hr />
                    <table className="table table-striped">
                        <tr>
                            <th>Name:</th>
                            <td>{this.state.newApiData.Name}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Key:</th>
                            <td>{this.state.newApiData.Key}</td>
                            <td>
                                <CopyToClipboard text={this.state.newApiData.Key} onCopy={this.justCopiedAction}>
                                    <button className="btn btn-link btn-xs">
                                        <span>Copy <i className="fa-solid fa-copy"></i></span>
                                    </button>
                                </CopyToClipboard>
                            </td>
                        </tr>
                    </table>
                </div>
            );
        }

        return (
            <>
                <div className="alert">
                <p>{this.props.account.Brand} has an API to allow you to
                automate provisioning from your own systems. <a target="_blank"
                href="/api/swagger/ui/index">Click here</a> to access the API
                reference which also allows you to
                enter your API key and make API calls from within your browser.
                The API is accessible via HTTP requests that have an
                <code>APIKey</code> header with a valid API Key value.</p>

                    <hr />
                    <button className="btn btn-primary" onClick={this.toggleApiFormClick} disabled={disableAddButton} >
                        <i className="fa-solid fa-plus"></i> Create API Key
                    </button>
                </div>

                {newApiKeyReveal}

                <div className={formWrapperClasses}>
                    <p>Use the form below to add an API key for this account. You will not be able to view the API key again after leaving this page.</p>
                    <APIKeyForm
                        toggleFormClick={this.toggleApiFormClick}
                        apiUpdateTrigger={this.triggerAPITableUpdate}
                    />
                </div>
                <APIKeyReactTable
                    apiUpdateTrigger={this.triggerAPITableUpdate}
                    lastUpdate={this.state.tableApiRevision}
                />
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TabAPIKey);
