import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import WizardBreadcrumbs from '../WizardBreadcrumbs';
import PrerequisiteDetails from './PrerequisiteDetails';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import ServiceTabs from './ServiceTabs';

import MicrosoftServicePanel from './MicrosoftServicePanel';
import NewUserForm from '../ServiceUsers/NewUserForm';
import { toast } from 'react-toastify';
import { cmsGetServices, cmsGetCountries, cmsGetAllAccountServicePromise, cmsGetPBXTemplates, cmsStartOAuthServiceSync, cmsStartExternalServiceSync, cmsGetAccountServicePromise } from '../CallMSAPI.js';
import { openPopupWindow } from '../ExternalAuthProvider.js';

import { getWizardPreReqs, createServicePrincipal, getAccessToken, checkDelicenceTestUser } from '../MSGraphAPI'
import { cmsGetTrunkStatus, cmsGetPBXStatus, cmsGetSyncModuleDefaultSettings, cmsPutWizardPrevPreReqsPromise, cmsGetFullService } from '../CallMSAPI.js';
import ServiceSync from './ServiceSync';
import {
    extractLicence,
    PBX_USER_LICENCE_CODE,
    TRUNK_USER_LICENCE_CODE
} from '../LicenceHelpers';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

var _ = require('lodash');
var checkIE = require('check-ie');
const AppServicePrincipalWriteScope = "Application.ReadWrite.All";
const RemoveLicensesDirectoryScope = 'Directory.ReadWrite.All';
const DirectoryReadAllScope = 'Directory.Read.All';

class Wizard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepNames: [
                "Prerequisites",
                "Services",
                "Teams",
                "Users",
            ],
            showDialog: false,
            showStatusDialog: false,
            statuses: {
                glbAdm: " — ",
                sysLic: " — ",
                off365: " — ",
                resAccnt: " — ",
                pbxSip: " — ",
                isIE: " — ",
                pbxTnkLic: " — ",
                servicePrinc: " — "
            },
            tableRevision: 0,
            countries: [],
            isPrerequisiteNextBtnEnabled: false,
            isSyncComplete: false,
            pbxTemplates: [],
            pbxSyncId: null,
            pbxServiceToSync: null,
            SyncModuleDefaultSettings: {
                SyncClientId: null,
                TenantId: null
            },
            isPattiFPresent: false,
            isPhoneAppSetUp: false
        }
        this.stepChanged = this.stepChanged.bind(this);
        this.redirectToUser = this.redirectToUser.bind(this);
        this.getCountriesandTemplates = this.getCountriesandTemplates.bind(this);
        this.pbxSkipSyncCallback = this.pbxSkipSyncCallback.bind(this);
        this.newServiceSyncStartedCb = this.newServiceSyncStartedCb.bind(this);
        this.buildPopupCb = this.buildPopupCb.bind(this);
        this.requiresPBXSync = this.requiresPBXSync.bind(this);
        this.createServicePrincipal = this.createServicePrincipal.bind(this);
        this.incorrectPermissionsCb = this.incorrectPermissionsCb.bind(this);
        this.hasPBXorTrunkLicense = this.hasPBXorTrunkLicense.bind(this);
        this.delicenceTestUser = this.delicenceTestUser.bind(this);
        this.doPrerequisiteCheck = this.doPrerequisiteCheck.bind(this);
        this.getSyncModuleDefaultSettings = this.getSyncModuleDefaultSettings.bind(this);
        this.formatPreReqJsonSave = this.formatPreReqJsonSave.bind(this);
        this.getFullTeamsService = this.getFullTeamsService.bind(this);
    }

    incorrectPermissionsCb() {
        var self = this;
        var state = {
            showDialog: false,
            isPrerequisiteNextBtnEnabled: false,
            statuses: {
                glbAdm: "failed",
                sysLic: " — ",
                off365: " — ",
                resAccnt: " — ",
                pbxSip: " — ",
                isIE: " — ",
                pbxTnkLic: " — ",
                servicePrinc: " — "
            }
        };
        self.setState(state);

    }

    formatPreReqJsonSave(data, isCompatibleBrowser) {
        var self = this;
        var hasPBXorTrunkLicense = self.hasPBXorTrunkLicense(self.props.account)
        var preReqResults = {
            "Global Admin check": data.isGlobalAdmin,
            "Compatible Browser": isCompatibleBrowser,
            "Microsoft Phone System licenses": data.userPhoneLicenses ? "passed" : "failed",
            "Spare O365 Licenses": data.o365Licenses ? "passed" : "failed",
            "Access to portal": "passed",
            "Microsoft tenant supports Direct Routing configuration": data.servicePrinc ? "passed" : "failed",
            "At least one license": hasPBXorTrunkLicense ? "passed" : "failed",
            "Tenant used to check": data.idToken.idToken.preferred_username && data.idToken.idToken.name ? (data.idToken.idToken.name + ' (' + data.idToken.idToken.preferred_username + ')') : 'n/a',
            "Tenant id use": data.idToken.idToken.tid ? data.idToken.idToken.tid : 'n/a',
            "Account used": self.props.account.Name,
            "Full Data": {
                "Global Admin check": data.isGlobalAdminFullData,
                "Spare O365 Licenses": data.o365LicensesFullData,
                "Microsoft Phone System licenses": data.userPhoneLicensesFullData,
                "Microsoft tenant supports Direct Routing configuration": data.servicePrincFullData,
                "Is default user Patti F Present": data.isPattiFPresentFullData
            }
        };
        
        var data = {
            results: JSON.stringify(preReqResults),
            tenantId: data.idToken.idToken.tid,
            userUPN: data.idToken.idToken.name + ' (' + data.idToken.idToken.preferred_username + ')'
        }

        cmsPutWizardPrevPreReqsPromise(self.props.account.Id, data).then(function (res) {
            toast.success('Prerequisites check logged successfully');
        }).catch(function (err) {
            toast.error('err: ' + err.message);
        })
    }

    createServicePrincipal() {
        var self = this;
        var statusState = { ...self.state.statuses };
        statusState.glbAdm = "failed";
        var reqArg = {
            SyncModuleDefaultSettings: self.state.SyncModuleDefaultSettings,
            additionalScope: AppServicePrincipalWriteScope + "+" + DirectoryReadAllScope
        };
        return getAccessToken(reqArg, null, function () {
            self.setState({
                showDialog: false,
                isPrerequisiteNextBtnEnabled: false,
                statuses: statusState
            });
        }).then(function (tok) {
            return createServicePrincipal(tok.accessToken).then(function () {
                getWizardPreReqs(self.props.account, null, tok, reqArg).then(function (data) {
                    var hasPBXorTrunkLicense = self.hasPBXorTrunkLicense(self.props.account)
                    var ieStatus = checkIE.default(window.navigator.userAgent);
                    var isCompatibleBrowser = false;
                    if (ieStatus.isIE && ieStatus.version != 'ie11') {
                        isCompatibleBrowser = false;
                    }
                    else {
                        isCompatibleBrowser = true;
                    }

                    var state = {
                        showDialog: false,
                        isPrerequisiteNextBtnEnabled: data.isGlobalAdmin && data.o365Licenses && data.userPhoneLicenses && data.servicePrinc && hasPBXorTrunkLicense && isCompatibleBrowser,
                        statuses: {
                            glbAdm: data.isGlobalAdmin ? "passed" : "failed",
                            sysLic: data.userPhoneLicenses ? "passed" : "failed",
                            off365: data.o365Licenses ? "passed" : "failed",
                            resAccnt: data.teamsResourceAccount ? "passed" : "warning",
                            pbxSip: "passed",
                            isIE: isCompatibleBrowser ? "passed" : "failed",
                            servicePrinc: data.servicePrinc ? "passed" : "failed",
                            pbxTnkLic: hasPBXorTrunkLicense ? "passed" : "failed"
                        },
                        isPattiFPresent: data.isPattiFPresent
                    };

                    self.setState(state);
                }).catch(function (err) {

                    self.setState({
                        showDialog: false,
                        isPrerequisiteNextBtnEnabled: false,
                        statuses: {
                            glbAdm: " — ",
                            sysLic: " — ",
                            off365: " — ",
                            resAccnt: " — ",
                            pbxSip: " — ",
                            isIE: " — ",
                            pbxTnkLic: " — ",
                            servicePrinc: " — "
                        }
                    }, function () {
                        toast.warning(err.message);
                    });
                })
            })
        })
    }

    doPrerequisiteCheck(tok, incorrectCb, addScope, isPhoneAppSetUp) {
        var self = this;
        var reqArg = {
            SyncModuleDefaultSettings: self.state.SyncModuleDefaultSettings,
            additionalScope: addScope
        }
        var tokens = tok? tok : null;
        getWizardPreReqs(self.props.account, incorrectCb, tokens, reqArg).then(function (data) {
            var hasPBXorTrunkLicense = self.hasPBXorTrunkLicense(self.props.account)
            var ieStatus = checkIE.default(window.navigator.userAgent);
            var isCompatibleBrowser = false;
            if (ieStatus.isIE && ieStatus.version != 'ie11') {
                isCompatibleBrowser = false;
            }
            else {
                isCompatibleBrowser = true;
            }
            self.formatPreReqJsonSave(data, isCompatibleBrowser);

            var state = {
                showDialog: false,
                isPrerequisiteNextBtnEnabled: data.isGlobalAdmin && data.o365Licenses && data.userPhoneLicenses && data.servicePrinc && hasPBXorTrunkLicense && isCompatibleBrowser,
                statuses: {
                    glbAdm: data.isGlobalAdmin ? "passed" : "failed",
                    sysLic: data.userPhoneLicenses ? "passed" : "failed",
                    off365: data.o365Licenses ? "passed" : "failed",
                    resAccnt: data.teamsResourceAccount ? "passed" : (isPhoneAppSetUp ? "passed" : "warning"),
                    pbxSip: "passed",
                    isIE: isCompatibleBrowser ? "passed" : "failed",
                    servicePrinc: data.servicePrinc ? "passed" : "failed",
                    pbxTnkLic: hasPBXorTrunkLicense ? "passed" : "failed"
                },
                isPattiFPresent: data.isPattiFPresent
            };

            self.setState(state);
        }).catch(function (err) {

            self.setState({
                showDialog: false,
                isPrerequisiteNextBtnEnabled: false,
                statuses: {
                    glbAdm: " — ",
                    sysLic: " — ",
                    off365: " — ",
                    resAccnt: " — ",
                    pbxSip: " — ",
                    isIE: " — ",
                    pbxTnkLic: " — ",
                    servicePrinc: " — "
                }
            }, function () {
                toast.error(err.message);
            });
        }).catch(function (err) {
            throw new Error(err)
        })
    }

    getCountriesandTemplates() {
        var self = this;
        cmsGetCountries(
            self.props.account.Id,
            {},
            function (callmsData) {
                self.setState({ countries: callmsData.Results });
            },
            function (error) {
                toast.error("Countries load error: " + error);
            }
        );

        var templateVariantIds = [];
        if (   self.props.account.AvailableServices
            && self.props.account.AvailableServices.filter(x => x.Code === 'pbx').length
        ) {
            var templateVariants = self.props.account.AvailableServices.filter(x => x.Code === 'pbx')[0].Variants;
            templateVariants.forEach(function (variant) {
                if (!templateVariantIds.includes(variant.Id)) {
                    templateVariantIds.push(variant.Id);
                }
            })
        }
        cmsGetPBXTemplates(
            self.props.account.Id,
            templateVariantIds,
            function (callmsData) {
                self.setState({
                    pbxTemplates: callmsData.Results,
                }, function () {
                    if (self.state.pbxTemplates && self.state.pbxTemplates.length) {

                        var stepname = '';
                        var availableServices = [];
                        var isPBX = false;
                        var isTRUNK = false

                        if (this.props.account.AvailableServices.filter(x => x.Code === 'pbx').length > 0) {
                            availableServices = this.props.account.AvailableServices.filter(x => x.Code === 'pbx')[0].Variants;
                        }
                        if (availableServices.length > 0) {
                            if (availableServices.filter(x => x.Code === 'PBX')) { isPBX = true; }
                            if (availableServices.filter(x => x.Code === 'TRUNK')) { isTRUNK = true; }
                        }
                        var lics = null;
                        if (isPBX) {
                            lics = extractLicence(self.props.account, PBX_USER_LICENCE_CODE);
                            if (!lics || !lics.NumPurchased) {
                                isPBX = false;
                            }
                        }
                        if (isTRUNK) {
                            lics = extractLicence(self.props.account, TRUNK_USER_LICENCE_CODE);
                            if (!lics || !lics.NumPurchased) {
                                isTRUNK = false;
                            }
                        }
                        if (isPBX && isTRUNK) {
                            stepname = 'PBX/Trunk';
                        }
                        else if (isPBX && !isTRUNK) {
                            stepname = 'PBX';
                        }
                        else if (!isPBX && isTRUNK) {
                            stepname = 'Trunk';
                        }
                        else if (!isPBX && !isTRUNK) {
                            stepname = 'Services';
                        }

                        var templates = self.state.pbxTemplates;
                        var pbxServicesWithSync = self.props.services.rawList ? self.props.services.rawList.find(x => x.ServiceCode === 'pbx' && x.ServiceSyncModuleCode && x.ServiceSyncModuleCode != null) : null;
                        if ((templates && templates.length === 1 && templates[0].SupportsSync == true)
                            || pbxServicesWithSync ) {
                            self.setState({
                                stepNames: [
                                    "Prerequisites",
                                    stepname,
                                    "Teams",
                                ]
                            });
                        } else {
                            self.setState({
                                stepNames: [
                                    "Prerequisites",
                                    stepname,
                                    "Teams",
                                    "Users",
                                ]
                            });
                        }
                    }
                });
            }
        );
    }

    componentDidMount() {
        this.getCountriesandTemplates();

        this.refetchServices(() => {
            this.getSyncModuleDefaultSettings();
            if (this.props.services && this.props.services.rawList) {
                let teamsService = this.props.services.rawList.find(x => x.ServiceSyncModuleCode && x.ServiceSyncModuleCode === 'TEAMS');
                if (teamsService) {
                    this.getFullTeamsService(teamsService.Id);
                }
            }
        });

        this.getSyncModuleDefaultSettings();
    }

    getFullTeamsService(Id) {
        cmsGetFullService(this.props.account.Id, Id).then(res => {
            if(res 
                && res.data 
                && res.data.TeamsSettings
                && res.data.TeamsSettings.PhoneAppBotLastAuthorisedDate != null){
                this.setState({isPhoneAppSetUp: true});
            }
        })
    }

    getSyncModuleDefaultSettings() {
        var self = this;
        cmsGetSyncModuleDefaultSettings(this.props.account.Id).then(function (res) {
            var appId = res.data.find(x => x.Key === "SyncClientId").Value;
            var tenantId = res.data.find(x => x.Key === "TenantId").Value;
            if (tenantId === null) {
                tenantId = 'common';
            }
            var syncSettings = {
                SyncClientId: appId,
                TenantId: tenantId
            };
            self.setState({ SyncModuleDefaultSettings: syncSettings });
        })
    }

    buildPopupCb() {

        if (this.requiresPBXSync()) {
            var popup = openPopupWindow('about:blank', "Portal Auth");
            return popup;
        }
        return false;
    }

    getCheckingDialog = () => {
        return (
            <>
                {this.state.showDialog && (
                    <Dialog
                        hidden={false}
                        dialogContentProps={{
                            type: DialogType.normal,
                        }}
                        modalProps={{
                            styles: { main: { maxWidth: 450 } },
                            containerClassName: "prerequisite-dailog",
                            isBlocking: true
                        }}>
                        <div>
                            <div className="ms-Spinner prerequisite-dailog--inner ">
                                <div style={{ display: 'inline' }}><i className="fa fa-spin fa-solid fa-spinner" style={{ marginRight: '10px' }}></i></div>
                                <div className="ms-Spinner-label " style={{ display: 'inline' }}>Checking your Microsoft 365 Tenant...</div>
                            </div>
                        </div>
                    </Dialog>
                )}
            </>
        )
    }

    getStatusDialog = () => {
        return (
            <>
                {this.state.showStatusDialog && (
                    <Dialog
                        hidden={false}
                        dialogContentProps={{
                            type: DialogType.normal,
                        }}
                        modalProps={{
                            styles: { main: { maxWidth: 450 } },
                            containerClassName: "prerequisite-dailog",
                            isBlocking: true
                        }}>
                        <div>
                            <div style={{ display: 'inline' }}><i className="fa fa-spin fa-solid fa-spinner" style={{ marginRight: '10px' }}></i></div>
                            <div className="ms-Spinner-label " style={{ display: 'inline' }}>Please wait, registration status checking in progress</div>
                        </div>
                    </Dialog>
                )}
            </>
        )
    }

    stepChanged = (stats) => {
        document.getElementById(`step-${stats.previousStep}-container`).style.display = "none";
        document.getElementById(`step-${stats.activeStep}-container`).style.display = "block";
    }

    hasPBXorTrunkLicense = (account) => {
        var lics = extractLicence(account, PBX_USER_LICENCE_CODE);
        if (lics && lics.NumPurchased) {
            return true;
        }
        else {
            lics = extractLicence(account, TRUNK_USER_LICENCE_CODE);
            return lics && lics.NumPurchased
        }
    }

    userUpdateTrigger = (data) => {
        var self = this;

        if (data !== null) {
            //have to call api due to redux services lacking such properties like PBXSettings/TrunkSettings
            cmsGetAccountServicePromise(self.props.account.Id, data.Part1.AccountServiceId).then(resp => {
                var service = resp.data;
                var teamsService = self.props.services.fullTeams.find(x => x.Id == data.Part2.AccountServiceId);
                // Start a sync, we don't care about how this one goes, just that it happens
                cmsStartOAuthServiceSync(
                    self.props.account.Id,
                    teamsService, 
                    null, 
                    null, 
                    null, 
                    teamsService.TeamsSettings.IsAutoSyncEnabled
                );

                var mode = service.PBXSettings !== null
                    ? service.PBXSettings.SBCMode
                    : service.TrunkSettings.SBCMode

                if (mode.includes("NonRegistration")) {
                    self.redirectToUser(
                        self.props.account.Id,
                        true,
                        "Success");
                }
                else {
                    self.setState({
                        showStatusDialog: true,
                    }, () => {
                        switch (service.Variant.Code) {
                            case "PBX":
                                this.pollPBXStatus(self.props.account.Id,
                                    data.Part1.AccountServiceId,
                                    data.Part1.Id,
                                    function (status, message) {
                                        self.setState({
                                            showStatusDialog: false
                                        });
                                        self.redirectToUser(
                                            self.props.account.Id,
                                            true,
                                            status,
                                            message);
                                    });
                                break;
                            case "TRUNK":
                                this.pollTrunkStatus(self.props.account.Id,
                                    data.Part1.AccountServiceId,
                                    function (status, message) {
                                        self.setState({
                                            showStatusDialog: false
                                        });
                                        self.redirectToUser(
                                            self.props.account.Id,
                                            true,
                                            status,
                                            message);
                                    });
                                break;
                            default:
                                self.setState({
                                    showStatusDialog: false
                                });
                        }
                    })
                }
            }).catch(err => toast.error(err.message));
        }
    }

    pollPBXStatus = (accountId, accountServiceId, userId, callback) => {
        var id = setInterval(() =>
            cmsGetPBXStatus(
                accountId, accountServiceId, userId).then((result) => {
                    if (result.data) {
                        if (result.data.RegistrationStatus != "Pending") {
                            clearInterval(id)
                            callback(
                                result.data.RegistrationStatus,
                                result.data.RegistrationMessage);
                        }
                    } else {
                        clearInterval(id)
                        callback(null);
                        toast.error("No data found.");
                    }
                }, (err) => {
                    clearInterval(id)
                    callback(null);
                    toast.error(err.message);
                })
            , 2000);
    }

    pollTrunkStatus = (accountId, accountServiceId, callback) => {
        var id = setInterval(() =>
            cmsGetTrunkStatus(
                accountId, accountServiceId).then((result) => {
                    if (result.data) {
                        if (result.data.RegistrationStatus != "Pending") {
                            clearInterval(id)
                            callback(
                                result.data.RegistrationStatus,
                                result.data.RegistrationMessage);
                        }
                    } else {
                        clearInterval(id)
                        callback(null);
                        toast.error("No data found.");
                    }
                }, (err) => {
                    clearInterval(id)
                    callback(null);
                    toast.error(err.message);
                })
            , 2000);
    }

    redirectToUser = (accoundId, show, status, message = "") => {
        if (status !== null) {
            this.props.history.push({
                pathname: `/portal/${accoundId}/users`,
                props: {
                    showAlert: show,
                    status: this.getStatus(status, message),
                    message: message == null ? "" : message,
                }
            })
        };
    }

    getStatus = (status, message) => {
        if (status == 'Disabled' && message == null ) {
            return "Success";
        };

        return status;
    }

    showModal(prop, visible) {
        this.setState({ [prop]: visible });
    }

    handleStatus = () => {
        var self = this;
        self.setState({
            showDialog: true,
            isPrerequisiteNextBtnEnabled: false,
            statuses: {
                glbAdm: " — ",
                sysLic: " — ",
                off365: " — ",
                resAccnt: " — ",
                pbxSip: " — ",
                isIE: " — ",
                pbxTnkLic: " — ",
                servicePrinc: " — "
            }
        }, function () {
            self.doPrerequisiteCheck(null, this.incorrectPermissionsCb, DirectoryReadAllScope, this.state.isPhoneAppSetUp);
        });
    }

    pbxSkipSyncCallback(skipSync, data) {
        if (skipSync && data) {
            this.setState({
                pbxSyncId: this.props.account.Id,
                pbxServiceToSync: data
            })
        }
    }

    requiresPBXSync() {
        var self = this;
        var pbxServices = this.props.services.rawList && this.props.services.rawList.length > 0 && this.props.services.rawList.filter(x => x.ServiceVariantCode === 'PBX');
        var pbxToSync = pbxServices && pbxServices.length > 0 && pbxServices.filter(x => x.ServiceSyncModuleCode && x.ServiceSyncModuleCode != null);
        if (pbxToSync && pbxToSync.length > 0 && pbxServices.length == 1) {
            return pbxToSync[0];
        }
        else {
            return false;
        }
    }

    newServiceSyncStartedCb(popup) {
        var self = this;
        var pbxToSync = this.requiresPBXSync();
        if (popup && pbxToSync) {

            cmsGetAllAccountServicePromise(self.props.account.Id, [pbxToSync]).then(function (data) {
                return data
            }).then((data) => {
                let servOb = data[0];
                return cmsStartExternalServiceSync(
                    this.props.account.Id, servOb, null, popup,
                )
            }).then(function () {
                popup.close();
                toast.success('initial sync successfully started');
            }).catch(function (err) {
                popup.close();
                toast.error(err.message);
            })
        }
    }

    delicenceTestUser() {
        var self = this;
        var reqArg = {
            SyncModuleDefaultSettings: self.state.SyncModuleDefaultSettings,
            additionalScope: RemoveLicensesDirectoryScope
        }
        checkDelicenceTestUser(reqArg).then(function (tok) {
            self.doPrerequisiteCheck(tok, self.incorrectPermissionsCb, RemoveLicensesDirectoryScope, this.state.isPhoneAppSetUp);
        }).catch(function (err) {
            toast.warning(err.message);
        })
    }

    getTeamService = () => {
        let availService = null;
        let self = this;
        self.props.services.totalList.forEach(sId => {
            availService = self.props.account.AvailableServices.find(a => a.Id === sId);
        });
        return availService ? availService : null;
    }

    getCurrentService = () => {
        let self = this;
        self.props.services.totalList.forEach(sId => {
            let existingServices = self.props.services.list[sId];
            if (existingServices) {
                return existingServices[0];
            }
            return null;
        });
    }

    refetchServices = (callBack = () => { }) => {
        this.props.refetchServices(callBack);
    }

    render = () => {
        const state = { ...this.state };
        var self = this;
        var availableServices = [];
        if (this.props.account.AvailableServices.filter(x => x.Code === 'pbx').length > 0) {
            availableServices = this.props.account.AvailableServices.filter(x => x.Code === 'pbx')[0].Variants;
        }

        if (state.isUserAdded) {
            return
        }

        if (this.requiresPBXSync()) {
            var pop = this.buildPopupCb
        }
        else {
            var pop = null;
        }

        return (
            <div className='row'>
                {this.getCheckingDialog()}
                {this.getStatusDialog()}
                <br />
                <div>
                    <StepWizard
                        onStepChange={this.stepChanged}
                        nav={<WizardBreadcrumbs names={this.state.stepNames}
                        />}>
                        <PrerequisitesStep
                            isPattiFPresent={state.isPattiFPresent}
                            availableServices={availableServices}
                            handleUpdate={this.handleStatus}
                            statuses={state.statuses}
                            account={self.props.account}
                            createServicePrincipal={self.createServicePrincipal}
                            delicenceTestUser={self.delicenceTestUser}
                            isNextButtonEnabled={state.isPrerequisiteNextBtnEnabled} />
                        <ServicesStep
                            {...this.props}
                            state={state}
                            updateTrigger={this.refetchServices}
                            pbxSkipSyncCallback={this.pbxSkipSyncCallback}
                            isNextButtonEnabled={this.props.services.list.length > 0} />
                        <TeamsStep
                            {...this.props}
                            state={state}
                            fullRef={self}
                            buildPopupCb={pop}
                            newServiceSyncStartedCb={this.newServiceSyncStartedCb}
                            updateTrigger={this.refetchServices}
                        />
                        {state.stepNames.includes('Users') ?
                            <UsersStep
                                userUpdateTrigger={this.userUpdateTrigger}
                                updateTrigger={this.refetchServices}
                                fullRef={self}
                                {...this.props} state={state} />
                            : null}
                    </StepWizard>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    const services = state.services;
    return {
        services,
        fullTeams: services.fullTeams,
        account: account.account
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refetchServices: (callBack = () => { }) => dispatch(actions.refetchServices(callBack))
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wizard));

const PrerequisitesStep = (props) => {
    return (
        <div id="step-1-container">
            <PrerequisiteDetails
                isPattiFPresent={props.isPattiFPresent}
                availableServices={props.availableServices}
                handleUpdate={props.handleUpdate}
                createServicePrincipal={props.createServicePrincipal}
                delicenceTestUser={props.delicenceTestUser}
                statuses={props.statuses} />
            <Steps
                isNextButtonEnabled={props.isNextButtonEnabled}
                totalSteps={props.totalSteps}
                nextStep={props.nextStep}
                step={1}
            />
        </div>
    );
};

const ServicesStep = (props) => {
    return (
        <div id="step-2-container">
            {props.state.pbxTemplates ?
                <>
                    <ServiceTabs
                        restrictServiceTabs={true}
                        disableChangeUrlWithTab={false}
                        skipTeamsTrueTransferCheck={true}
                        skipAutoSync={props.state.stepNames.includes('Users') ? false : true}
                        limitToOneService={ true }
                        saveServiceCallback={() => {
                            if (props.currentStep === 2) {
                                props.nextStep();

                            }
                        }}
                        skipSyncCallback={props.pbxSkipSyncCallback}
                    />
                    <Steps
                        isNextButtonEnabled={props.isNextButtonEnabled}
                        totalSteps={props.totalSteps}
                        previousStep={props.previousStep}
                        nextStep={props.nextStep}
                        step={2}
                    />
                </>
                : <p>Services loading...</p>
            }
        </div>
    );
};

const TeamsStep = (props) => {
    var serviceSync = (
        <ServiceSync
            onSyncCompleteCallback={function () {
                if (props.state.isSyncComplete == false) {
                    props.fullRef.setState({ isSyncComplete: true });
                    /* if there us a sync module in the pbx (8x8) then there won't be a users tab
                    * if this is the case, then once the sync is complete, the redirect to the users
                    * page should happen
                    */
                    if (props.state.pbxServiceToSync && props.state.pbxSyncId && !props.state.stepNames.includes('Users') && props.currentStep === 3) {
                        props.fullRef.redirectToUser(
                            props.account.Id,
                            true,
                            "Success");
                    }
                }
            }}
        />
    );

    var isLoaded = props.services !== null && props.services.rawList !== null && props.services.rawList.length > 0 && props.services.rawList.filter(x => x.ServiceName === 'Teams').length > 0;
    var isSyncEnabled = isLoaded ? props.services.rawList.find(x => x.ServiceName === 'Teams').SyncEnabled : false;

    return (
        <div id="step-3-container">
            <br />
            <br />
            {isLoaded && isSyncEnabled
                ? <><div style={{ textAlign: 'center' }}>We are setting up your Microsoft 365 calling, this may take up to 15 minutes.</div>
                    <br />
                    <br />
                    <div style={{ textAlign: 'center' }}>{serviceSync}</div></>
                : <div style={{ textAlign: 'center' }}>
                    <MicrosoftServicePanel
                        availableService={props.fullRef.getTeamService()}
                        currentService={isLoaded ? props.services.rawList.find(x => x.ServiceName === 'Teams') : props.fullRef.getCurrentService()}
                        newServiceSyncStartedCb={props.newServiceSyncStartedCb} 
                    />
                </div>}
            <br/>
            <br/>
            <Steps
                isNextButtonEnabled={props.state.isSyncComplete || (isLoaded && !isSyncEnabled)}
                totalSteps={props.totalSteps}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                step={3}
            />
        </div>
    );
};

const UsersStep = (props) => {
    var servicesLoaded = false;
    var teamsService = null;
    if (props.services.rawList && props.services.rawList.length > 0 && props.currentStep === 4) {
        teamsService = props.services.rawList.filter(x => x.ServiceCode === 'teams');
        if (teamsService && teamsService.length > 0) {
            servicesLoaded = true;
        }
    }
    var showUsersForm = servicesLoaded && (props.state.isSyncComplete || (teamsService && !teamsService.SyncEnabled));
    return (
        <div id="step-4-container">
            <div className='row'>
                <div className="col-md-9 wizard-page-heading">
                    <p>This form will connect your PBX/Trunk user with a specific Teams user.</p>
                </div>
                <div className="col-md-3"></div>
            </div>
            {showUsersForm ?
                <NewUserForm
                    countries={props.state.countries}
                    otherFormOpen={true}
                    alwaysShowForm={true}
                    isPartofWizard={true}
                    hideCloseButton={true}
                    submitButtonText={`Add User${teamsService && teamsService.SyncEnabled ? ' and Sync': ''}`}
                    userUpdateTrigger={teamsService && teamsService.SyncEnabled ? props.userUpdateTrigger : () => { props.fullRef.redirectToUser(
                        props.account.Id,
                        true,
                        "Success")}}
                />
                : <p>Services loading...</p>
            }
            <Steps
                totalSteps={props.totalSteps}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                step={4}
            />
        </div>
    );
};

const Steps = ({
    nextStep,
    previousStep,
    totalSteps,
    step,
    isNextButtonEnabled
}) => (
    <div>
        <hr />
        <div className="row">

            {(step == 1 && step < totalSteps) &&
                <div className='col-md-12'>
                    <div className="pull-right">
                        <button disabled={isNextButtonEnabled ? "" : "disabled"} id={`step-btn-next-${step}`} className='btn btn-primary wizard-btn-primary' onClick={nextStep}>Next</button>
                    </div>
                </div>
            }

            {step > 1 &&
                <div className='col-md-6'>
                    <div className="pull-left">
                        <button id={`step-btn-prev-${step}`} className='btn btn-primary wizard-btn-primary' onClick={previousStep}>Back</button>
                    </div>
                </div>
            }

            {(step > 1 && step < totalSteps) &&
                <div className='col-md-6'>
                    <div className="pull-right">
                        <button disabled={isNextButtonEnabled ? "" : "disabled"} id={`step-btn-save-${step}`} className='btn btn-primary wizard-btn-primary' onClick={nextStep}>Next</button>
                    </div>
                </div>
            }
        </div>
    </div>
);

