import React, { Component } from 'react';
import PBXTrunkServiceNew from './PBXTrunkServiceNew';
import PBXServiceEdit from './PBXServiceEdit';

class PBXServicePanel extends Component {

    render() {
        var self = this;

        var addService = null;
        if (self.props.availableService
            && self.props.availableService.AllowMultipleInstances
            && self.props.availableServiceVariant
            && self.props.allowNewService
        ) {
            addService = (
                <div>
                    <PBXTrunkServiceNew
                        serviceName="PBX"
                        variant={self.props.availableServiceVariant}
                        existingCount={self.props.currentServices ? self.props.currentServices.length : 0}
                        restrictServiceTabs={self.props.restrictServiceTabs}
                        saveServiceCallback={self.props.saveServiceCallback}
                        skipTeamsTrueTransferCheck={self.props.skipTeamsTrueTransferCheck}
                        skipAutoSync={self.props.skipAutoSync}
                        skipSyncCallback={self.props.skipSyncCallback}
                        limitToOneService={self.props.limitToOneService}
                    />
                </div>
            );
        }

        var serviceList = [];
        if (self.props.currentServices) {
            self.props.currentServices.forEach(function (service) {
                serviceList.push(
                    <div key={service.Id}>
                        <PBXServiceEdit
                            totalPBXTrunk={self.props.currentServices.length}
                            variant={self.props.availableServiceVariant}
                            currentVariant={self.props.availableServiceVariant}
                            currentService={service}
                        />
                    </div>
                );
            });
        }

        return (<div className="service-panel">{serviceList} {addService}</div>);
    }

}
export default PBXServicePanel;
