import React, { Component } from 'react';
import { getErrMsg, cmsServiceDisableSync, cmsServiceEnableSync } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { connect } from 'react-redux';

class ServiceSyncOnOff extends Component {
    constructor(props) {
        super(props);
        this.disableSync = this.disableSync.bind(this);
        this.enableSync = this.enableSync.bind(this);
    }

    disableSync(e, service) {
        var self = this;
        e.preventDefault();

        var name = '';
        if (service.hasOwnProperty('Name') && service.Name) {
            name = ' for ' + service.Name;
        }

        if (window.confirm("Are you sure you want to disable sync" + name + "?")) {
            cmsServiceDisableSync(
                self.props.account.Id,
                service.Id,
                function () {
                    toast.success("Service sync is now disabled.");

                    self.props.updateServicesTrigger && self.props.updateServicesTrigger();
                },
                function(err) {
                    toast.error("Service sync failed to update: " + err)
                }
            );
        }
    }

    enableSync(e) {
        var self = this;
        e.preventDefault();

        cmsServiceEnableSync(
            self.props.account.Id,
            self.props.service.Id,
            {},
            function () {
                toast.success("Sync is now enabled");
                self.props.updateServicesTrigger && self.props.updateServicesTrigger();
            },
            function (err) {
                toast.error(getErrMsg(err));
            }
        );
    }

    render() {
        // Check if we should show sync button enable/disable...
        const service = this.props.service;

        if (!service) {
            return null;
        }

        if (service.SyncEnabled) {
            return <DefaultButton text='Disable Sync' onClick={(e) => this.disableSync(e, service)} />
        } else {
            return <PrimaryButton text='Enable Sync' onClick={(e) => this.enableSync(e)} />
        }
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceSyncOnOff);
